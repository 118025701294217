import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  Chat,
  Faq,
  HowitworkIcon,
  LogoutIcon,
  PerasonalDeatil,
  PrivacyIcon,
  Refer,
  Refericon,
  Termconditonicon,
  Totalexpense,
} from "../../components/Icon/fin";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useAuth } from "../../contexts/Auth";
import { FaSignOutAlt } from "react-icons/fa";
import { HiArrowRight } from "react-icons/hi";
import Leaf from "../../assets/images/leaf.png";
import { useQuery } from "react-query";
import { getUserProfileData } from "../../dataFetchers/userProfileDataFetcher";

const More = () => {
  const { signOut } = useAuth();
  const { user } = useAuth();
  const { isError, isLoading, data, error } = useQuery(
    "userProfile",
    getUserProfileData
  );
  return (
    <>
      <Heading
        fontSize="18px"
        fontFamily="poppins"
        fontStyle="normal"
        fontWeight="500"
        color="#000"
        px="4"
        pt="5"
        mt="-3"
      >
        Your Profile and more
      </Heading>
      <Box
        display="flex"
        shadow="md"
        bg="#ffffff"
        h="100px"
        alignItems="center"
        borderRadius="8px"
        mt="3"
      >
        {/* <Image
          w="92px"
          h="92px"
          borderRadius="50%"
          ml="2"
          src=""
        /> */}
        <Avatar
          ml="4"
          size="lg"
          name={data?.queryData.name}
          src="/images/avatar-min.jpg"
        />
        <Box
          ml="4"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
        >
          <Heading
            fontSize="18px"
            fontFamily="poppins"
            fontStyle="normal"
            fontWeight="600"
          >
            {data?.queryData.name}
          </Heading>
          <Text
            fontSize="12px"
            fontFamily="poppins"
            fontStyle="normal"
            fontWeight="600"
            color="#9C9C9C"
          >
            {user?.email}
          </Text>
        </Box>
      </Box>

      <Text
        mt="8"
        fontSize="16px"
        fontFamily="poppins"
        fontStyle="normal"
        fontWeight="500"
        px="4"
      >
        {"Homeowner’s information".toUpperCase()}
      </Text>
      <Box
        mt="4"
        py="2"
        px="2"
        borderRadius="5px"
        bg="#ffffff"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.05);"
      >
        <Link href="/dashboard/myprofile">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="3"
          >
            <Box display="flex" alignItems="center">
              <PerasonalDeatil fontSize="20px" />
              <Text
                ml="6"
                fontSize="16px"
                fontFamily="poppins"
                fontStyle="normal"
                fontWeight="400"
                color="#000"
              >
                Your personal details
              </Text>
            </Box>

            <MdKeyboardArrowRight fontSize="18px" />
          </Box>
        </Link>
        <Divider variant="dashed" mt="3" color="#DFDFDF" />
        <Box
          mt="2"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px="3"
          py="3"
        >
          <Box display="flex" alignItems="center">
            <Refericon fontSize="20px" />
            <Text
              ml="6"
              fontSize="16px"
              fontFamily="poppins"
              fontStyle="normal"
              fontWeight="400"
              color="#000"
            >
              Refer your Friend
            </Text>
          </Box>
          <Link href="/refer">
            <MdKeyboardArrowRight fontSize="18px" />
          </Link>
        </Box>
      </Box>

      <Text
        mt="12"
        fontSize="16px"
        fontFamily="poppins"
        fontStyle="normal"
        fontWeight="500"
        px="4"
      >
        SUPPORT & LEGAL
      </Text>
      <Box
        mt="4"
        p="2"
        borderRadius="5px"
        bg="#FFFFFF"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.05);"
      >
        <Link href="/dashboard/faq">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px="3"
            py="3"
          >
            <Box display="flex" alignItems="center">
              <Faq fontSize="20px" />
              <Text
                ml="6"
                fontSize="16px"
                fontFamily="poppins"
                fontStyle="normal"
                fontWeight="400"
                color="#000"
              >
                FAQs
              </Text>
            </Box>

            <MdKeyboardArrowRight fontSize="18px" />
          </Box>
        </Link>
        <Divider variant="dashed" mt="3" color="#DFDFDF" />
        <Link href="/dashboard/terms&condition">
          <Box
            mt="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="3"
          >
            <Box display="flex" alignItems="center">
              <Termconditonicon fontSize="20px" />
              <Text
                ml="6"
                fontSize="16px"
                fontFamily="poppins"
                fontStyle="normal"
                fontWeight="400"
                color="#000"
              >
                Terms & Conditions
              </Text>
            </Box>
            <MdKeyboardArrowRight fontSize="18px" />
          </Box>
        </Link>
        <Divider variant="dashed" mt="3" color="#DFDFDF" />
        <Link href="/dashboard/privacy">
          <Box
            mt="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="3"
          >
            <Box display="flex" alignItems="center">
              <PrivacyIcon fontSize="20px" />
              <Text
                ml="6"
                fontSize="16px"
                fontFamily="poppins"
                fontStyle="normal"
                fontWeight="400"
                color="#000"
              >
                Privacy Policy
              </Text>
            </Box>
            <MdKeyboardArrowRight fontSize="18px" />
          </Box>
        </Link>
        <Divider variant="dashed" mt="3" color="#DFDFDF" />
        <Link href="/dashboard/howitsworks">
          <Box
            mt="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="3"
          >
            <Box display="flex" alignItems="center">
              <HowitworkIcon fontSize="20px" />
              <Text
                ml="6"
                fontSize="16px"
                fontFamily="poppins"
                fontStyle="normal"
                fontWeight="400"
                color="#000"
              >
                How it works{" "}
              </Text>
            </Box>
            <MdKeyboardArrowRight fontSize="18px" />
          </Box>
        </Link>
        <Divider variant="dashed" mt="3" color="#DFDFDF" />
        <Link href="/dashboard/chat">
          <Box
            mt="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="3"
          >
            <Box display="flex" alignItems="center">
              <Chat fontSize="14px" color="gray" />
              <Text
                ml="6"
                fontSize="16px"
                fontFamily="poppins"
                fontStyle="normal"
                fontWeight="400"
                color="#000"
              >
                Chat support
              </Text>
            </Box>
            <MdKeyboardArrowRight fontSize="18px" />
          </Box>
        </Link>
      </Box>

      <Box mt="5">
        <Button
          onClick={signOut}
          ml="6"
          fontSize="16px"
          fontFamily="poppins"
          fontStyle="normal"
          fontWeight="500"
          color="#245E53"
          border="1px solid #245E53"
          w="90%"
          bg="transparent"
          borderRadius="10px"
        >
          Logout
        </Button>
      </Box>
    </>
  );
};

export default More;
