import { FaFacebook } from 'react-icons/fa';
import { SiLinkedin } from 'react-icons/si';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { TiSocialTwitter } from 'react-icons/ti';
import { Box, IconButton, Center, Stack, Text } from '@chakra-ui/react';
import * as React from 'react'

export default function All() {
    return (
        <Center p={8}>
            <Stack spacing={2} isInline align={'center'}>
                {/* Whatsapp */}
                <IconButton w="2" aria-label="Whatsapp" colorScheme={'whatsapp'} icon={<AiOutlineWhatsApp />}>

                    {/* <Center>
                        <Text>Continue with Facebook</Text>
                    </Center> */}
                </IconButton>
                {/* Facebook */}
                <IconButton w="0.5" aria-label="Whatsapp" colorScheme={'facebook'} icon={<FaFacebook />}>
                    {/* <Center>
                        <Text>Continue with Facebook</Text>
                    </Center> */}
                </IconButton>



                {/* LinkedIn */}
                <IconButton w="0.5" aria-label="Whatsapp" colorScheme={'linkedin'} icon={<SiLinkedin />}>
                    {/* <Center>
                        <Text>Send to Linkedin</Text>
                    </Center> */}
                </IconButton>
                {/* Twitter */}
                <IconButton w="0.5" aria-label="Whatsapp" colorScheme={'twitter'} icon={<TiSocialTwitter />}>
                    {/* <Center>
                        <Text>Continue with Facebook</Text>
                    </Center> */}
                </IconButton>

            </Stack>
        </Center>
    );
}