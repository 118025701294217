import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { getBookingPriceData } from "../../dataFetchers/userProfileDataFetcher";
import { useQuery } from "react-query";

type showMorePropType = {
  booking_id: string;
  children: React.ReactNode;
  height: string;
};

const ShowMore = ({ children, height, booking_id }: showMorePropType) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(prev => !showAll);
  const listHeight = showAll ? "auto" : height;

  const {
    data: bookingPriceData,
    isLoading: isbookload,
    isError,
    error,
  } = useQuery(["bookingpriceData", booking_id], () =>
    getBookingPriceData({
      booking_id: booking_id,
    })
  );

  console.log(bookingPriceData);

  return (
    <div>
      <div style={{ height: listHeight, overflow: "hidden" }}>
        <>
          {children}
          <Flex
            justifyContent="space-between"
            px="2"
            fontSize="sm"
            mt="2"
            color="#6E6E6E"
          >
            <Text>Name:</Text>
            <Text>{bookingPriceData?.firstName}</Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            px="2"
            fontSize="sm"
            mt="2"
            color="#6E6E6E"
          >
            <Text>Price:</Text>
            <Text>
              ₹
              {Math.round(
                bookingPriceData?.paymentDetails.sellRate
              ).toLocaleString("en-in")}
            </Text>
          </Flex>
        </>
      </div>
      <Text textAlign="center" mt="1" color="#D1D1D1" fontSize="sm">
        {showAll ? "Show Less" : "Show More"}
      </Text>
      <Box margin="auto" w="fit-content" cursor="pointer">
        {showAll ? (
          <MdKeyboardArrowUp
            color="#D1D1D1"
            fontSize="20px"
            onClick={() => toggleShowAll()}
          />
        ) : (
          <MdKeyboardArrowDown
            color="#D1D1D1"
            fontSize="20px"
            onClick={() => toggleShowAll()}
          />
        )}
      </Box>
    </div>
  );
};

export default ShowMore;
