import { Box, useRadioGroup, UseRadioGroupProps } from '@chakra-ui/react'
import * as React from 'react'
import { ButtonRadio } from '../buttonRadio/ButtonRadio'

interface ButtonRadioGroupProps extends UseRadioGroupProps {
  options: Array<{
    label: string
    value: string
    description?: string
    icon: React.ReactElement
  }>
}

export const ButtonRadioGroup = (props: ButtonRadioGroupProps) => {
  const { options, ...rest } = props
  const { getRadioProps, getRootProps } = useRadioGroup(rest)
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent={{
        base: "space-evenly",
        sm: "space-between"
      }}
      {...getRootProps()}
    >
      {options.map((option) => (
        <ButtonRadio
          key={option.value}
          icon={option.icon}
          description={option.description}
          label={option.label}
          {...getRadioProps({ value: option.value, enterKeyHint: option.value })}
        />
      ))}
    </Box>
  )
}
