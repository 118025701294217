import { Box, Text } from "@chakra-ui/layout";
import { Divider } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiLeftArrow, BiLeftArrowAlt } from "react-icons/bi";
import Leaf from "./../assets/images/leaf.png";
import Alyflogo from "./../assets/images/Alyf_border.png";
import { Image } from "@chakra-ui/image";
import { Hexicon, PerasonalDeatil, Star } from "../components/Icon/fin";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Radio, RadioGroup, useRadioGroup } from "@chakra-ui/radio";
import { Button } from "@chakra-ui/button";
import { EmailIcon, Icon, PhoneIcon } from "@chakra-ui/icons";
import { rotate } from "@chakra-ui/progress/dist/declarations/src/progress.utils";
import { MdPerson } from "react-icons/md";
import { useForm } from "react-hook-form";

type FormInputs = {
  name: string;
  number: string;
  email: string;
  ReferFor: string;
};
const Referearn = () => {
  const [formstate, setFormstate] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
    setValue,
    getValues,
  } = useForm<FormInputs>();

  const onSubmit = async (data: FormInputs) => {
    // const data1 = new FormData(data);
    const response = await fetch("", {
      method: "POST",
      body: JSON.stringify(data),
    });
    console.log(data);
  };

  const options = [
    {
      value: "toBuy",
      label: "To Own",
      text: "We’ll help them find their dream home",
    },
    {
      value: "torent",
      label: "To Stay",
      text: "We’ll host them at our best properties",
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "radio-group",
    defaultValue: "toBuy",
  });

  const group = getRootProps();

  return (
    <>
      <Box display="flex" gap="5" alignItems="center">
        <BiLeftArrowAlt fontSize="20px" onClick={() => window.history.back()} />
        <Text
          color={"#000"}
          fontWeight="500"
          fontFamily="Cormorant Garamond"
          fontStyle="normal"
          fontSize="20px"
        >
          Refer a friend
        </Text>
      </Box>
      <Divider mt="2" />

      <Box
        borderRadius="6px"
        bg="#FFF"
        boxShadow="8px 8px 16px 0px rgba(0, 0, 0, 0.05)"
      >
        <Box
          position="relative"
          bg="#00272c"
          borderRadius="5px"
          px="3"
          py="8"
          mt="15"
        >
          <Image
            position="absolute"
            w="70px"
            h="85px"
            top="0"
            left="0"
            src={`${Leaf}`}
            transform="rotate(180deg)"
          />
          <Text
            textAlign="center"
            mt="2"
            fontSize="16px"
            color="#E2B599"
            fontWeight="600"
            fontFamily="Montserrat"
            fontStyle="normal"
          >
            EARN UP TO <br />
            <b style={{ fontSize: "25px" }}> Rs.5,00,000</b>
          </Text>
          <Box m="auto" display="flex" justifyContent="center" mt="2">
            <Star />
          </Box>
          <Text
            zIndex="1"
            color="#fff"
            textAlign="center"
            fontSize="11px"
            mt="3"
            mb="3"
            lineHeight="20px"
            fontWeight="500"
            fontFamily="Montserrat"
            fontStyle="normal"
          >
            Travel is better together. Invite your friends or family to explore
            Life with ALYF and earn rewards when they book with us
          </Text>

          <Image
            position="absolute"
            w="70px"
            h="85px"
            bottom="0"
            right="0"
            src={`${Leaf}`}
          />
        </Box>
        <Box
          display="flex"
          mt="-5"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Image src={Alyflogo} />
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt="10">
            <FormControl isRequired>
              <FormLabel
                fontSize="12px"
                color="#B8B8B8"
                fontFamily="Montserrat"
              >
                Name of your friend
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdPerson name="person" fontSize="20px" color="#B8B8B8" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  fontSize="12px"
                  {...register("name", { required: true })}
                  _placeholder={{ opacity: 0.7, color: "#B8B8B8" }}
                />
              </InputGroup>

              <FormLabel
                fontSize="12px"
                color="#B8B8B8"
                fontFamily="Montserrat"
              >
                Phone
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon name="phone" color="#B8B8B8" />
                </InputLeftElement>
                <Input
                  type="number"
                  placeholder="Enter Number"
                  {...register("number", { required: true })}
                  fontSize="12px"
                  _placeholder={{ opacity: 0.7, color: "#B8B8B8" }}
                />
              </InputGroup>

              <FormLabel
                fontSize="12px"
                color="#B8B8B8"
                fontFamily="Montserrat"
              >
                Email
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon name="email" color="#B8B8B8" />
                </InputLeftElement>
                <Input
                  type="email"
                  placeholder="Enter Email"
                  fontSize="12px"
                  {...register("email", { required: false })}
                  isRequired={false}
                  _placeholder={{ opacity: 0.7, color: "#B8B8B8" }}
                />
              </InputGroup>
              <Box display="flex" justifyContent="space-between" gap="2">
                {options.map((option) => {
                  const radio = getRadioProps({ value: option.value });

                  return (
                    <Box
                      key={option.value}
                      p="4"
                      borderRadius="md"
                      mt="5"
                      w="50%"
                      border="1px solid"
                      borderColor={radio.isChecked ? "#7FAB63" : "#CECECE"}
                    >
                      <label style={{ fontFamily: "Montserrat" }}>
                        <Radio
                          {...radio}
                          {...register("ReferFor", { required: true })}
                          colorScheme="teal"
                        >
                          {option.label}
                        </Radio>
                      </label>
                      <Text
                        mt="2"
                        mx="4"
                        fontSize="10px"
                        w="full"
                        color="#D1D1D1"
                        fontFamily="Montserrat"
                      >
                        {radio.isChecked &&
                          (option.value === "toBuy"
                            ? "We’ll help them find their dream home"
                            : "We’ll host them at our best properties")}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </FormControl>
          </Box>
          <Button
            type="submit"
            bg="#00272c"
            color="#E2B599"
            mt="5"
            mb="4"
            px="14"
            fontSize="14px"
            fontWeight="500"
            fontFamily="Montserrat"
            fontStyle="normal"
            isLoading={isSubmitting}
            loadingText="Submitting.."
            colorScheme="none"
          >
            Submit
          </Button>
          <Button
            bg="#FFF"
            color="#C1C1C1"
            mt="5"
            px="14"
            mb="4"
            ml="5"
            fontSize="14px"
            fontWeight="500"
            fontFamily="Montserrat"
            shadow="md"
            fontStyle="normal"
          >
            Cancel
          </Button>
        </form>
      </Box>
    </>
  );
};

export default Referearn;
