import { FaPowerOff, FaBed } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { MdPayment } from "react-icons/md";
import { BiBody } from "react-icons/bi";
import CircleIcon, { Chat, Finance, Myhome, Booking } from "../components/Icon/fin";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
export interface SidebarItemObjectType {
  id: number;
  name?: string;
  link: string;
  badge: string;
  icon: IconType;
  noLink?: boolean;
  onClickFunction?: () => void;
}

export interface MenuDataType {
  data: Array<SidebarItemObjectType>;
}

export const menuData: MenuDataType = {
  data: [
    {
      id: 1,
      name: "My Home",
      link: "/dashboard",
      badge: "new",
      icon: CircleIcon,
    },
    {
      id: 5,
      name: "My Stay",
      link: "/dashboard/mystay",
      badge: "new",
      icon: Myhome,
    },
    {
      id: 2,
      name: "Finance",
      link: "/dashboard/payment",
      badge: "new",
      icon: Finance,
    },
    {
      id: 6,
      name: "Chat",
      link: "/dashboard/bookings",
      badge: "new",
      icon: Booking,
    },

    {
      id: 7,
      name: "Concierge",
      link: "/dashboard/more",
      badge: "new",
      icon: HiOutlineDotsHorizontal,
    },
  ],
};

export const sidebarLogoutObject: SidebarItemObjectType = {
  id: 7,
  name: "Logout",
  link: "/logout",
  badge: "new",
  icon: FaPowerOff,
  noLink: true,
  onClickFunction: () => { },
};
