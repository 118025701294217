import {
  Box,
  chakra,
  Text,
  useRadio,
  UseRadioProps,
  VStack,
  HStack,
  useColorModeValue as mode,
  useId,
} from '@chakra-ui/react'
import * as React from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'

const RadioBox = chakra('div', {
  baseStyle: {
    borderWidth: '3px',
    mb: '2',
    px: '2',
    pt: '4',
    pb: '3',
    borderRadius: 'md',
    cursor: 'pointer',
    transition: 'all 0.2s',
    _focus: { shadow: 'outline' },
  },
})

const CheckboxIcon = (props: { checked: boolean }) => (
  <Box fontSize="3xl" color={props.checked ? 'blue.600' : mode('gray.300', 'whiteAlpha.400')}>
    {props.checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
  </Box>
)

interface ButtonRadioProps extends UseRadioProps {
  icon: React.ReactElement
  label: string
  description?: string
  showLabel?: boolean
}

export const ButtonRadio = (props: ButtonRadioProps) => {
  const { label, icon, description, showLabel } = props
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio(props)
  const id = useId()

  const checkedStyles = {
    bg: mode('blue.50', 'rgb(0 31 71)'),
    borderColor: 'blue.600',
  }

  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox m={[2, 2, 0, 0]} {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <VStack >
          { showLabel && <VStack textAlign="center">
              <Text fontWeight="extrabold" fontSize="lg">
                {label}
              </Text>
              {description && <Text fontSize="sm">{description}</Text>}
          </VStack>}
          <HStack>
            <CheckboxIcon checked={state.isChecked} />
            <Box
              aria-hidden
              fontSize="3xl"
              color={state.isChecked ? 'blue.600' : undefined}
            >
              {icon}
            </Box>
          </HStack>
        </VStack>
      </RadioBox>
    </label>
  )
}
