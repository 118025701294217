import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Progress,
  SimpleGrid,
  Skeleton,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { PureComponent, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { MdArrowDropDown, MdKeyboardArrowRight } from "react-icons/md";
import {
  Calender,
  CalenderShort,
  Mountain,
  Tenteativesharevalue,
  Totalexpense,
  Totalrevenue,
  Vector,
  Yourvilla,
} from "../../components/Icon/fin";

import Banner1 from "../../assets/images/Banner1.png";
import Banner2 from "../../assets/images/Banner2.png";
import Banner3 from "../../assets/images/Banner3.png";

import "./myDashboard.css";
import { GoLocation } from "react-icons/go";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useAuth } from "../../contexts/Auth";
import { useQuery, useQueryClient } from "react-query";
import {
  getAllBookingsData,
  getAllUpcomingAndCompletedBookings,
  getBookingsData,
  getExpensesData,
  getPropertyData,
  getUserProfileData,
} from "../../dataFetchers/userProfileDataFetcher";
import { HomePagePropertyCard } from "../../components/homePagePropertyCard/HomePagePropertyCard";
import Slider from "react-slick";
import "./myDashboard.css";
import { DEFAULT_EXPENSES_OBJECT } from "../Payment/Payment";
import {
  BookingData,
  BookingDetails,
  expensesObjectType,
} from "../../types/dataFetcherTypes/DataFetcherTypes";
import { useProperty } from "../../contexts/Property";

export const getStartDateOfSelectedMonth = () => {
  let date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  // console.log(firstDay)
  return firstDay
    .toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join("-");
};

const Mydashboard = () => {
  const { user } = useAuth();
  const { isLoading: PropertyLoading, propertyData } = useProperty();
  console.log(propertyData, "222222");

  const { isError, isLoading, data, error } = useQuery(
    "userProfile",
    getUserProfileData
  );
  // const {
  //   isError: isPropertyError,
  //   isLoading: isPropertyLoading,
  //   data: propertyData,
  //   error: propertyError,
  // } = useQuery("propertyData", getPropertyData);
  console.log(user);

  const userId = user?.id;

  const monthStartDate = getStartDateOfSelectedMonth();
  const {
    isLoading: bookinLoading,
    data: bookingData,
    error: bookingsError,
  } = useQuery({
    queryKey: [propertyData?.queryData.property_id.property_id, user?.id],
    queryFn: getAllUpcomingAndCompletedBookings,
    enabled: !!user?.id,
  });
  // console.log(bookingData);
  const queryClient = useQueryClient();
  const invalidateBookingCache = () => {
    queryClient.invalidateQueries("bookingsData");
  };

  // console.log(bookingsData);
  // console.log(user?.id);

  let propertyBookingArray =
    bookingData?.filter(({ status }) => status === "upcoming") || [];

  const {
    isError: isExpensesError,
    isLoading: isExpensesLoading,
    data: expensesData,
    error: expensesError,
  } = useQuery("expensesData", getExpensesData);

  const [expensesDataObject, setExpenseObject] = useState<{
    [key: string | number]: expensesObjectType;
  }>({});

  useEffect(() => {
    // console.log(expensesData, "11111");
    expensesData?.queryData.sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      const quarterA = +a.quarter.substring(1);
      const quarterB = +b.quarter.substring(1);
      return quarterB - quarterA;
    });
    let latestQuarterdata = {};
    for (let index = 0; index < expensesData?.queryData?.length; index++) {
      console.log(
        "aaaaa",
        expensesData?.queryData[index].property_id,
        propertyData?.queryData?.property_id?.property_id
      );

      if (
        expensesData?.queryData[index].property_id ===
        propertyData?.queryData?.property_id?.property_id
      ) {
        latestQuarterdata = expensesData?.queryData[index];
        break;
      }
    }
    // console.log(latestQuarterdata, "222222");

    // console.log(
    //   propertyData?.queryData.start_date,
    //   new Date(latestQuarterdata?.payout_date)
    // );

    // payout date and startdate can be same figureitout,sarat date is  ot availabele in some cases
    if (
      new Date(propertyData?.queryData.start_date || "2022-06-22") <
      new Date(latestQuarterdata?.payout_date || "2022-06-22")
    ) {
      setExpenseObject({ [latestQuarterdata.property_id]: latestQuarterdata });
    }
  }, [expensesData, propertyData]);
  console.log(propertyData);

  // console.log(propertyData, expensesDataObject);
  // console.log(propertyData?.queryData?.property_id?.property_id);

  // console.log(
  //   expensesDataObject[propertyData?.queryData?.property_id?.property_id]
  //     ? expensesDataObject[propertyData?.queryData?.property_id?.property_id]
  //     : DEFAULT_EXPENSES_OBJECT
  // );
  // expensesData?.queryData?.map((val, i) => {
  //   if (
  //     new Date(propertyData?.queryData.start_date) < new Date(val?.payout_date)
  //   ) {
  //     expensesDataObject[val.property_id] = val;
  //   }
  // });

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Box mt="0" borderRadius="6px" py="2" px="4">
        <Box className="offer-banner">
          <Slider {...settings}>
            <div>
              <Image
                w="full"
                h="auto"
                objectFit="cover"
                borderRadius="8px"
                src={Banner1}
                alt=""
              />
            </div>
            <div>
              <Link href="/refer">
                <Image
                  w="full"
                  h="auto"
                  objectFit="cover"
                  borderRadius="8px"
                  src={Banner2}
                  alt=""
                />
              </Link>
            </div>
            <div>
              <Image
                w="full"
                h="auto"
                objectFit="cover"
                borderRadius="8px"
                src={Banner3}
                alt=""
              />
            </div>
          </Slider>
        </Box>
      </Box>

      {propertyData && (
        <HomePagePropertyCard
          propertyData={propertyData?.queryData}
          propertyBookingArray={propertyBookingArray}
          expensesData={
            expensesDataObject[
              propertyData?.queryData?.property_id?.property_id
            ]
              ? expensesDataObject[
                  propertyData?.queryData?.property_id?.property_id
                ]
              : DEFAULT_EXPENSES_OBJECT
          }
          isInvalid={invalidateBookingCache}
        />
      )}

      {/* {propertyData && propertyData?.queryData?.map((val, key) => {
        return (
          <HomePagePropertyCard
            key={key}
            propertyData={val}
            propertyBookingArray={propertyBookingArray}
            expensesData={expensesDataObject[val.property_id.property_id] ? expensesDataObject[val.property_id.property_id] : DEFAULT_EXPENSES_OBJECT}
            isInvalid={invalidateBookingCache}
          />
        );
      })} */}

      {/* <Box 
        p="5" 
        mt="5"  
        bgColor="white"
        borderRadius="md"
        boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
      >
          <Flex alignItems="flex-end">
            <Heading as="h3" fontWeight="500" fontSize="14px" fontStyle="normal">Portfolio Returns</Heading>
            <Spacer />
            <Flex>
              <Box w="2" h="2" mr="1" borderRadius="100%" bgColor="#F17A36" alignSelf="center" />
              <Text fontWeight="500" fontSize="12px" fontStyle="normal">Revenue</Text>
              <Box w="2" h="2" mr="1" ml="2" borderRadius="100%" bgColor="#E99D08" alignSelf="center" />
              <Text fontWeight="500" fontSize="12px" fontStyle="normal">Expense</Text>
            </Flex>
          </Flex>
            
          <Box w="100%" h="300px" mt="5">  
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={340}
                height={200}
                data={data}
                maxBarSize={25}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis width={40} />
                <Tooltip />
                <Bar dataKey="Revenue" fill="#F17A36" />
                <Bar dataKey="Expense" fill="#E99D08" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
      </Box> */}

      {/* <SimpleGrid columns={2} spacingX="5" spacingY="5" mt="10">
        <Box 
          py="3" 
          px="2" 
          bgColor="white"
          borderRadius="md"
          boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
        >
             <Box display="flex" ml="1" alignItems="center" color={"#ababab"}>
             <Yourvilla fontSize="20px"/>
              <Text fontSize="12px" fontWeight="medium" pl="2">No.of Properties</Text>
             </Box>
             <Box mt="3" display="flex" ml="1">
              <Text fontWeight="500" fontSize="18px" fontStyle="normal" fontFamily="Montserrat" color="#185d53">02</Text>
             </Box>
        </Box>

        <Box 
          py="3" 
          px="2" 
          bgColor="white"
          borderRadius="md"
          boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
        >
             <Box display="flex" ml="1" alignItems="center" color={"#ababab"}>
             <Totalrevenue fontSize="20px"/>
              <Text fontSize="12px" fontWeight="medium" pl="2">Total Revenue</Text>
             </Box>
             <Box mt="3" display="flex" ml="1" justifyContent="space-between" alignItems="center" >
             <Text fontWeight="500" fontSize="18px" fontStyle="normal" fontFamily="Montserrat" color="#185d53">{totalRevenue.toLocaleString('en-IN')}</Text>
               <Box display="flex" justifyContent="center" alignItems="center">
                 <Vector fontSize="30px" pt="2" />
                 <Text fontSize={"12px"} color="#7FAB63">31.26%</Text>
               </Box>
             </Box>
        </Box>
        <Box 
          py="3" 
          px="2" 
          bgColor="white"
          borderRadius="md"
          boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
        >
             <Box display="flex" ml="1" alignItems="center" color={"#ababab"}>
             <Totalexpense fontSize="20px"/>
              <Text fontSize="12px" fontWeight="medium" pl="2">Total Expenses</Text>
             </Box>
             <Box mt="3" display="flex" ml="1" justifyContent="space-between" alignItems="center" >
             <Text fontWeight="500" fontSize="18px" fontStyle="normal" fontFamily="Montserrat" color="#185d53">{totalExpense.toLocaleString('en-IN')}</Text>
               <Box display="flex" justifyContent="center" alignItems="center">
                 <Vector fontSize="30px" pt="2" />
                 <Text fontSize={"12px"} color="#7FAB63">17.55%</Text>
               </Box>
             </Box>
        </Box>
        <Box 
          py="3" 
          px="2" 
          bgColor="white"
          borderRadius="md"
          boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
        >
             <Box display="flex" ml="1" alignItems="center" color={"#ababab"}>
             <Tenteativesharevalue fontSize="20px"/>
              <Text fontSize="12px" fontWeight="medium" pl="2">Tentative Share Value</Text>
             </Box>
             <Box mt="3" display="flex" ml="1" justifyContent="space-between" alignItems="center" >
             <Text fontWeight="500" fontSize="18px" fontStyle="normal" fontFamily="Montserrat" color="#185d53">48,10,000</Text>
               <Box display="flex" justifyContent="center" alignItems="center">
                 <Vector fontSize="30px" pt="2" />
                 <Text fontSize={"12px"} color="#7FAB63">11.30%</Text>
               </Box>
             </Box>
        </Box>
      </SimpleGrid>
      <Box 
        py="3" 
        px="2" 
        mt="10"
        bgColor="white"
        borderRadius="md"
        boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
      >
        <Text fontWeight="600" fontSize="18px" fontStyle="normal" fontFamily="Montserrat">Remaining Days</Text>
        <Link href='/calander'>
        <Box mt="5" display="flex" py="2" px="1" borderRadius="5px" bg="#FAFAFA">
          <Image src='https://storage.googleapis.com/sharenest/properties/calangute-heights/14.png' w="100px" h="100px" borderRadius="5px" />
          <Box w="100%">
            <Box display="flex" alignItems="center" justifyContent="space-between" px="5" fontWeight="">
              <Text fontSize="16px" fontWeight="600" fontStyle="normal" fontFamily="Montserrat">Calangute Heights</Text>
              <MdKeyboardArrowRight fontSize="20px" />
            </Box>
            <Text px="5" mt="2" fontWeight="400" fontSize="sm">3 days remaining of 6 days</Text>
            <Progress w="85%" h="2" m="auto" mt="3" borderRadius="20px" value={50} colorScheme="teal" bg="#F3F1F1"
              boxShadow={"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"} />
          </Box>
        </Box>
        </Link>

        <Link href='/calander'>
        <Box mt="5" display="flex" py="2" px="1" borderRadius="5px" bg="#FAFAFA">
          <Image src='https://storage.googleapis.com/sharenest/Web%20Images/Tropical%20Grand%20Villa/2.jpg' w="100px" h="100px" borderRadius="5px" />
          <Box w="100%">
            <Box display="flex" alignItems="center" justifyContent="space-between" px="5" fontWeight="">
              <Text fontSize="16px" fontWeight="600" fontStyle="normal" fontFamily="Montserrat">Tropical Grand Villa</Text>
              <MdKeyboardArrowRight fontSize="20px" />
            </Box>
            <Text px="5" mt="2" fontWeight="400" fontSize="sm">22 days remaining of 28 days</Text>
            <Progress w="85%" h="2" m="auto" mt="3" borderRadius="20px" value={78} colorScheme="teal" bg="#F3F1F1"
              boxShadow={"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"} />
          </Box>
        </Box>
        </Link>
      </Box>
      <Box 
        py="3" 
        px="2" 
        mt="10"
        bgColor="white"
        borderRadius="md"
        boxShadow={"rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}
      >
        <Text fontWeight="600" fontSize="18px" fontStyle="normal" fontFamily="Montserrat">Upcoming Stays</Text>
        <Center h="150px">
          <Text color="#185d53" fontWeight="600" fontSize="18px" fontStyle="normal" fontFamily="Montserrat">Coming Soon!!!</Text>
        </Center>
      </Box> */}
    </>
  );
};

export default Mydashboard;
