export interface defaultAvatarsType {
    URL:string,
    displayName:string,
    superPowers:string
}

export interface defaultAvatarsObjectType {
    [key: string]: defaultAvatarsType
}

export const defaultAvatarsObject:defaultAvatarsObjectType = {
    batman: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/batman.png',
        displayName: 'Batman',
        superPowers: 'I am rich, i am vengeance'
    },
    captainAmerica: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/captain_america.png',
        displayName: 'Captain America',
        superPowers: 'Human perfection in strength, stamina, agility, and durability'
    },
    captainAmericaKid: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/captain_america_kid.png',
        displayName: 'Captain America',
        superPowers: 'human perfection in strength, stamina, agility, and durability'
    },
    catwoman: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/catwoman.png',
        displayName: 'Cat Woman',
        superPowers: 'I have money and brains'
    },
    catwomanLady: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/catwoman_lady.png',
        displayName: 'Cat Woman',
        superPowers: 'I have money and brains'
    },
    ironman: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/ironman.png',
        displayName: 'Ironman',
        superPowers: 'Genius, billionaire, playboy, philanthropist.'
    },
    robin: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/robin.png',
        displayName: 'Robin',
        superPowers: 'Gadgets, Superhuman Level Agility, Master Combatant, Master Weapons Specialist, Master Detective'
    },
    wolverine: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/wolverine.png',
        displayName: 'Wolverine',
        superPowers: 'Superhuman strength, Super healing, Claws to kill'
    },
    wonderWoman: {
        URL: 'https://ujbbftmqfdmuudadklpl.supabase.co/storage/v1/object/public/public/avatars/default_avatars/wonder_woman.png',
        displayName: 'Wonder Woman',
        superPowers: 'A literal god'
    },
}