import React from 'react'
import { Card } from '../../components/card/Card'
import { useAuth } from '../../contexts/Auth'
import { getCertificateData } from '../../dataFetchers/certificateFetcher'
import { useQuery } from 'react-query'
import { SimpleGrid } from "@chakra-ui/react"
import { BlogCard } from '../../components/blogWithTile/BlogCard'
import { format, parseISO } from 'date-fns'

export const Certificates = () => {

    const { user } = useAuth()
    const userEmail = user?.email!
    const { data } = useQuery(['userCertificate', userEmail], () => getCertificateData(userEmail))
    
    if (data === undefined) return (
        <span>Data not available</span>
    );


    return (
        <div>

            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="12" mb="10">
                {data?.queryData?.map((certificate, i) => {
                    return (
                        <div>
                            <br />

                            {/* <Image
                                    borderRadius="full"
                                    boxSize="150px"
                                    src="https://bit.ly/sage-adebayo"
                                    alt="Segun Adebayo"
                                    mr='10'
                                /> */}
                            {/* <Text w="80" color="white.500">
                                    {(JSON.stringify(certificate))}
                                </Text> */}


                            <BlogCard
                                event_name={certificate.event_name}
                                event_type={certificate.event_type}
                                type={certificate.certificate_type}
                                thumbnail_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                                organization_logo_url={certificate.organization_logo_url}
                                karma_score={i + 100}
                                org_name={certificate.organization_name}
                                event_start_date={format(parseISO(certificate.event_start_date), 'dd MMM yyyy').toString()}
                                event_end_date={format(parseISO(certificate.event_end_date), 'dd MMM yyyy').toString()}
                                verified={certificate.is_organization_verified}
                            ></BlogCard>

                        </div>
                    )
                })}
                <div>
                    <br />
                    <BlogCard
                        event_name="Hacktober"
                        event_type="Hackathon"
                        type="Winner"
                        thumbnail_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        organization_logo_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        karma_score={400}
                        org_name="IIT Bombay"
                        event_start_date="21-12-2021"
                        event_end_date="21-12-2021"
                        verified={true}
                    ></BlogCard>

                </div>
                <div>
                    <br />
                    <BlogCard
                        event_name="Oasis"
                        event_type="Fest"
                        type="Participant"
                        thumbnail_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        organization_logo_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        karma_score={400}
                        org_name="BITS Pilani"
                        event_start_date="21-12-2021"
                        event_end_date="21-12-2021"
                        verified={true}
                    ></BlogCard>

                </div>
                <div>
                    <br />
                    <BlogCard
                        event_name="Promotion"
                        event_type="Jop-Promotion"
                        type="Employee"
                        thumbnail_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        organization_logo_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        karma_score={400}
                        org_name="Caltech"
                        event_start_date="21-12-2021"
                        event_end_date="21-12-2021"
                        verified={false}
                    ></BlogCard>

                </div>
                <div>
                    <br />
                    <BlogCard
                        event_name="Hacktober"
                        event_type="Hackathon"
                        type="Winner"
                        thumbnail_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        organization_logo_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        karma_score={400}
                        org_name="IIT Bombay"
                        event_start_date="21-12-2021"
                        event_end_date="21-12-2021"
                        verified={true}
                    ></BlogCard>

                </div>
                <div>
                    <br />
                    <BlogCard
                        event_name="Hacktober"
                        event_type="Hackathon"
                        type="Winner"
                        thumbnail_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        organization_logo_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        karma_score={400}
                        org_name="IIT Bombay"
                        event_start_date="21-12-2021"
                        event_end_date="21-12-2021"
                        verified={true}
                    ></BlogCard>

                </div>
                <div>
                    <br />
                    <BlogCard
                        event_name="Hacktober"
                        event_type="Hackathon"
                        type="Winner"
                        thumbnail_url="https://bit.ly/sage-adebayo"
                        organization_logo_url="https://m.media-amazon.com/images/I/71Yo3oLgd3L._SX466_.jpg"
                        karma_score={400}
                        org_name="IIT Bombay"
                        event_start_date="21-12-2021"
                        event_end_date="21-12-2021"
                        verified={true}
                    ></BlogCard>

                </div>
            </SimpleGrid>
            <br />
            <Card>
                {JSON.stringify(user, null, 2)}
            </Card>

        </div>
    )
}
