import { Box, Flex, HStack, Img, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { ImQuotesLeft } from 'react-icons/im'

export function Testimonial() {
  return (
    <Flex as="blockquote" flex="1">
      <Box marginEnd="4" fontSize="32px" color="gray.300">
        <ImQuotesLeft />
      </Box>
      <Box>
        <Text fontSize="2xl" mt="4">
          This product should be known by all. This might be the exact thing the professional world needs as of now. 
          This eliminates the fake certificates and shuts down the illegal practice of making illegitimate copies of certificates 
          to put in a resume.
        </Text>
        <HStack spacing="4" mt="8">
          <Img
            w="12"
            h="12"
            alt="Tushar Goyal"
            rounded="full"
            objectFit="cover"
            src="/images/TusharGoyal.jpeg"
          />
          <Box>
            <Text as="cite" fontStyle="normal" fontWeight="medium">
              Tushar Goyal
            </Text>
            <Text color={mode('gray.600', 'gray.400')}>Director @ REGEX Software Services</Text>
          </Box>
        </HStack>
      </Box>
    </Flex>
  )
}
