import {
  Box,
  Center,
  Flex,
  FlexProps,
  Icon,
  Stack,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaBell, FaCheckCircle } from 'react-icons/fa'
import { FiInfo } from 'react-icons/fi'
import { VscError } from 'react-icons/vsc'

interface NotificationProps extends FlexProps {
  primaryAction: React.ReactNode
  secondaryAction: React.ReactNode
  notificationType?: 'ERROR' | 'INFO' | 'SUCCESS' | 'WARNING'
}

export const Notification = (props: NotificationProps) => {
  const { primaryAction, secondaryAction, children, notificationType, ...flexProps } = props
  let icon = FiInfo
  let iconBgColorLight = 'blue.500'
  let iconBgColorDark = 'blue.300'

  switch (notificationType) {
    case 'WARNING':
      icon = FaBell
      iconBgColorLight = 'orange.500'
      iconBgColorDark = 'orange.300'
      break;

    case 'INFO':
      icon = FiInfo
      iconBgColorLight = 'blue.500'
      iconBgColorDark = 'blue.300'
      break;

    case 'ERROR':
      icon = VscError
      iconBgColorLight = 'red.500'
      iconBgColorDark = 'red.300'
      break;

    case 'SUCCESS':
      icon = FaCheckCircle
      iconBgColorLight = 'green.500'
      iconBgColorDark = 'green.300'
      break;
  
    default:
      icon = FiInfo
      iconBgColorLight = 'blue.500'
      iconBgColorDark = 'blue.300'
      break;
  }

  return (
    <Flex
      boxShadow="lg"
      borderRadius="base"
      overflow="hidden"
      bg={useColorModeValue('white', 'gray.700')}
      {...flexProps}
      borderLeft={{ base: '2px', sm: 'none' }}
      borderRight={{ base: '2px', sm: 'none' }}
      borderLeftColor={useColorModeValue(iconBgColorLight, iconBgColorDark)}
      borderRightColor={useColorModeValue(iconBgColorLight, iconBgColorDark)}
    >
      <Center
        bg={useColorModeValue(iconBgColorLight, iconBgColorDark)}
        px="4"
        display={{ base: 'none', sm: 'flex' }}
      >
        <Icon as={icon} color={useColorModeValue('white', 'gray.900')} boxSize="9" />
      </Center>
      <Stack direction={{ base: 'column', sm: 'row' }} divider={<StackDivider />} spacing="0">
        <Box px="4" py="3">
          {children}
        </Box>
        <Stack
          direction={{ base: 'row', sm: 'column' }}
          height="full"
          divider={<StackDivider />}
          spacing="0"
        >
          {primaryAction}
          {secondaryAction}
        </Stack>
      </Stack>
    </Flex>
  )
}
