import moment from "moment"
import supabase from "../configs/Supabase"
import { displayError } from "../helpers/CommonFunctions"
import { certificateFetcherObjectReturnType } from "../types/dataFetcherTypes/DataFetcherTypes"

export const getCertificateData = async (props: string): Promise<certificateFetcherObjectReturnType> => {
    try {
        let isError = false


        let returnData: null | Array<object> = [{}]


        const { data, error } = await supabase.rpc('get_certificate_data_for_user_dashboard', {
            user_email: props
        })
        if (error) {
            isError = true
        }
        //console.log(data);
        if (data?.length == 0) {
            returnData = data
        } else {
            returnData = data
        }

        return {
            isQueryError: isError,
            queryData: returnData,
            queryError: error
        }
    } catch (error) {
        throw error;
    }
}

// export const setUserProfileData = async (updatedData:any): Promise<any> => {
//     console.log('query value', updatedData)

//     const updatedDataObject = {
//         user_id: updatedData.id,
//         full_name: updatedData.fullName, 
//         gender: updatedData.gender, 
//         date_of_birth: moment( updatedData.dob ).utc(), 
//         linkedin_id: updatedData.linkedinId, 
//         facebook_handle: updatedData.facebookHandle, 
//         twitter_handle: updatedData.twitterHandle, 
//         insta_handle: updatedData.instaHandle, 
//         about: updatedData.about,
//         avatar_url: updatedData.avatarURL
//     }

//     let isError = false
//     const { data, error } = await supabase.from( 'profiles' ).upsert(updatedDataObject)

//     if (error) {
//         isError = true
//         displayError( 'Action not allowed', error.message || error.details || error.hint)
//     }

//     const returnData = {
//         ...data![0],
//         date_of_birth: moment(data![0].date_of_birth).local()
//     }
//     return {
//         isQueryError: isError,
//         queryData: returnData,
//         queryError: error
//     }
// }


// .select( `
//     full_name as name, 
//     gender, 
//     date_of_birth as dob, 
//     linkedin_id as linkedinId, 
//     facebook_handle as facebookHandle, 
//     twitter_handle as twitterHandle, 
//     insta_handle as instaHandle, 
//     avatar_url as avatarURL
// `)