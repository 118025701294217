

import { Box } from '@chakra-ui/react'
import React from 'react'
import { menuData } from '../../dataObjects/SideBarObject'
import { SidebarItem } from '../sidebar/SidebarItem'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getPropertyData } from '../../dataFetchers/userProfileDataFetcher'
import { removeByAttr } from '../../helpers/CommonFunctions'

const Header = () => {
    let menuDataList = menuData.data
    const location = useLocation()
    let isFinanceTabVisible:boolean = false
    const {
        data: propertyData,
      } = useQuery("propertyData", getPropertyData);

    propertyData?.queryData?.map(val => {
        if (val.property_id.property_type === 'ALYF Growth') {
            isFinanceTabVisible = true
        }
        return true
    })
    
    if (isFinanceTabVisible === false) {
        menuDataList = removeByAttr(menuData.data, "name", "Finance")
    }

    if (location.pathname.includes('/calander')) {
        return (
            <>
            </>
        )
    }

    return (

            <Box zIndex="10" display="flex" justifyContent="center" h="16" w="90%" bg="#00272c" borderRadius="100px" position="fixed" bottom="10px" left={['5%', null, 'calc(((100vw - 60vh) / 2) + 3vh)', null ]} maxW="54vh" >
                
                {
                    menuDataList.map( (menuDataObject) => {
                        return (
                            <SidebarItem key={menuDataObject.id} menuObj={menuDataObject} />
                        )
                    })
                } 
            </Box>
    )
}

export default Header
