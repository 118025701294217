import React, { useState } from "react";
import Header from "../../components/Navbar/Header";
import { Navbar } from "../../components/header/Header";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  background,
} from "@chakra-ui/react";
import { getAllBookingsData } from "../../dataFetchers/userProfileDataFetcher";
import { useQuery } from "react-query";
import { BookingData } from "../../types/dataFetcherTypes/DataFetcherTypes";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import ShowMore from "../../components/showMore/showMoreBox";
import { useAuth } from "../../contexts/Auth";
import { useProperty } from "../../contexts/Property";
import { Booking } from "../../components/Icon/fin";

const UserBookingData = () => {
  const MAX_ALLOWED_DEVATION = 2;
  const [monthDevition, setMonthDevition] = useState(0);
  const { user } = useAuth();
  const { isLoading: isPropertyLoading, propertyData } = useProperty();
  const getMonth = () => {
    let date = new Date();
    date.setMonth(date.getMonth() + monthDevition);
    return date.toLocaleString("default", { month: "long" });
  };

  const getStartDateOfSelectedMonth = () => {
    let date = new Date();
    var firstDay = new Date(
      date.getFullYear(),
      date.getMonth() + monthDevition,
      1
    );
    console.log(firstDay);
    return firstDay
      .toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join("-");
  };

  const monthStartDate = getStartDateOfSelectedMonth();
  const year = new Date().getFullYear();

  const {
    data: bookingData,
    isLoading: isbookload,
    isError: isBookingerror,
  } = useQuery<BookingData>(
    [
      "bookingData",
      propertyData?.queryData.property_id.property_id,
      monthStartDate,
    ],
    () =>
      getAllBookingsData({
        property_id: propertyData?.queryData.property_id.property_id,
        firstday: monthStartDate,
        emailId: user?.email,
        userId: user?.id,
      })
  );

  console.log(bookingData);

  return (
    <Box marginTop="">
      <Flex
        marginTop="25px"
        justifyContent="space-between"
        alignItems="center"
        px="4"
        pt="4"
      >
        <Button
          disabled={monthDevition === -1 * MAX_ALLOWED_DEVATION}
          onClick={() =>
            setMonthDevition(prev => {
              if (prev > -1 * MAX_ALLOWED_DEVATION) {
                return prev - 1;
              } else {
                return prev;
              }
            })
          }
        >
          <ArrowBackIcon />
        </Button>
        <Text>
          {getMonth()} {year}
        </Text>
        <Button
          disabled={monthDevition === MAX_ALLOWED_DEVATION}
          onClick={() =>
            setMonthDevition(prev => {
              if (prev < MAX_ALLOWED_DEVATION) {
                return prev + 1;
              } else {
                return prev;
              }
            })
          }
        >
          <ArrowForwardIcon />
        </Button>
      </Flex>
      {isbookload && (
        <Box margin="auto">
          <iframe
            style={{ margin: "auto" }}
            src="https://lottie.host/embed/89decc3f-8395-4781-ad07-7dd3b61a26e1/UMtPG613D1.json"
          ></iframe>
        </Box>
      )}
      <Box marginTop="10px" mx="4" rounded="md">
        {bookingData &&
          bookingData.bookingDetails.map((data, i) => {
            return (
              <Box
                key={i}
                rounded="md"
                shadow="md"
                px="2"
                py="5"
                mb="5"
                bg="#fff"
              >
                <Flex
                  color="balck"
                  justifyContent="space-between"
                  px="2"
                  alignItems="center"
                >
                  <Text fontSize="xs">{data.booking_id}</Text>
                  <Text
                    fontSize="10px"
                    backgroundColor="#00272c"
                    color="#fff"
                    h="fit-content"
                    p="1"
                    rounded="sm"
                    fontFamily="poppins"
                    px="3"
                  >
                    {data.booking_category.toLocaleLowerCase()}
                  </Text>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  px="2"
                  alignItems="center"
                  mt="3"
                >
                  <Box>
                    <Text color="#D5D5D5" fontSize="sm" fontWeight="semibold">
                      Check in
                    </Text>
                    <Text fontSize="md">{data.checkin_date}</Text>
                  </Box>

                  <Box>
                    <Text
                      color="#D5D5D5"
                      fontSize="sm"
                      textAlign="right"
                      fontWeight="semibold"
                    >
                      Check Out
                    </Text>
                    <Text fontSize="md">{data.checkout_date}</Text>
                  </Box>
                </Flex>
                <Divider variant="dashed" mt="2" />
                <ShowMore height="0px" booking_id={data?.booking_id}>
                  <Heading fontSize="sm" mt="5" px="2" fontWeight="medium">
                    Customer Details
                  </Heading>
                </ShowMore>
              </Box>
            );
          })}
      </Box>
      {bookingData && bookingData.bookingDetails.length == 0 && (
        <Box>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="5"
            gap="2"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
              >
                <path
                  d="M29.5511 1.76075V2.26075H30.0511H35.0051C35.3276 2.26075 35.5891 2.52223 35.5891 2.84474V35.916C35.5891 36.2385 35.3276 36.5 35.0051 36.5H1.99023C1.66773 36.5 1.40625 36.2385 1.40625 35.916V2.84474C1.40625 2.52223 1.66773 2.26075 1.99023 2.26075H6.94412H7.44412V1.76075V1.08398C7.44412 0.761478 7.70559 0.5 8.0281 0.5C8.35061 0.5 8.61209 0.761479 8.61209 1.08398V1.76075V2.26075H9.11209H12.1789H12.6789V1.76075V1.08398C12.6789 0.761478 12.9404 0.5 13.2629 0.5C13.5854 0.5 13.8469 0.761478 13.8469 1.08398V1.76075V2.26075H14.3469H17.4137H17.9137V1.76075V1.08398C17.9137 0.761478 18.1751 0.5 18.4977 0.5C18.8202 0.5 19.0816 0.761478 19.0816 1.08398V1.76075V2.26075H19.5816H22.6484H23.1484V1.76075V1.08398C23.1484 0.761478 23.4099 0.5 23.7324 0.5C24.0549 0.5 24.3163 0.761478 24.3163 1.08398V1.76075V2.26075H24.8163H27.8832H28.3832V1.76075V1.08398C28.3832 0.761478 28.6446 0.5 28.9671 0.5C29.2896 0.5 29.5511 0.761478 29.5511 1.08398V1.76075ZM3.07422 3.42872H2.57422V3.92872V9.37979V9.87979H3.07422H33.9212H34.4212V9.37979V3.92872V3.42872H33.9212H30.0512H29.5512V3.92872V4.60542C29.5512 4.92792 29.2897 5.1894 28.9672 5.1894C28.6447 5.1894 28.3832 4.92792 28.3832 4.60542V3.92872V3.42872H27.8832H24.8164H24.3164V3.92872V4.60542C24.3164 4.92792 24.0549 5.1894 23.7324 5.1894C23.4099 5.1894 23.1484 4.92792 23.1484 4.60542V3.92872V3.42872H22.6484H19.5816H19.0816V3.92872V4.60542C19.0816 4.92792 18.8202 5.1894 18.4977 5.1894C18.1751 5.1894 17.9137 4.92792 17.9137 4.60542V3.92872V3.42872H17.4137H14.3469H13.8469V3.92872V4.60542C13.8469 4.92792 13.5855 5.1894 13.263 5.1894C12.9404 5.1894 12.679 4.92792 12.679 4.60542V3.92872V3.42872H12.179H9.11216H8.61216V3.92872V4.60542C8.61216 4.92792 8.35068 5.1894 8.02817 5.1894C7.70567 5.1894 7.44419 4.92792 7.44419 4.60542V3.92872V3.42872H6.94419H3.07422ZM2.57422 34.832V35.332H3.07422H33.9211H34.4211V34.832V11.5478V11.0478H33.9211H3.07422H2.57422V11.5478V34.832Z"
                  fill="black"
                  stroke="black"
                />
                <path
                  d="M20.2003 15.7227H16.8184C16.2197 15.7227 15.7344 16.208 15.7344 16.8066C15.7344 17.4053 16.2197 17.8906 16.8184 17.8906H20.2003C20.799 17.8906 21.2843 17.4053 21.2843 16.8066C21.2843 16.208 20.799 15.7227 20.2003 15.7227Z"
                  fill="black"
                />
                <path
                  d="M29.505 15.7227H26.123C25.5244 15.7227 25.0391 16.208 25.0391 16.8066C25.0391 17.4053 25.5244 17.8906 26.123 17.8906H29.505C30.1037 17.8906 30.589 17.4053 30.589 16.8066C30.589 16.208 30.1037 15.7227 29.505 15.7227Z"
                  fill="black"
                />
                <path
                  d="M10.88 15.7227H7.49805C6.8994 15.7227 6.41406 16.208 6.41406 16.8066C6.41406 17.4053 6.8994 17.8906 7.49805 17.8906H10.88C11.4787 17.8906 11.964 17.4053 11.964 16.8066C11.964 16.208 11.4787 15.7227 10.88 15.7227Z"
                  fill="black"
                />
                <path
                  d="M10.88 28.5508H7.49805C6.8994 28.5508 6.41406 29.0361 6.41406 29.6348C6.41406 30.2334 6.8994 30.7188 7.49805 30.7188H10.88C11.4787 30.7188 11.964 30.2334 11.964 29.6348C11.964 29.0361 11.4787 28.5508 10.88 28.5508Z"
                  fill="black"
                />
                <path
                  d="M20.2003 28.5508H16.8184C16.2197 28.5508 15.7344 29.0361 15.7344 29.6348C15.7344 30.2334 16.2197 30.7188 16.8184 30.7188H20.2003C20.799 30.7188 21.2843 30.2334 21.2843 29.6348C21.2843 29.0361 20.799 28.5508 20.2003 28.5508Z"
                  fill="black"
                />
                <path
                  d="M7.22389 25.1832C7.43556 25.3948 7.71299 25.5007 7.99034 25.5007C8.2677 25.5007 8.5452 25.3948 8.75679 25.1832L9.18598 24.754L9.61516 25.1832C9.82683 25.3948 10.1043 25.5007 10.3816 25.5007C10.659 25.5007 10.9365 25.3948 11.1481 25.1832C11.5714 24.7599 11.5714 24.0735 11.1481 23.6502L10.7189 23.221L11.1481 22.7918C11.5714 22.3686 11.5714 21.6822 11.1481 21.2589C10.7248 20.8356 10.0384 20.8356 9.61509 21.2589L9.18591 21.688L8.75672 21.2589C8.33346 20.8356 7.64708 20.8356 7.22375 21.2589C6.80042 21.6821 6.80042 22.3685 7.22375 22.7918L7.65293 23.221L7.22375 23.6502C6.80056 24.0735 6.80056 24.7598 7.22389 25.1832Z"
                  fill="black"
                />
                <path
                  d="M16.5442 25.1832C16.7559 25.3948 17.0333 25.5007 17.3107 25.5007C17.588 25.5007 17.8655 25.3948 18.0771 25.1832L18.5063 24.754L18.9355 25.1832C19.1471 25.3948 19.4246 25.5007 19.7019 25.5007C19.9793 25.5007 20.2568 25.3948 20.4684 25.1832C20.8917 24.7599 20.8917 24.0735 20.4684 23.6502L20.0392 23.221L20.4684 22.7918C20.8917 22.3686 20.8917 21.6822 20.4684 21.2589C20.0451 20.8356 19.3587 20.8356 18.9354 21.2589L18.5062 21.688L18.077 21.2589C17.6538 20.8356 16.9674 20.8356 16.5441 21.2589C16.1207 21.6821 16.1207 22.3685 16.5441 22.7918L16.9732 23.221L16.5441 23.6502C16.1208 24.0735 16.1208 24.7598 16.5442 25.1832Z"
                  fill="black"
                />
                <path
                  d="M29.7731 21.2589C29.3498 20.8356 28.6634 20.8356 28.2401 21.2589L27.8109 21.688L27.3817 21.2589C26.9585 20.8356 26.2721 20.8356 25.8487 21.2589C25.4254 21.6821 25.4254 22.3685 25.8487 22.7918L26.2779 23.221L25.8487 23.6502C25.4254 24.0735 25.4254 24.7598 25.8487 25.1832C26.0604 25.3948 26.3378 25.5007 26.6152 25.5007C26.8926 25.5007 27.1701 25.3948 27.3816 25.1832L27.8108 24.754L28.24 25.1832C28.4517 25.3948 28.7291 25.5007 29.0065 25.5007C29.2838 25.5007 29.5613 25.3948 29.7729 25.1832C30.1962 24.7599 30.1962 24.0735 29.7729 23.6502L29.3437 23.221L29.7729 22.7918C30.1964 22.3685 30.1964 21.6821 29.7731 21.2589Z"
                  fill="black"
                />
              </svg>
            </div>
            <Text
              mt="1"
              fontWeight="600"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
            >
              No Bookings Yet
            </Text>
            <Text
              mt="1"
              fontWeight="500"
              fontFamily="Montserrat"
              fontSize="14px"
              fontStyle="normal"
              px="6"
            >
              We will notify you if any booking is made.
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserBookingData;
