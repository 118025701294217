import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    UseDisclosureProps,
    Button,
    Img,
    Stack,
    Box,
    Heading,
    Text,
    useColorMode,
    useColorModeValue as mode,
    useMediaQuery,
    Center
} from "@chakra-ui/react"
import React from 'react'
import All from './Social'

interface ModalProps extends UseDisclosureProps {
    image: string | undefined
    OrgName: string | undefined
    Start_Date: string | undefined
    End_Date: string | undefined
    Event_type: string | undefined
    Event_name: string | undefined
    Certificate_type: string | undefined
    Org_thumb: string | undefined
}

export const CertificateModal = (props: ModalProps) => {
    const { isOpen, onOpen, onClose, image, OrgName, Start_Date, End_Date, Event_type, Certificate_type, Event_name, Org_thumb } = props;
    const [isLargerThan1000, isSmallerThan600] = useMediaQuery([
        "(min-width: 1000px)",
        "(max-width: 600px)"])
    // let sizeArray = ["6xl", "2xl", "lg"]
    // let modalSize;
    let inline;
    let img_mb;
    if (isLargerThan1000) {
        // modalSize = sizeArray[0];
        inline = true;
        img_mb = "10"
    } else if (isSmallerThan600) {
        // modalSize = sizeArray[2];
        inline = false;
        img_mb = "1"
    } else {
        // modalSize = sizeArray[1];
        inline = false;
        img_mb = "1"
    }


    return (

        <Modal onClose={onClose} isOpen={isOpen} isCentered size="wide" overflow="hidden"
            scrollBehavior="inside"
            max-width="1000px"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{Event_name}</ModalHeader>
                <Stack isInline={inline}>
                    <Center>
                        <Img
                            w={[300, 400, 500, 600, 900]}
                            objectFit="cover"
                            boxShadow="xl"
                            src={image}
                            alt="certificate_image"
                            transition="all 0.2s"
                            mr="10"
                            ml="10"
                            mb={img_mb}
                            _groupHover={{ transform: 'scale(1.05)' }}
                        />
                    </Center>
                    <ModalCloseButton />
                    <Stack isInline={!inline}>
                        <ModalBody>


                            <Box mb="2">
                                <Text color={mode('gray.600', 'gray.400')} lineHeight="tall">
                                    Organised by:
                                </Text>
                                <Stack isInline>
                                    {inline &&
                                        <Img
                                            objectFit="cover"
                                            rounded="full"
                                            htmlHeight="40px"
                                            htmlWidth="40px"
                                            w="10"
                                            h="10"
                                            src={Org_thumb} />
                                    }
                                    <Heading size="md" mt="2" mb="2">
                                        {OrgName}
                                    </Heading>


                                </Stack>
                                <Text color={mode('gray.600', 'gray.400')} lineHeight="tall">
                                    Event Started :{' '}
                                </Text>

                                {Start_Date}
                                <br />
                                <Text color={mode('gray.600', 'gray.400')} lineHeight="tall">
                                    Event Ended :{' '}
                                </Text>

                                {End_Date}
                                <Box as="a" href='/'>
                                    <Heading size="md" mt="2" mb="2">
                                        {Event_type}
                                    </Heading>
                                </Box>
                                <Text color={mode('gray.600', 'gray.400')} lineHeight="tall">
                                    {OrgName}<br />

                                    {Certificate_type} <br />
                                </Text>
                            </Box>

                        </ModalBody>

                        <ModalFooter>


                            <Stack >


                                {!inline &&
                                    <Center>
                                        <Img
                                            objectFit="cover"
                                            rounded="full"
                                            htmlHeight="40px"
                                            htmlWidth="40px"
                                            w="20"
                                            h="20"
                                            src={Org_thumb} />
                                    </Center>
                                }

                                <All />
                            </Stack>
                        </ModalFooter>
                    </Stack>
                </Stack>

            </ModalContent>
        </Modal>
    )
}