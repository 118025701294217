import React, { HTMLProps } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  StackDivider,
  Heading,
  FormLabel,
  FormControl,
  VStack,
  Input,
  FormErrorMessage,
  Button,
  Textarea,
  FormHelperText,
  InputLeftAddon,
  InputGroup,
  HStack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { FieldGroup } from "../../components/fieldGroup/FieldGroup";
import { ButtonRadioGroup } from "../../components/buttonRadioGroup/ButtonRadioGroup";
import moment from "moment";
import { createDateWithDays } from "../../helpers/CommonFunctions";
import {
  FaTransgenderAlt,
  FaMale,
  FaFemale,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { useState } from "react";
import { useAuth } from "../../contexts/Auth";
import { DrawerComponent } from "../drawerComponent/DrawerComponent";
import { ChangePassword } from "../changePassword/ChangePassword";
import { MobileOtp } from "../../pages/signin/signinOtp";

export interface ProfileFormInputType {
  fullName?: string;
  gender?: "male" | "female" | "other" | null;
  genderString?: string | null;
  dob?: Date | string;
  anniversary?: Date | string;
  linkedinId?: string;
  facebookHandle?: string;
  twitterHandle?: string;
  instaHandle?: string;
  about?: string;
  avatarURL?: string;
}

interface ProfileFormPropsType extends HTMLProps<HTMLFormElement> {
  initialData: ProfileFormInputType;
  onSubmitFunction: (arg: any) => any;
}

export const ProfileForm = (props: ProfileFormPropsType) => {
  const { initialData, onSubmitFunction, ...restProps } = props;

  const [genderValue, setgenderValue] = useState<
    "male" | "female" | "other" | null
  >(initialData.gender!);
  const [isSavePasswordLoading, setIsSavePasswordLoading] = useState(false);
  const { user } = useAuth();

  const schema = yup.object().shape({
    fullName: yup.string().min(3),
    genderString: yup.string().nullable(),
    dob: yup
      .date()
      .nullable()
      .default(createDateWithDays(-30))
      .max(
        moment(new Date()).subtract(30, "days").format("YYYY-MM-DD").toString(),
        "Sorry, But we had trouble when we used to let toddlers operate"
      )

      .min(
        moment(new Date())
          .subtract(100, "years")
          .format("YYYY-MM-DD")
          .toString(),
        "Belive us we tried but we can't find your name in record books"
      ),
    anniversary: yup
      .date()
      .nullable()
      .default(createDateWithDays(-30))
      .max(
        moment(new Date()).subtract(30, "days").format("YYYY-MM-DD").toString(),
        "Sorry, But we had trouble when we used to let toddlers operate"
      )

      .min(
        moment(new Date())
          .subtract(100, "years")
          .format("YYYY-MM-DD")
          .toString(),
        "Belive us we tried but we can't find your name in record books"
      ),

    about: yup.string(),
  });

  const methods = useForm<ProfileFormInputType>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: initialData,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleProfileSaveButtonClick = async (values: any) => {
    values.gender = genderValue
      ? ["male", "female"].includes(genderValue)
        ? genderValue
        : values.genderString
      : initialData.gender;
    values.id = user?.id;
    values.email = user?.email;
    values.fullName =
      values.fullName === initialData.fullName ? null : values.fullName;
    values.about = values.about === initialData.about ? null : values.about;
    values.avatarURL =
      values.avatarURL === initialData.avatarURL ? null : values.avatarURL;
    await onSubmitFunction(values);
  };

  return (
    <form onSubmit={handleSubmit(handleProfileSaveButtonClick)} {...restProps}>
      <Stack
        spacing="4"
        divider={<StackDivider />}
        mt={12}
        w={{
          base: undefined,
          sm: "md",
          md: "xl",
        }}
      >
        <Heading
          size="lg"
          as="h1"
          paddingBottom="4"
          fontFamily="Cormorant Garamond"
        >
          My Profile
        </Heading>
        <FieldGroup title="Personal Info" fontFamily="Montserrat">
          <VStack width="full" spacing="6">
            <FormControl
              id="fullname"
              isInvalid={!!errors?.fullName}
              errortext={errors?.fullName?.message}
            >
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                autoComplete="name"
                maxLength={255}
                {...register("fullName")}
              />
              <FormErrorMessage>{errors?.fullName?.message}</FormErrorMessage>
            </FormControl>

            {/* <FormControl
              id="about"
              isInvalid={!!errors?.about}
              errortext={errors?.about?.message}
            >
              <FormLabel>About</FormLabel>
              <Textarea
                type="text"
                autoComplete="name"
                maxLength={255}
                rows={5}
                {...register("about")}
              />
              <FormErrorMessage>{errors?.about?.message}</FormErrorMessage>
              <FormHelperText>Some words about you.</FormHelperText>
            </FormControl> */}

            <FormControl
              id="dob"
              isInvalid={!!errors?.dob}
              errortext={errors?.dob?.message}
            >
              <FormLabel>Date Of Birth</FormLabel>
              <Input
                type="date"
                autoComplete="dob"
                maxLength={255}
                {...register("dob")}
              />
              <FormErrorMessage>{errors?.dob?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="anniversary"
              isInvalid={!!errors?.anniversary}
              errortext={errors?.anniversary?.message}
            >
              <FormLabel>Anniversary</FormLabel>
              <Input
                type="date"
                autoComplete="anniversary"
                maxLength={255}
                {...register("anniversary")}
              />
              <FormErrorMessage>
                {errors?.anniversary?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="gender"
              isInvalid={!!errors?.dob}
              errortext={errors?.dob?.message}
            >
              <FormLabel>Gender</FormLabel>
              <ButtonRadioGroup
                defaultValue={initialData.gender!}
                options={[
                  {
                    label: "Male",
                    icon: <FaMale />,
                    value: "male",
                  },
                  {
                    label: "Female",
                    icon: <FaFemale />,
                    value: "female",
                  },
                  {
                    label: "Other",
                    icon: <FaTransgenderAlt />,
                    value: "other",
                  },
                ]}
                //@ts-ignore
                onChange={setgenderValue}
              />
              <FormErrorMessage>{errors?.gender?.message}</FormErrorMessage>
            </FormControl>

            {genderValue === "other" && (
              <FormControl
                id="genderString"
                isInvalid={!!errors?.genderString}
                errortext={errors?.genderString?.message}
              >
                <FormLabel>How do you identify yourself</FormLabel>
                <Input
                  type="text"
                  autoComplete="gender"
                  maxLength={255}
                  {...register("genderString")}
                />
                <FormErrorMessage>
                  {errors?.genderString?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          </VStack>
        </FieldGroup>

        <FieldGroup title="Security">
          <VStack width="full" spacing="6">
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input type="text" isDisabled={true} defaultValue={user?.email} />
            </FormControl>

            <FormControl>
              <FormLabel>Password</FormLabel>
              <HStack>
                <Text>**********</Text>

                <DrawerComponent
                  triggerComponent={
                    <Button
                      ml="4"
                      p={2}
                      variant="link"
                      color={mode("blue.600", "blue.300")}
                    >
                      Change
                    </Button>
                  }
                  heading="Change Password"
                  showFooter={true}
                  footerButtons={
                    <Button
                      colorScheme="blue"
                      type="submit"
                      form="changePasswordFromProfile"
                      isLoading={isSavePasswordLoading}
                      loadingText="Processing"
                    >
                      Save
                    </Button>
                  }
                >
                  <ChangePassword
                    showActionOnBottom={true}
                    formId="changePasswordFromProfile"
                    loadingStatus={setIsSavePasswordLoading}
                    hideSubmitButton={true}
                  />
                </DrawerComponent>
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Mobile</FormLabel>
              <HStack>
                <Text>**********</Text>

                <DrawerComponent
                  triggerComponent={
                    <Button
                      ml="4"
                      p={2}
                      variant="link"
                      color={mode("blue.600", "blue.300")}
                    >
                      Change
                    </Button>
                  }
                  showFooter={false}
                >
                  <MobileOtp isPhoneChange={true} />
                </DrawerComponent>
              </HStack>
            </FormControl>
          </VStack>
        </FieldGroup>

        <FieldGroup mt="8">
          <HStack width="full">
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting && Object.keys(errors).length === 0}
              loadingText="Submitting.."
              // isDisabled={!isDirty}
            >
              Save Changes
            </Button>
            <Button variant="outline">Cancel</Button>
          </HStack>
        </FieldGroup>
      </Stack>
    </form>
  );
};
