import React from 'react'
import { Main } from '../../components/main/Main'
import { Navbar } from '../../components/header/Header'
import Header from '../../components/Navbar/Header'

export default function Dashboard() {
    return (
        <>
            <Navbar />
            <Header />
            <Main />
        </>
    )
}
