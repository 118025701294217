import {
  Box,
  Button,
  Flex,
  LightMode,
  Stack,
  InputGroup,
  InputLeftAddon,
  Icon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useColorModeValue as mode,
  Spacer,
} from "@chakra-ui/react";
import * as React from "react";
import { FaUser } from "react-icons/fa";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordField } from "../passwordField/PasswordField";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { displayError, displaySuccess } from "../../helpers/CommonFunctions";
import { useState } from "@hookstate/core";
import { redirectURLHolderOnAuth } from "../../stores/AuthStore";
import supabase from "../../configs/Supabase";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

export type SigninFormInputs = {
  email: string;
  password: string;
};

export const SigninForm = () => {
  const { signinByEmail } = useAuth();
  const history = useHistory();
  const redirectUrl = useState(redirectURLHolderOnAuth);

  const handleSigninButtonClick = async (values: SigninFormInputs) => {
    const { user, error } = await signinByEmail(values);
    console.log(user, error);

    if (error) {
      displayError("", "", true, {
        type: "USER",
        subType: "INVALID_AUTH",
        code: error.status,
        error: error,
      });
    } else {
      displaySuccess("Congratulations", "You are logged in successfully");
      history.push(
        redirectUrl.get()
          ? redirectUrl.get().replace(window.location.origin, "")
          : window.location.origin
      );
    }
  };

  const methods = useForm<SigninFormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSigninButtonClick)}>
        <Stack spacing="6">
          <FormControl
            id="email"
            isInvalid={!!errors?.email}
            errortext={errors?.email?.message}
            isRequired
          >
            <Flex align="baseline" justify="space-between">
              <FormLabel mb={1}>Email</FormLabel>
            </Flex>
            <InputGroup>
              <InputLeftAddon children={<Icon as={FaUser} />} />
              <Input
                type="email"
                autoComplete="email"
                {...register("email")}
                placeholder="someone@email.com"
              />
            </InputGroup>
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <Box>
            <FormControl
              id="password"
              isInvalid={!!errors?.password}
              errortext={errors?.password?.message}
              isRequired
            >
              <Flex align="baseline" justify="space-between">
                <FormLabel mb={1}>Password</FormLabel>
              </Flex>
              <PasswordField name="password" />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>
            <Flex mt="2">
              <Box
                display="inline-block"
                as={Link}
                to="/forgot-password"
                color={mode("blue.600", "blue.300")}
                fontWeight="semibold"
                fontSize="sm"
                mt="3"
              >
                Forgot password?
              </Box>
              <Spacer />
              <Box
                display="inline-block"
                as={Link}
                to="/get-magic-link"
                color={mode("blue.600", "blue.300")}
                fontWeight="semibold"
                fontSize="sm"
                mt="3"
              >
                Get magic link?
              </Box>
            </Flex>
          </Box>
        </Stack>
        <Flex
          spacing="4"
          direction={{ base: "column-reverse", md: "row" }}
          mt="6"
          align="center"
          justify="space-between"
        >
          {/* <Text color={mode('gray.600', 'gray.400')} fontSize="sm" fontWeight="semibold">
					New user?{' '}
					<Box as={Link} to="/signup" color={mode('blue.600', 'blue.300')}>
					Create account
					</Box>
				</Text> */}
          <LightMode>
            <Button
              mb={{ base: "4", md: "0" }}
              mt="4"
              w="full"
              type="submit"
              bg="#00272c"
              color="#FFFFFF"
              size="lg"
              fontSize="md"
              fontWeight="bold"
              isLoading={isSubmitting}
              loadingText="Signin in..."
              isDisabled={Object.keys(errors).length !== 0}
            >
              Sign in
            </Button>
          </LightMode>
        </Flex>
      </form>
    </FormProvider>
  );
};
