import React, { useState, useEffect, useCallback } from "react";
import { Button, useToast, Text } from "@chakra-ui/react";

const ResendOTP = ({
  resend,
  timeInSeconds,
  retryCount,
}: {
  resend: () => {};
  timeInSeconds: number;
  retryCount: number;
}) => {
  const [timer, setTimer] = useState(timeInSeconds);
  const [isDisabled, setIsDisabled] = useState(true);
  const [attempts, setAttempts] = useState(0); // Track the number of OTP resend attempts
  const toast = useToast();

  // Function to start the countdown
  const startCountdown = useCallback(() => {
    setIsDisabled(true); // Disable the resend button
    setTimer(timeInSeconds); // Reset the timer

    const countdown = setInterval(() => {
      setTimer(prev => {
        if (prev === 1) {
          clearInterval(countdown);
          setIsDisabled(false); // Enable the resend button
        }
        return prev - 1;
      });
    }, 1000);
  }, [timeInSeconds]);

  // Handle the resend click
  const handleResendClick = () => {
    if (attempts < retryCount) {
      // Show toast notification
      toast({
        title: "OTP Resent.",
        description: "The OTP has been resent to your registered number.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      resend(); // Call the resend function passed in props
      setAttempts(prev => prev + 1); // Increment the attempt count
      startCountdown(); // Restart the timer
    } else {
      // Show a toast message that retry limit has been reached
      toast({
        title: "Retry Limit Reached.",
        description: `You have exceeded the maximum retry count of ${retryCount}. Please try again later.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Start the countdown when the component mounts
  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  return (
    <div>
      <Button
        variant="link"
        colorScheme={isDisabled || attempts >= retryCount ? "gray" : "blue"}
        onClick={handleResendClick}
        isDisabled={isDisabled || attempts >= retryCount} // Disable if retry count is exceeded
      >
        {isDisabled && attempts < retryCount
          ? `Resend OTP in ${timer} seconds`
          : attempts >= retryCount
          ? "Retry Limit Reached"
          : "Resend OTP"}
      </Button>
      {attempts < retryCount && (
        <Text mt={2}>
          {`You have ${retryCount - attempts} ${
            retryCount - attempts === 1 ? "attempt" : "attempts"
          } remaining.`}
        </Text>
      )}
    </div>
  );
};

export default ResendOTP;
