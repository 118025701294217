import { Center, Stack, Img, Box, Heading } from '@chakra-ui/react'
import React from 'react'

interface errorComponentErrorMessageType {
    errorMessage: string
}

export const ErrorComponent = ( { errorMessage }:errorComponentErrorMessageType ) => {
    return (
        <Center position="relative">
            <Stack>
                <Img
                    w="100%"
                    alt="Error Cat"
                    objectFit="cover"
                    src="/images/cute-2528119.jpg"
                />
                <Box 
                    textAlign="center" 
                    position={{
                        base: undefined,
                        md: "absolute"
                    }}
                    top={{
                        base: undefined,
                        md: '20px'
                    }}
                    left={{
                        base: undefined,
                        md: '20px'
                    }}
                    color={{
                        base: undefined,
                        md: 'gray.800'
                    }}
                >
                    <Heading 
                        as="h3" 
                        size="lg" 
                    >
                        Whoops! You weren't meant to see that.
                    </Heading>
                    <Heading 
                        as="h5" 
                        size="sm"
                        mt={{
                            base: 2,
                            md: 5
                        }}
                    >
                        Please wait or reload while our cats are firefighting
                    </Heading>
                    { errorMessage && <Heading 
                        as="h5" 
                        size="sm"
                        mt={{
                            base: 1,
                            md: 2
                        }}
                    >
                        Error Message:- {errorMessage}
                    </Heading>}
                </Box>
            </Stack>
        </Center>
    )
}
