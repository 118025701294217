import moment from "moment";
import supabase from "../configs/Supabase";
import { displayError } from "../helpers/CommonFunctions";
import {
  BookingData,
  bookingFetcherObjectReturnType,
  bookingObjectType,
  BookingsReturnType,
  expensesFetcherObjectReturnType,
  expensesObjectType,
  GetReturnType,
  profileFetcherObjectReturnType,
  propertyFetcherObjectReturnType,
  propertyLocationFetcherObjectReturnType,
  propertyLocationObjectType,
  propertyObjectType,
} from "../types/dataFetcherTypes/DataFetcherTypes";
import { DEMO_USER_IDS } from "../configs/GlobalConstants";
import { QueryFunctionContext } from "react-query";

export const getUserProfileData = async (): Promise => {
  try {
    let isError = false;

    const { data, error } = await supabase
      .from("profile")
      .select(
        `
                                    name
                                `
      )
      .single();
    if (error) {
      isError = true;
    }

    return {
      isQueryError: isError,
      queryData: data,
      queryError: error,
    };
  } catch (error) {
    throw error;
  }
};

export const getPropertyData =
  async (): Promise<propertyFetcherObjectReturnType> => {
    try {
      let isError = false;

      const { data, error } = await supabase.from("ownership").select(`
                                    shares_owned,
                                    ownership_percentage,
                                    investment_amount,
                                    q1_mg_amount,
                                    is_resale,
                                    start_date,
                                    property_id(
                                        property_id,
                                        project_name,
                                        description,
                                        property_images,
                                        property_category,
                                        property_type,
                                        flat_number,
                                        location_id(
                                            state,
                                            country
                                        )
                                    )
                                `);
      if (error) {
        isError = true;
      }

      return {
        isQueryError: isError,
        queryData: data as any as propertyObjectType[],
        queryError: error,
      };
    } catch (error) {
      throw error;
    }
  };

export const getExpensesData =
  async (): Promise<expensesFetcherObjectReturnType> => {
    try {
      let isError = false;

      const { data, error } = await supabase.from("rental_mis").select(`
                                    property_id,
                                    available_days,
                                    occupancy_achieved_in_days,
                                    occupancy_achieved_in_percentage,
                                    arr_achieved,
                                    revenue_generated,
                                    alyf_share,
                                    operating_expenses,
                                    owner_share,
                                    payout_date,
                                    minimum_return_as_per_pma,
                                    amount_payable,
                                    amount_payable_per_share_gross,
                                    amount_payable_per_share_net_taxation,
                                    quarter,
                                    year
                                `);
      if (error) {
        isError = true;
      }

      return {
        isQueryError: isError,
        queryData: data as any as expensesObjectType[],
        queryError: error,
      };
    } catch (error) {
      throw error;
    }
  };

export const getBookingsData = async (
  userId?: string
): Promise<bookingFetcherObjectReturnType> => {
  try {
    let isError = false;

    let tableName = "booking_duplicate";
    if (userId && !DEMO_USER_IDS.includes(userId)) {
      tableName = "booking";
    }

    const { data, error } = await supabase
      .from(tableName)
      .select(
        `
                                    guest_name,
                                    checkin_date,
                                    checkout_date,
                                    stay_type,
                                    booking_user_id,
                                    booking_id(
                                        booking_id,
                                        property_id
                                    )
                                `
      )
      .eq("booking_status", "confirmed")
      .order("checkin_date", {
        ascending: true,
      });
    if (error) {
      isError = true;
    }

    return {
      isQueryError: isError,
      queryData: data as any as bookingObjectType[],
      queryError: error,
    };
  } catch (error) {
    throw error;
  }
};

export const getPropertyLocationData = async (
  propertyId?: string
): Promise<propertyLocationFetcherObjectReturnType> => {
  try {
    let isError = false;

    let query = supabase.from("property").select(`
                                    property_id,
                                    property_type,
                                    project_name,
                                    flat_number,
                                    seasons,
                                    location_id(
                                        location_id,
                                        location_name,
                                        state,
                                        seasons,
                                        special_days_calender
                                    )
                                `);

    if (propertyId !== undefined) {
      query = query.eq("property_id", propertyId);
    }

    const { data, error } = await query;

    if (error) {
      isError = true;
    }

    return {
      isQueryError: isError,
      queryData: data as any as propertyLocationObjectType[],
      queryError: error,
    };
  } catch (error) {
    throw error;
  }
};

export const setUserProfileData = async (updatedData: any): Promise<any> => {
  // console.log('query value', updatedData)

  const updatedDataObject = {
    user_id: updatedData.id,
    name: updatedData.fullName,
    email: updatedData.email,
    gender: updatedData.gender,
    dob: moment(updatedData.dob).utc(),
    // avatar_url: updatedData.avatarURL
  };

  let isError = false;
  const { data, error } = await supabase
    .from("profile")
    .upsert(updatedDataObject);
  console.log(data);
  if (error) {
    isError = true;
    displayError(
      "Action not allowed",
      error.message || error.details || error.hint
    );
  }

  const returnData = {
    ...data[0],
    dob: moment(data[0].dob).local(),
  };

  return {
    isQueryError: isError,
    queryData: returnData,
    queryError: error,
  };
};

export const getAllBookingsData = async ({
  property_id: property_id,
  userId = "123",
  emailId = "indraindrani1999@gmail.com",
  firstday = "01-08-2024",
  period = "month",
}: {
  property_id: string;
  userId?: string;
  emailId?: string;
  firstday?: string | Date;
  period?: string;
}): Promise<BookingData> => {
  console.log(
    `https://alyf-central-api-4qlkkmjfwq-el.a.run.app/bookings/property/${property_id}?period=${period}&startDate=${firstday}&userEmail=${emailId}&userId=${userId}`
  );

  try {
    const response = await fetch(
      `https://alyf-central-api-4qlkkmjfwq-el.a.run.app/bookings/property/${property_id}?period=${period}&startDate=${firstday}&userEmail=${emailId}&userId=${userId}`,
      {
        headers: {
          "x-api-key": `abce2$lvtumkp3snhyi45lokcsx`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching booking data:", error);
    throw error;
  }
};

export const getBookingPriceData = async ({
  booking_id = "123",
}: {
  booking_id: string;
}): Promise<any> => {
  try {
    const response = await fetch(
      `https://alyf-central-api-4qlkkmjfwq-el.a.run.app/bookings?bookingId=${booking_id}`,
      {
        headers: {
          "x-api-key": `abce2$lvtumkp3snhyi45lokcsx`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching booking data:", error);
    throw error;
  }
};

export const getAllUpcomingAndCompletedBookings = async ({
  queryKey,
}: QueryFunctionContext<[string | undefined, string | undefined]>) => {
  const [property_id, userId] = queryKey;

  if (property_id === undefined || userId === undefined)
    throw new Error("Parameters can not be undefined!!");

  try {
    const response = await fetch(
      `https://alyf-central-api-4qlkkmjfwq-el.a.run.app/bookings/property/${property_id}/stays?userId=${userId}`,
      {
        headers: {
          "x-api-key": `abce2$lvtumkp3snhyi45lokcsx`,
        },
      }
    );

    if (!response.ok) throw new Error("Something went wrong!");
    const { data, error, message } = (await response.json()) as GetReturnType<BookingsReturnType[]>;
    if (error !== null) throw new Error("Something went wrong!");

    return data;
  } catch (error) {
    console.error("Error fetching booking data:", error);
    throw new Error("Something went wrong!");
  }
};
// .select( `
//     full_name as name,
//     gender,
//     date_of_birth as dob,
//     linkedin_id as linkedinId,
//     facebook_handle as facebookHandle,
//     twitter_handle as twitterHandle,
//     insta_handle as instaHandle,
//     avatar_url as avatarURL
// `)
