import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  TabPanel,
  TabPanels,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ImSpoonKnife } from "react-icons/im";
import { Button } from "@chakra-ui/react";
import { AiFillCar } from "react-icons/ai";
import { Tab, TabList, Tabs } from "@chakra-ui/react";
import { StatCard } from "../../components/statCard/StatCard";
import Percentage from "../../assets/images/modern-house-3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Barbecue, Check, Live, Pickup, Rent } from "../../components/Icon/fin";
import { useQuery, useQueryClient } from "react-query";
import {
  getAllBookingsData,
  getAllUpcomingAndCompletedBookings,
  getBookingsData,
  getUserProfileData,
} from "../../dataFetchers/userProfileDataFetcher";
import { getPropertyData } from "../../dataFetchers/userProfileDataFetcher";
import { useAuth } from "../../contexts/Auth";
import { GoLocation } from "react-icons/go";
import supabase from "../../configs/Supabase";
import {
  BookingData,
  BookingDetails,
} from "../../types/dataFetcherTypes/DataFetcherTypes";
import { parseDate } from "../../components/homePagePropertyCard/HomePagePropertyCard";
import { getStartDateOfSelectedMonth } from "../MyDashboard/Mydashboard";
import { useProperty } from "../../contexts/Property";
import moment from "moment";
interface ArrowProps {
  className?: string;
  onClick?: () => void;
}
export const SampleNextArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      position="absolute"
      top="50%"
      right="0%"
      zIndex="10"
      width="1%"
      h="30px"
      borderRadius="full"
    >
      <ArrowForwardIcon fontSize={"medium"} />
    </Button>
  );
};
export const SamplePrevArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      position="absolute"
      top="50%"
      zIndex="10"
      width="1%"
      h="30px"
      borderRadius="full"
    >
      <ArrowBackIcon fontSize={"medium"} />
    </Button>
  );
};
const Mystay = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isPropertyLoading, propertyData } = useProperty();
  const { isError, isLoading, data, error } = useQuery(
    "userProfile",
    getUserProfileData
  );

  let firstDay = getStartDateOfSelectedMonth();

  const userId = user?.id;
  const {
    data: allBookdata,
    isLoading: isbookload,
    isError: isBookingerror,
  } = useQuery<BookingData>(
    ["bookingData", propertyData?.queryData.property_id.property_id],
    () =>
      getAllBookingsData({
        property_id: propertyData?.queryData.property_id.property_id,
        firstday: firstDay,
        emailId: user?.email,
        userId: user?.id,
      })
  );

  const {
    isLoading: bookinLoading,
    data: bookingData,
    error: bookingsError,
  } = useQuery({
    queryKey: [propertyData?.queryData.property_id.property_id, user?.id],
    queryFn: getAllUpcomingAndCompletedBookings,
    enabled: !!user?.id,
  });

  let nextBookingArray =
    bookingData?.filter(({ status }) => status === "upcoming") || [];
  let completedBookingArray =
    bookingData?.filter(({ status }) => status === "completed") || [];
  // console.log(allBookdata);
  // allBookdata?.bookingDetails.map((data: BookingDetails, i: React.Key) => {
  //   const checkoutDateParts = data.checkout_date.split("-");
  //   //@ts-ignore
  //   const checkoutDate = new Date(
  //     checkoutDateParts[2],
  //     checkoutDateParts[1] - 1,
  //     checkoutDateParts[0]
  //   );
  //   console.log(checkoutDate);
  //   if (checkoutDate > new Date() && data.booking_category === "SELF_BOOKING") {
  //     nextBookingArray.push(data);
  //   } else if (data.booking_category === "SELF_BOOKING") {
  //     completedBookingArray.push(data);
  //     console.log("8uu");
  //   }
  // });

  const toast = useToast();

  const handlesubmit = async (val: string) => {
    try {
      const { error } = await supabase.from("valueadd_services").insert({
        user_id: user?.id,
        name: data.queryData.name,
        email: user?.email,
        service_name: val,
        property_name: propertyData?.queryData.property_id.project_name,
      });
      if (error) {
        toast({
          title: ``,
          status: "error",
          isClosable: true,
        });
      } else {
        onOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const queryClient = useQueryClient();
  const invalidateBookingCache = () => {
    queryClient.invalidateQueries("bookingsData");
  };

  return (
    <div>
      <div>
        <Box borderRadius="10px" px="4" pt="4" mt="4">
          <Slider {...settings}>
            {propertyData?.queryData.property_id.property_images.map(
              (ele, i) => {
                return (
                  <Box key={i}>
                    <Image
                      h="35vh"
                      w="full"
                      objectFit={"cover"}
                      borderRadius="10px"
                      src={ele.replace(
                        "https://authapi.alyf.in",
                        "https://rkuwgpjctkcphphvextv.supabase.co"
                      )}
                      alt=""
                    />
                  </Box>
                );
              }
            )}
          </Slider>
          <Box
            display="flex"
            justifyContent="space-between"
            width="95%"
            alignItems="center"
            mt="2"
          >
            <Text
              fontWeight="600"
              fontFamily="Poppins"
              fontSize="16px"
              fontStyle="normal"
              color="#3C3C3C"
              mt="2"
            >
              {propertyData?.queryData.property_id.project_name}
            </Text>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box mt="1">
                <GoLocation fontSize="16px" />
              </Box>
              <Text
                fontWeight="600"
                fontFamily="Poppins"
                fontSize="16px"
                fontStyle="normal"
                color="#3C3C3C"
                mt="2"
                ml="2"
              >
                {propertyData?.queryData.property_id.location_id.state}
              </Text>
            </Box>
          </Box>
        </Box>
        <Tabs
          position="relative"
          size="sm"
          pt="5"
          pb="5"
          mb="5"
          variant="solid-rounded"
          bg="FAFAFA8"
          borderRadius="3px"
          boxShadow="8px 8px 16px 0px rgba(0, 0, 0, 0.05)"
        >
          <TabList
            w="100%"
            fontWeight="semibold"
            py="1"
            px="2"
            boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.05)"
            display="flex"
            justifyContent="space-between"
            borderRadius="full"
            h={"50px"}
          >
            <Tab
              _selected={{ bg: "#00272c", color: "#ffffff", fontWeight: "500" }}
              fontSize="12px"
              w="50%"
              p="4"
              fontFamily="poppins"
            >
              Upcoming Stays
            </Tab>
            {/* <Tab textAlign="end">Cancelled </Tab> */}
            <Tab
              _selected={{ bg: "#00272c", color: "#ffffff" }}
              fontSize="12px"
              w="50%"
              p="4"
              fontFamily="poppins"
            >
              Completed Stays
            </Tab>
          </TabList>
          <TabPanels w="100%" px="1">
            <TabPanel>
              {nextBookingArray !== undefined ? (
                <>
                  {nextBookingArray.map((data, i) => (
                    <StatCard
                      key={i}
                      bookingdata={{
                        from: `${moment(data.checkin_date).format(
                          "DD MMM, yyyy"
                        )} `,

                        to: `${moment(data.checkout_date).format(
                          "DD MMM, yyyy"
                        )} `,
                        isCompleted: false,
                        isInvalid: invalidateBookingCache,
                        id: data.booking_id,
                      }}
                    />
                  ))}

                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt="8"
                  >
                    <Image w={"50px"} h="50px" src="/images/addbook.png" />
                    <Text
                      mt="4"
                      fontWeight="500"
                      fontFamily="Poppins"
                      fontStyle="normal"
                      fontSize="18px"
                    >
                      Your Happy Place Awaits
                    </Text>
                    <Box
                      textAlign={"center"}
                      m={"auto"}
                      mt="10px"
                      border={"1px"}
                      p="2"
                      px="6"
                      rounded={"8px"}
                      shadow="sm"
                    >
                      <Link
                        href={`${`/calander/${propertyData?.queryData.property_id.property_id}`}`}
                      >
                        <Heading
                          size="lg"
                          cursor={"pointer"}
                          fontFamily="Poppins"
                          fontSize="16px"
                          fontWeight="500"
                          color="#00272c"
                        >
                          Explore Dates
                        </Heading>
                      </Link>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Image src={Percentage} w="60px" h="60px" mt="2" mb="4" />
                  <Text
                    mt="1"
                    fontWeight="500"
                    fontFamily="Poppins"
                    fontSize="16px"
                    fontStyle="normal"
                  >
                    Want to visit Home again?
                  </Text>
                  <Box textAlign={"center"} m={"auto"} mt="10px">
                    <Link
                      href={`${`/calander/${propertyData?.queryData.property_id.property_id}`}`}
                    >
                      <Heading
                        size="lg"
                        cursor={"pointer"}
                        fontWeight="700"
                        fontFamily="Poppins"
                        fontSize="18px"
                        fontStyle="semibold"
                        color="#245E53"
                      >
                        Explore Dates
                      </Heading>
                    </Link>
                  </Box>
                </Box>
              )}
            </TabPanel>
            <TabPanel px="4">
              {completedBookingArray !== undefined ? (
                <>
                  {completedBookingArray.map((data, i) => (
                    <StatCard
                      key={i}
                      bookingdata={{
                        from: `${moment(data.checkin_date).format(
                          "DD MMM, yyyy"
                        )} `,

                        to: `${moment(data.checkout_date).format(
                          "DD MMM, yyyy"
                        )} `,
                        isInvalid: invalidateBookingCache,
                        id: data.booking_id,
                        isCompleted: true,
                      }}
                    />
                  ))}

                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt="8"
                  >
                    <Image w={"50px"} h="50px" src="/images/addbook.png" />
                    <Text
                      mt="4"
                      fontWeight="600"
                      fontFamily="Cormorant Garamond"
                      fontStyle="normal"
                      fontSize="18px"
                    >
                      Your Happy Place awaits
                    </Text>
                    <Box textAlign={"center"} m={"auto"} mt="10px">
                      <Link
                        href={`${`/calander/${propertyData?.queryData.property_id.property_id}`}`}
                      >
                        <Heading
                          size="lg"
                          cursor={"pointer"}
                          fontWeight="600"
                          fontFamily="Poppins"
                          fontSize="18px"
                          fontStyle="normal"
                          color="#245E53"
                        >
                          Explore Dates
                        </Heading>
                      </Link>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Image src={Percentage} w="60px" h="60px" my="4" />
                  <Text
                    mt="1"
                    fontWeight="500"
                    fontFamily="Poppins"
                    fontSize="16px"
                    fontStyle="normal"
                  >
                    Your Happy Place awaits
                  </Text>
                  <Box textAlign={"center"} m={"auto"} mt="10px">
                    <Link
                      href={`${`/calander/${data.property_id.property_id}`}`}
                    >
                      <Heading
                        size="lg"
                        cursor={"pointer"}
                        fontWeight="700"
                        fontFamily="Poppins"
                        fontSize="20px"
                        fontStyle="normal"
                        color="#245E53"
                      >
                        Explore Dates
                      </Heading>
                    </Link>
                  </Box>
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb="3"
              py="3"
              borderRadius="3px"
              boxShadow="8px 8px 16px 0px rgba(0, 0, 0, 0.10)"
            >
              <Text
                mt="1"
                fontWeight="600"
                fontFamily="Poppins"
                fontSize="16px"
                fontStyle="normal"
              >
                PLAN YOUR GETAWAY
              </Text>
              <Text
                mt="1"
                fontWeight="500"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                px="6"
              >
                Stop creating business plans, create memories instead
              </Text>
              <Box textAlign={"center"} m={"auto"} mt="10px">
                <Link href={`/calander/5cb36ed6-3e02-4a24-8d7e-98085276ea4c`}>
                  <Heading
                    size="lg"
                    cursor={"pointer"}
                    fontWeight="700"
                    fontFamily="Poppins"
                    fontSize="20px"
                    fontStyle="normal"
                    color="#245E53"
                  >
                    Add Booking
                  </Heading>
                </Link>
              </Box>
            </Box> */}
        <Divider mb="3" />
      </div>

      <Box mb="3" px="4">
        <Text
          fontWeight="500"
          fontFamily="Poppins"
          fontSize="22px"
          fontStyle="normal"
        >
          ALYF Experiences
        </Text>
        <Text
          fontWeight="500"
          fontFamily="Poppins"
          fontSize="12px"
          fontStyle="normal"
          color="#3C3C3C"
          mt="2"
        >
          Welcome to a world where the mundane is left behind, and you create
          unforgettable memories
        </Text>
      </Box>
      <Box px="4" py="2" display="flex" flexDirection="column" gap="8">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Pickup />
            <Text
              fontWeight="500"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              ml="2"
            >
              {" "}
              Request Pickup & Drop
            </Text>
          </Flex>
          <Button
            onClick={() => handlesubmit("Request pick up or drop ")}
            bg="#00272c"
            color="#E2B599"
            fontSize="14px"
            px="6"
            colorScheme="none"
          >
            Enquire
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Barbecue />
            <Text
              fontWeight="500"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              ml="2"
            >
              {" "}
              Barbeque party
            </Text>
          </Flex>
          <Button
            onClick={() => handlesubmit("Barbecue ")}
            bg="#00272c"
            color="#E2B599"
            fontSize="14px"
            px="6"
            colorScheme="none"
          >
            Enquire
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Rent />
            <Text
              fontWeight="500"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              ml="2"
            >
              {" "}
              Adventure activities
            </Text>
          </Flex>
          <Button
            onClick={() => handlesubmit("Rent a bike or car ")}
            bg="#00272c"
            color="#E2B599"
            fontSize="14px"
            px="6"
            colorScheme="none"
          >
            Enquire
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Live />
            <Text
              fontWeight="500"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              ml="2"
            >
              Local restaurant recommendations
            </Text>
          </Flex>
          <Button
            onClick={() => handlesubmit("Live musician (only Villas)")}
            bg="#00272c"
            color="#E2B599"
            fontSize="14px"
            px="6"
            colorScheme="none"
          >
            Enquire
          </Button>
        </Box>
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalContent
          py="5"
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
          w="95%"
          h="40%"
          position="absolute"
          top="15"
        >
          <ModalCloseButton />
          <Check m="auto" mt="20" />
          <Heading m="auto" mt="2" fontSize="20px">
            SIT BACK AND RELAX!
          </Heading>
          <ModalBody m="auto" fontSize="12px" w="90%" textAlign="center">
            Your ticket has been raised. Your problem will be solved in a while.
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Mystay;
