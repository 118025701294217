import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import supabase from "../configs/Supabase";
import Loading from "../components/loading/Loading";
import {
  propertyFetcherObjectReturnType,
  propertyObjectType,
} from "../types/dataFetcherTypes/DataFetcherTypes";

// Define the context value type
interface PropertyContextType {
  isLoading: boolean;
  propertyData: propertyFetcherObjectReturnType | null;
  setPropertyData: (data: propertyFetcherObjectReturnType) => void;
}

// Create context with default undefined value
const PropertyContext = createContext<PropertyContextType | undefined>(
  undefined
);

// Custom hook to use the PropertyContext
export function useProperty() {
  const context = useContext(PropertyContext);
  if (context === undefined) {
    throw Error("PropertyContext must be used inside of a PropertyProvider");
  }
  return context;
}

// PropertyProvider component
export const PropertyProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reloadCount, setReloadCount] = useState(0);
  const [propertyData, setPropertyData] =
    useState<propertyFetcherObjectReturnType | null>(null);

  useEffect(() => {
    const fetchDefaultPropertyData = async () => {
      try {
        const { data, error } = await supabase
          .from("ownership")
          .select(
            `
                        shares_owned,
                        ownership_percentage,
                        investment_amount,
                        q1_mg_amount,
                        is_resale,
                        start_date,
                        property_id (
                            property_id,
                            project_name,
                            description,
                            property_images,
                            property_category,
                            property_type,
                            flat_number,
                            location_id (
                                state,
                                country
                            )
                        )
                    `
          )
          .limit(1)
          .single();
        if (error) {
          throw error;
        }

        setPropertyData({
          isQueryError: false,
          queryData: data as any as propertyObjectType[],
          queryError: null,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching default property data:", error);
        setPropertyData({
          isQueryError: true,
          queryData: null,
          queryError: error,
        });
        setIsLoading(false);
      }
    };

    fetchDefaultPropertyData();
  }, []);

  // Define the context value
  const value = {
    isLoading,
    propertyData,
    setPropertyData,
  };

  return (
    <PropertyContext.Provider value={value}>
      {isLoading ? <Loading /> : children}
    </PropertyContext.Provider>
  );
};
