export default function deleteAllCookies() {
  console.log("mmllmlmll ml l k l l");

  var cookies = document.cookie; // Get all cookies as a single string
  var allCookies = cookies.split(";"); // Split into individual cookies
  localStorage.removeItem("sb-rkuwgpjctkcphphvextv-auth-token");
  window.location.reload();
  for (var i = 0; i < allCookies.length; i++) {
    var cookie = allCookies[i];
    var eqPos = cookie.indexOf("="); // Find the first '='
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie; // Extract the cookie name
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"; // Set cookie to expire in the past
  }
}
