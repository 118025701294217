import { Box, useColorModeValue, Flex, Stack, Heading, Text, BoxProps } from '@chakra-ui/react'
import React from 'react'
import { Notification } from '../notification/Notification'
import { NotificationButton } from '../notificationButton/NotificationButton'

interface NotificationWrapperProps extends BoxProps {
    heading?: string
    text?: string
    primaryActionOnClick?: () => boolean
    secondaryActionOnClick?: () => boolean
    primaryActionText?: string
    secondaryActionText?: string
    notificationType?: 'ERROR' | 'INFO' | 'SUCCESS' | 'WARNING'
}

export default function NotificationWrapper( props:NotificationWrapperProps ) {
    return (
        <Box
            as="section"
            mx={{ base: '0', md: '8' }}
            bg={useColorModeValue('gray.50', 'inherit')}
        >
            <Flex direction="row-reverse">
                <Notification
                    primaryAction={<NotificationButton onClick={props.primaryActionOnClick} colorScheme="blue">{props.primaryActionText}</NotificationButton>}
                    secondaryAction={<NotificationButton onClick={props.secondaryActionOnClick}>{props.secondaryActionText}</NotificationButton>}
                    notificationType={props.notificationType}
                >
                    <Stack spacing="1">
                        <Heading as="h3" fontSize="md">
                            { props.heading }
                        </Heading>
                        <Text fontSize="sm">
                            { props.text }
                        </Text>
                    </Stack>
                </Notification>
            </Flex>
        </Box>
    )
}
