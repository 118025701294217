import React, { createContext, FC, useContext, useEffect } from "react";
import { useState } from "@hookstate/core";
import supabase from "../configs/Supabase";
import { Broadcasted } from "@hookstate/broadcasted";
import Loading from "../components/loading/Loading";
import { AuthChangeEvent, Session, User } from "@supabase/supabase-js";
import { redirectURLHolderOnAuth } from "../stores/AuthStore";
import deleteAllCookies from "../configs/removeCookies";
// import { Persistence } from '@hookstate/persistence'

export interface CustomError extends Error {
  status?: number;
  statusCode?: number | string;
  error?: string;
}

export interface AuthReturnType {
  user: User | null;
  session: Session | null;
  error?: CustomError | null;
  data: User | Session | null;
}

export interface AuthContextType {
  user: User | null;
  userStatus: AuthChangeEvent | "UNKNOWN";
  userStatusPersisted: AuthChangeEvent | "UNKNOWN";
  signupByEmail: (arg0: SignupByEmailInputType) => Promise<AuthReturnType>;
  signinByEmail: (arg0: SigninByEmailInputType) => Promise<AuthReturnType>;
  signInByGmail: () => Promise<AuthReturnType>;
  signInByFacebook: () => Promise<AuthReturnType>;
  resetPassword: (
    arg0: ResetPasswordInputType
  ) => Promise<{ data: {} | null; error: CustomError | null }>;
  sendMagicLink: (
    arg0: MagicLinkInputType
  ) => Promise<{ data: {} | null; error: CustomError | null }>;
  saveNewPassword: (arg0: SaveNewPasswordInputType) => Promise<{
    user: User | null;
    data: User | null;
    error: CustomError | null;
  }>;
  signOut: () => Promise<{
    error: Error | null;
  }>;
}

export interface SignupByEmailInputType {
  email: string;
  password: string;
}

export interface SigninByEmailInputType {
  email: string;
  password: string;
}
export interface SigninByOtpInputType {
  mobile: string;
}

export interface MagicLinkInputType {
  email: string;
}

export interface ResetPasswordInputType {
  email: string;
}

export interface SaveNewPasswordInputType {
  password: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw Error("Auth context must be used inside of a Auth provider");
  }
  return context;
}

export const AuthProvider: FC = ({ children }) => {
  const loading = useState(true);
  const user = useState<User | null>(null);
  const userStatus = useState<AuthChangeEvent | "UNKNOWN">("UNKNOWN");
  const userStatusPersisted = useState<AuthChangeEvent | "UNKNOWN">("UNKNOWN");
  userStatusPersisted.attach(Broadcasted("sync-user-data-channel-topic"));

  const redirectUrl = useState(redirectURLHolderOnAuth);

  useEffect(async () => {
    const session = await supabase.auth.getSession();
    user.set(session?.data?.session?.user ?? null);
    if (user.get()) {
      loading.set(false);
    } else {
      setTimeout(() => {
        loading.set(false);
      }, 1000);
    }
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(event);
        user.set(session?.user ?? null);
        loading.set(false);
        userStatus.set(event);
      }
    );
    return () => {
      listener.subscription.unsubscribe();
    };
  }, []);

  const signupByEmail = (input: SignupByEmailInputType) => {
    return supabase.auth.signUp(input);
  };

  const signinByEmail = (input: SigninByEmailInputType) => {
    return supabase.auth.signInWithPassword(input);
  };

  const signInByGmail = () => {
    return supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectUrl.get()
          ? redirectUrl.get()
          : window.location.origin,
      },
    });
  };

  const signInByFacebook = () => {
    return supabase.auth.signInWithOAuth({
      provider: "facebook",
      options: {
        redirectTo: redirectUrl.get()
          ? redirectUrl.get()
          : window.location.origin,
      },
    });
  };

  const resetPassword = (input: ResetPasswordInputType) => {
    return supabase.auth.resetPasswordForEmail(input.email, {
      redirectTo: window.location.origin + "/auth-action-handler/",
    });
  };

  const sendMagicLink = (input: MagicLinkInputType) => {
    return supabase.auth.signInWithOtp({
      email: `${input}`,
      options: {
        emailRedirectTo: redirectUrl.get()
          ? redirectUrl.get()
          : window.location.origin,
      },
    });
  };

  const saveNewPassword = (input: SaveNewPasswordInputType) => {
    return supabase.auth.updateUser({ password: input.password });
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();

    if (error?.status === 403) {
      deleteAllCookies();
    }
  };

  const value = {
    user: user.get(),
    userStatus: userStatus.get(),
    userStatusPersisted: userStatusPersisted.get(),
    signupByEmail,
    signinByEmail,
    signInByGmail,
    signInByFacebook,
    resetPassword,
    sendMagicLink,
    saveNewPassword,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading.get() ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
