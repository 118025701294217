import {
    Box,
    Heading,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { Card } from '../../components/card/Card'
import { Logo } from '../../components/logo/Logo'
import { displaySuccess } from '../../helpers/CommonFunctions'
import { DEFAULT_NOTIFICATION_DISPLAY_TIME } from '../../configs/GlobalConstants'
import { useHistory } from 'react-router-dom'
import { useState } from '@hookstate/core'
import { redirectURLHolderOnAuth } from '../../stores/AuthStore'
import { ChangePassword } from '../../components/changePassword/ChangePassword'

export type PasswordResetFormInputs = {
	password: string;
    confirmPassword: string;
}

export default function AuthActionHandler() {

    const redirectUrl = useState(redirectURLHolderOnAuth)
    const history = useHistory()

    const onSubmitSuccess = () => {
            displaySuccess('Password changed', `We have changed your password and log you in, if not redirected in ${DEFAULT_NOTIFICATION_DISPLAY_TIME/1000} seconds please goto home page`)
            setTimeout(() => {
                history.push( ( redirectUrl.get() )?redirectUrl.get().replace(window.location.origin, ''):window.location.origin )
            }, DEFAULT_NOTIFICATION_DISPLAY_TIME);
    }

    return (
        <>
        <Box
            bg={useColorModeValue('gray.50', 'inherit')}
            minH="100vh"
            py="12"
            px={{ base: '4', lg: '8' }}
        >
        <Box maxW="md" mx="auto">
          <Logo mx="auto" h="8" justifyContent="center" mb={{ base: '10', md: '20' }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Just one more step
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Enter your new password below</Text>
          </Text>
          <Card>
          <ChangePassword
            actionOnSubmitSuccess={onSubmitSuccess}
          />
          </Card>
        </Box>
      </Box>
      </>
    )
}
