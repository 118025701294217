import { useState } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  HStack,
  PinInput,
  PinInputField,
  FormControl,
  FormLabel,
  Center,
  Flex,
  InputGroup,
  InputLeftAddon,
  Link,
  Divider,
  useToast,
  toast,
} from "@chakra-ui/react";
import React from "react";
import { Logo } from "../../components/logo/Logo";
import supabase from "../../configs/Supabase";
import { useHistory } from "react-router-dom";
import ResendOTP from "../../components/resendOtp/ResendOtp";

const OtpLogin = () => {
  return (
    <Flex direction={{ base: "column" }}>
      <Box>
        <Logo
          iconColor="green"
          w="auto"
          h="12"
          color="white"
          mt="16"
          justifyContent="center"
        />
      </Box>

      <MobileOtp isPhoneChnage={false} />
    </Flex>
  );
};

export default OtpLogin;

export const MobileOtp = ({
  isPhoneChange = false,
}: {
  isPhoneChange: boolean;
}) => {
  const [mobile, setMobile] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const toast = useToast();

  const handleSendOtp = async () => {
    if (isPhoneChange) {
      setOtpSent(true);
      attechnumberTOExistingUser(mobile);
      return;
    } else {
      const { error } = await supabase.auth.signInWithOtp({
        phone: `+91${mobile}`,
      });

      if (error) {
        toast({
          title: `${error.message}`,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        setOtpSent(true);
        console.log("OTP sent to", mobile);
      }
    }
  };

  const handleVerifyOtp = async () => {
    const { data, error } = await supabase.auth.verifyOtp({
      phone: `+91${mobile}`,
      token: otp,
      type: isPhoneChange ? "phone_change" : "sms",
    });

    if (error) {
      console.error("Error verifying OTP:", error.message);
    } else {
      return history.push("/dashboard");
    }
  };
  return (
    <>
      <Center h="100vh" mt="-20">
        {!otpSent ? (
          <Flex
            direction={{ base: "column" }}
            fontFamily="poppins"
            px="4"
            textAlign="center"
            gap="4"
          >
            <Text fontWeight="500" fontSize="20px">
              Enter your mobile number
            </Text>
            <Text px="10">We will send you the 6-digit verification code</Text>
            <InputGroup mt="2">
              <InputLeftAddon>+91</InputLeftAddon>
              <Input
                onChange={e => setMobile(e.target.value)}
                type="tel"
                placeholder="phone number"
              />
            </InputGroup>
            <Button
              mt="2"
              w="full"
              type="submit"
              bg="#00272c"
              color="#FFFFFF"
              size="lg"
              fontSize="md"
              fontWeight="semibold"
              onClick={handleSendOtp}
              isDisabled={!mobile || mobile.length !== 10}
            >
              Generate OTP
            </Button>
            <Flex
              direction={{ base: "row" }}
              justifyContent="center"
              alignItems="center"
              gap="3"
            >
              <Divider />
              <Text fontSize="sm" fontFamily="poppins">
                Or
              </Text>
              <Divider />
              <hr />
            </Flex>
            <Link href="/signin">
              <Button
                w="full"
                type="submit"
                variant="outline"
                outline="1px solid #00272c"
                color="#00272c"
                size="lg"
                fontSize="md"
                fontWeight="semibold"
              >
                Log In With Email
              </Button>
            </Link>
          </Flex>
        ) : (
          <Flex
            direction={{ base: "column" }}
            fontFamily="poppins"
            px="4"
            textAlign="center"
            gap="4"
          >
            <Text fontWeight="500" fontSize={"20px"}>
              Enter the 6-digit code
            </Text>
            <Text px="8">We have sent a 6-digit verification code to</Text>
            <Flex m="auto" gap="2" alignItems="center" mt="-4">
              <Text>{mobile} </Text>
              <Link
                onClick={() => setOtpSent(false)}
                variant="ghost"
                p="0"
                color="#045ad4"
              >
                Edit
              </Link>
            </Flex>
            <Flex direction="column" gap="4" mt="2">
              <HStack justify="center">
                <PinInput otp value={otp} onChange={setOtp}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <ResendOTP
                resend={() => resendOtp(mobile)}
                timeInSeconds={30}
                retryCount={5}
              />
              {/* <Link
                fontSize="sm"
                onClick={() => {
                  resendOtp(mobile);
                }}
                variant="ghost"
                pr="4"
                color="#045ad4"
              >
                Resend Otp
              </Link> */}
            </Flex>

            <Button
              mt="2"
              w="full"
              type="submit"
              bg="#00272c"
              color="#FFFFFF"
              size="lg"
              fontSize="md"
              fontWeight="semibold"
              onClick={handleVerifyOtp}
              isDisabled={otp.length !== 6}
            >
              Verify OTP
            </Button>
          </Flex>
        )}
      </Center>
    </>
  );
};

async function attechnumberTOExistingUser(mobile: string) {
  const { data, error } = await supabase.auth.updateUser({
    phone: `+91${mobile}`,
  });
  console.log("updateuserdata", data, error);
}

// async function resendOtp(mobile: string) {
//   const { data, error } = await supabase.auth.resend({
//     type: "sms",
//     phone: mobile,
//   });
//   console.log(data, error);
// }

async function resendOtp(mobile: string) {
  const { data, error } = await supabase.auth.signInWithOtp({
    phone: `+91${mobile}`,
  });
  console.log(mobile);
  console.log(data, error);
}
