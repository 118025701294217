import {
  Box,
  Heading,
  Input,
  Text,
  FormControl,
  Stack,
  InputGroup,
  InputLeftAddon,
  useColorModeValue,
  Button,
  Flex,
  FormLabel,
  FormErrorMessage,
  Icon,
} from "@chakra-ui/react";
import * as React from "react";
import { Card } from "../../components/card/Card";
import { Logo } from "../../components/logo/Logo";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaUser } from "react-icons/fa";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useAuth } from "../../contexts/Auth";
import { displayError, displaySuccess } from "../../helpers/CommonFunctions";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export type ForgotPasswordFormInputs = {
  email: string;
};

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { resetPassword } = useAuth();

  const handleSendEmailButtonClick = async (
    values: ForgotPasswordFormInputs
  ) => {
    const { error } = await resetPassword(values);
    console.log(error);

    if (error) {
      displayError("", "", true, {
        type: "USER",
        subType: "NOT_FOUND",
        code: error.status,
        error: error,
      });
    } else {
      displaySuccess("", "", true, {
        type: "USER",
        subType: "LINK_SEND",
      });
    }
  };

  return (
    <>
      <Flex justifyContent="flex-end" mb={-14}>
        <ColorModeSwitcher m={2} />
      </Flex>
      <Box
        bg={useColorModeValue("gray.50", "inherit")}
        minH="100vh"
        py="12"
        px={{ base: "4", lg: "8" }}
      >
        <Box maxW="md" mx="auto">
          <Logo
            mx="auto"
            h="8"
            justifyContent="center"
            mb={{ base: "10", md: "20" }}
          />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Forgot your password?
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Don&apos;t worry, We got you covered</Text>
          </Text>
          <Card>
            <form onSubmit={handleSubmit(handleSendEmailButtonClick)}>
              <Stack spacing="12">
                <FormControl
                  id="email"
                  isInvalid={!!errors?.email}
                  errortext={errors?.email?.message}
                  isRequired
                >
                  <Flex align="baseline" justify="space-between">
                    <FormLabel mb={1}>Email</FormLabel>
                  </Flex>
                  <InputGroup>
                    <InputLeftAddon children={<Icon as={FaUser} />} />
                    <Input
                      type="email"
                      autoComplete="email"
                      {...register("email")}
                      placeholder="someone@email.com"
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                </FormControl>
                <Button
                  type="submit"
                  bg="#00272c"
                  color="#FFFFFF"
                  size="lg"
                  fontSize="md"
                  isLoading={isSubmitting}
                  loadingText="Sending..."
                  isDisabled={Object.keys(errors).length !== 0}
                >
                  Send Reset Link
                </Button>
              </Stack>
            </form>
          </Card>
        </Box>
      </Box>
    </>
  );
}
