import React from "react";
import { StatCard } from "../../components/statCard/StatCard";
import {
  Box,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { StatCardProps } from "../../components/statCard/StatCard";
import { getHomeData } from "../../dataFetchers/homeDataFetcher";
import { useQuery } from "react-query";
import {GoLocation} from "react-icons/go"
import Percentage from '../../assets/images/modern-house-3.png'
export const Home = () => {
  const { data } = useQuery("userHome", getHomeData);

  // data will be null when fetch call fails
  if (data === undefined) return <span>Data not available</span>;

  let bookingHistory:string|Array<Object>|null = localStorage.getItem('booking_history');
  if (bookingHistory === null) {
    bookingHistory = [
      {
        from: "Sat Sep 16 2023",
        to: "Sun Sep 17 2023",
      }
    ]
  } else {
    bookingHistory = JSON.parse(bookingHistory)
  }

  return (
    <Box>

      <Box  py="5" px="1" boxShadow={"rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"} borderRadius="5px">
      <Image src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/273221167.jpg?k=9e9b4cab4608fff75b22be41448e6b81e9a95916462816f7c0c69a9767ec22ca&o=&hp=1" w={["100%","100%","60%","60%"]} h={["100%","100%","400px","400px"]} borderRadius={"15px"} />
      <Box display="flex" justifyContent="space-between" alignContent="center" w="94%" mt="1" ml="2">

      <Box mt="15px" fontSize={"20px"} >
      <Text
        fontWeight="semibold"
      >
        Villa Samaya
      </Text>
      </Box>
       <Box display={"flex"}m="5px"  mt="15px" alignItems={"center"}  color={mode('gray.900', 'gray.300')} >
       <GoLocation/>
       <Text ml="15px">Anjuna, Goa, India</Text>
      </Box>
      </Box>
      </Box>
      <Heading as="h6" fontWeight="semibold" fontSize="20px" mt="12">My Bookings</Heading>
      
      {
        bookingHistory ? 
        <SimpleGrid mt="10px" columns={{ base: 1, md: 2 }} spacing="6">
        {bookingHistory.map((stat, idx) => (
          <StatCard key={idx} data={stat} />
        ))}
      </SimpleGrid> : 
      <Box textAlign="center" display="flex"  flexDirection="column"  alignItems="center" >
        <Image src={Percentage} w="60px" h="70px" my="4" />
        <Heading as="h6" fontSize="20px">You’ve No Bookings</Heading>
      </Box>
      }

      <Box  textAlign={"center"} m={"auto"} mt="15px">
          <Text>Want to Visit your Home</Text>
          <Link href="/calander">
            <Heading as={"h4"}size="lg" cursor={"pointer"}  color={"#185D53"} >Add Booking</Heading>
          </Link>
      </Box>
    </Box>
  );
};
