import {
  Box,
  Flex,
  Text,
  useColorModeValue as mode,
  Link,
  Center,
  Button,
  Divider,
} from "@chakra-ui/react";
import * as React from "react";
import { Logo } from "../../components/logo/Logo";
import { SigninForm } from "../../components/signinForm/SigninForm";
import { useLocation } from "react-router-dom";
import { redirectURLHolderOnAuth } from "../../stores/AuthStore";
import { useState } from "@hookstate/core";
import { useEffect } from "react";

export const Signin: React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = useState(redirectURLHolderOnAuth);
  const redirectToPath = searchParams.get("redirectToPath");

  useEffect(() => {
    redirectUrl.set(p => (redirectToPath ? redirectToPath : p));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flex minH="100vh" direction={{ base: "column", md: "row" }}>
        <Box w="100%">
          <Box>
            <Logo
              iconColor="green"
              w="auto"
              h="12"
              color="white"
              mt="16"
              justifyContent="center"
            />
          </Box>
          <Center h="90vh" mt="-10">
            <Box p="2">
              <Text
                maxW="md"
                mx="auto"
                fontWeight="semibold"
                fontSize={{ base: "2xl", lg: "2xl" }}
                letterSpacing="tight"
                lineHeight="normal"
                textAlign="center"
                mb="8"
              >
                Log in to your account
              </Text>
              <SigninForm />
              <Flex
                direction={{ base: "row" }}
                justifyContent="center"
                alignItems="center"
                gap="3"
              >
                <Divider />
                <Text fontSize="sm" fontFamily="poppins">
                  Or
                </Text>
                <Divider />
                <hr />
              </Flex>
              <Link href="/signinOtp">
                <Button
                  w="full"
                  type="submit"
                  variant="outline"
                  outline="1px solid #00272c"
                  color="#00272c"
                  size="lg"
                  fontSize="md"
                  fontWeight="semibold"
                  mt="4"
                >
                  Log In With Phone
                </Button>
              </Link>
            </Box>
          </Center>
        </Box>
      </Flex>
    </>
  );
};
