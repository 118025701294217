import {
  Box,
  Flex,
  Stack,
  Text,
  Center,
  useColorModeValue as mode,
  Divider,
  Link,
  useToast,
  useDisclosure,
  Modal,
  ModalContent,
  ModalCloseButton,
  Heading,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import * as React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { propertyObjectType } from "../../types/dataFetcherTypes/DataFetcherTypes";
import { useAuth } from "../../contexts/Auth";
import { Check } from "../Icon/fin";

export interface StatCardProps {
  bookingdata: {
    from: string;
    to: string;
    id: string;
    isInvalid: () => void;
    isCompleted: boolean;
  };
}

export function StatCard(props: StatCardProps) {
  const { from, to, id, isInvalid, isCompleted } = props.bookingdata;

  const { user } = useAuth();

  const [isLoading, setLoading] = React.useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlecancelBooking = async () => {
    const apiUrl =
      "https://asia-south1-sharenest-local.cloudfunctions.net/bookingCancellationHandler/api/cancel-booking";

    const postData = {
      booking_id: id,
      user_id: user?.id,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbjEyMyIsInJvbGUiOiJhZG1pbiIsInRpbWVzdGFtcCI6MTY5MjYzMDc1NzgzMCwiY29udGV4dCI6eyJhY3Rpb24iOiJzdWJtaXRfYm9va2luZyIsImVuZHBvaW50IjoiL2FwaS9jcmVhdGUtYm9va2luZyIsInRhYmxlc19hZmZlY3RlZCI6WyJib29raW5nIiwicHJvcGVydHlfYm9va2luZyJdfSwiaWF0IjoxNjkyNjMwNzU3fQ.YEOvbbDpPK6vuOZ4dQJ3u9n04_yq6xNzf9rwYDRlhnk`, // Replace with your actual Bearer token
    };
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(postData),
    };
    try {
      setLoading(true);
      const res = await fetch(apiUrl, requestOptions);
      const data = await res.json();
      if (res.status == 200) {
        toast({
          title: data.message,
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          title: data.message,
          status: "error",
          isClosable: true,
        });
      }
      setLoading(false);
      isInvalid();
      onClose();
    } catch (e) {
      console.error("Error:", e);
      setLoading(false);
    }
  };

  return (
    <>
      <Flex
        direction="column"
        align="center"
        p="3"
        rounded="8px"
        border={"1px solid #D0E6DE"}
        color={mode("gray.600", "gray.100")}
        textAlign="center"
        alignItems="center"
        mt="5"
      >
        <Box display={"flex"} justifyContent="space-between" w={"95%"}>
          <Text fontFamily="poppins">From</Text>
          <Text fontFamily="poppins">To</Text>
        </Box>
        <Box
          display="flex"
          fontWeight="bold"
          justifyContent="space-between"
          alignItems="center"
          w={"96%"}
          mt="5px"
        >
          <Text fontFamily="poppins" fontWeight={"500"} fontSize="14px">
            {from}
          </Text>
          <FiArrowRightCircle
            size={"25px"}
            color="#D0E6DE"
            style={{ marginTop: "-12px" }}
          />
          <Text fontFamily="poppins" fontWeight={"500"} fontSize="14px">
            {to}
          </Text>
        </Box>

        {isCompleted ? (
          <Box w="100%">
            <Divider
              orientation="horizontal"
              variant={"dashed"}
              mt="10px"
              w={"96%"}
            />

            <Text
              mt="10px"
              fontSize={"14px"}
              fontWeight="semibold"
              fontFamily="poppins"
              color="green"
              textAlign="end"
              w="100%"
              pr="2"
            >
              Completed
            </Text>
          </Box>
        ) : (
          <Box w="100%">
            <Divider
              orientation="horizontal"
              variant={"dashed"}
              mt="10px"
              w={"96%"}
            />

            <Text
              mt="10px"
              fontSize={"14px"}
              fontWeight="semibold"
              fontFamily="poppins"
              cursor="pointer"
              color="#cf142b"
              onClick={() => onOpen()}
              textAlign="end"
              w="full"
              pr="2"
            >
              Cancel Booking
            </Text>
          </Box>
        )}
      </Flex>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalContent
          py="5"
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
          w="95%"
          h="40%"
          position="absolute"
          top="15"
        >
          <ModalCloseButton />
          <Check m="auto" mt="20" />
          <Heading m="auto" mt="2" fontSize="20px">
            Are You Sure You want to cancel
          </Heading>
          <ModalBody m="auto" fontSize="12px" w="90%" textAlign="center">
            <Box
              display="flex"
              gap="1"
              alignItems="center"
              justifyContent="center"
              mt="3"
            >
              <Button
                bg="#00272c"
                color="#E2B599"
                mt="5"
                mb="4"
                px="14"
                fontSize="14px"
                fontWeight="500"
                fontFamily="Montserrat"
                colorScheme="none"
                fontStyle="normal"
                isLoading={isLoading}
                onClick={() => handlecancelBooking()}
              >
                Confirm
              </Button>
              <Button
                bg="#FFF"
                color="#C1C1C1"
                mt="5"
                px="14"
                mb="4"
                ml="5"
                fontSize="14px"
                fontWeight="500"
                fontFamily="Montserrat"
                shadow="md"
                fontStyle="normal"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
