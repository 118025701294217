import moment from "moment"
import supabase from "../configs/Supabase"
import { displayError } from "../helpers/CommonFunctions"
import { certificateNumberFetcherObjectReturnType } from "../types/dataFetcherTypes/DataFetcherTypes"

export const getHomeData = async (): Promise<certificateNumberFetcherObjectReturnType> => {
    try {
        let isError = false

        let returnData: null | Array<number> = [0, 0]
        const { data, error } = await supabase
            .from('certificates')
            .select(`
                    recipient_name,
                    `)

        if (error) {
            isError = true
        }
        //console.log('data in homedatafetcher=', data);
        if (data?.length == undefined) {
            returnData[0] = 0
        } else {
            returnData[0] = data?.length
        }

        returnData[1] = 350
        return {
            isQueryError: isError,
            queryData: returnData,
            queryError: error
        }
    } catch (error) {
        throw error;
    }
}

