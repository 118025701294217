import { ChevronRightIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Progress,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { GoLocation } from "react-icons/go";
import {
  Bathtub,
  Bed,
  Calender,
  CalenderShort,
  Check,
  Mountain,
  Pool,
} from "../Icon/fin";
import {
  BookingDetails,
  bookingObjectType,
  expensesObjectType,
  profileFetcherObjectReturnType,
  propertyObjectType,
} from "../../types/dataFetcherTypes/DataFetcherTypes";
import { ShowMore } from "../showMore/ShowMore";
import Plan from "../../assets/images/plan.png";
import { useAuth } from "../../contexts/Auth";
import moment from "moment";

export function parseDate(dateString: string) {
  const parts = dateString.split("-");
  return new Date(parts[2], parts[1] - 1, parts[0]); // Year, Month (0-based), Day
}

export const HomePagePropertyCard = ({
  propertyData,
  propertyBookingArray,
  expensesData,
  isInvalid,
}: {
  propertyData: profileFetcherObjectReturnType;
  propertyBooking: BookingDetails;
  expensesData?: expensesObjectType;
  isInvalid: () => void;
}) => {
  if (!propertyData) {
    window.location.reload();
  }
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = React.useState(false);
  const { user } = useAuth();

  let nextBookingArray = propertyBookingArray;

  const handlecancelBooking = async (booking_id: string, user_id: string) => {
    const apiUrl =
      "https://asia-south1-sharenest-local.cloudfunctions.net/bookingCancellationHandler/api/cancel-booking";

    const postData = {
      booking_id: booking_id,
      user_id: user_id,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbjEyMyIsInJvbGUiOiJhZG1pbiIsInRpbWVzdGFtcCI6MTY5MjYzMDc1NzgzMCwiY29udGV4dCI6eyJhY3Rpb24iOiJzdWJtaXRfYm9va2luZyIsImVuZHBvaW50IjoiL2FwaS9jcmVhdGUtYm9va2luZyIsInRhYmxlc19hZmZlY3RlZCI6WyJib29raW5nIiwicHJvcGVydHlfYm9va2luZyJdfSwiaWF0IjoxNjkyNjMwNzU3fQ.YEOvbbDpPK6vuOZ4dQJ3u9n04_yq6xNzf9rwYDRlhnk`, // Replace with your actual Bearer token
    };
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(postData),
    };
    try {
      setLoading(true);
      const res = await fetch(apiUrl, requestOptions);
      const data = await res.json();
      if (res.status == 200) {
        toast({
          title: data.message,
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          title: data.message,
          status: "error",
          isClosable: true,
        });
      }
      setLoading(false);
      isInvalid();
      onClose();
    } catch (e) {
      console.error("Error:", e);
      setLoading(false);
    }
  };

  return (
    <Box
      mt="4"
      borderRadius="6px"
      bg="#FFF"
      boxShadow="8px 8px 16px 0px rgba(0, 0, 0, 0.05)"
      p="4"
      mb="4"
    >
      <Box>
        <Box>
          <Heading
            mb="3"
            ml="1"
            as="h5"
            fontWeight="600"
            fontSize="18px"
            fontStyle="normal"
            color="#3C3C3C"
            fontFamily="poppins"
          >
            {propertyData.property_id.project_name}
          </Heading>
          <Image
            w="full"
            h="auto"
            objectFit="cover"
            borderRadius="8px"
            src={`${propertyData.property_id.property_images[0].replace(
              "https://authapi.alyf.in",
              "https://rkuwgpjctkcphphvextv.supabase.co"
            )}`}
            alt=""
          />
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          {/* <Box display={"flex"} ml="1" mt="15px" alignItems={"center"}>
            <GoLocation />
            <Text
              fontSize="14px"
           fontFamily="Poppins"
              fontWeight="600"
              ml="10px"
            >
              {propertyData.property_id.location_id.state},{" "}
              {propertyData.property_id.location_id.country}
            </Text>
          </Box> */}
        </Box>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="3"
          bg="#F9F9F9"
          mt="4"
          borderRadius="5px"
        >
          <Box display="flex" gap="5px" alignItems="center" mt="1" mb="-3">
            <Bathtub fontSize="70px"  />
            <Text color="#00272C" fontSize="12px" mt="-3">
              2 Baths
            </Text>
          </Box>
          <Box display="flex" gap="5px" alignItems="center" mt="1" mb="-3">
            <Bed fontSize="70px" />
            <Text color="#00272C" fontSize="12px" mt="-3">
              2 Beds
            </Text>
          </Box>
          <Box display="flex" gap="5px" alignItems="center" mt="1" mb="-3">
            <Pool fontSize="70px" />
            <Text color="#00272C" fontSize="12px" mt="-3">
              1 Pool
            </Text>
          </Box>
        </Box> */}
      </Box>
      <Box
        border="0px solid rgba(60, 60, 60, 0.50)"
        borderRadius="5px"
        px="2"
        py="2"
        mt="5"
        bg="#F8F8F8"
      >
        <Box display="flex" justifyContent="space-between" mt="2">
          <Text
            fontSize="12px"
            fontWeight="500"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            Your Investment{" "}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="600"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            {propertyData.investment_amount > 0
              ? `Rs. ${propertyData.investment_amount.toLocaleString("en-IN")}`
              : "N/A"}
          </Text>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2">
          <Text
            fontSize="12px"
            fontWeight="500"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            Your Earnings{" "}
          </Text>
          <Tooltip
            label={
              propertyData.is_resale === false
                ? `Includes ${expensesData?.quarter} ${
                    expensesData?.year
                  }  Earnings of Rs. ${
                    expensesData && expensesData?.amount_payable_per_share_gross
                  }`
                : `Not Applicable for quarter ended September 2023`
            }
          >
            <Text
              fontSize="12px"
              fontWeight="600"
              fontFamily="Poppins"
              fontStyle="normal"
            >
              {
                // propertyData.q1_mg_amount > 0 ||
                expensesData &&
                (expensesData?.amount_payable_per_share_gross as number) > 0 &&
                propertyData.is_resale === false
                  ? `Rs. ${
                      // propertyData.q1_mg_amount +
                      (expensesData?.amount_payable_per_share_gross
                        ? (expensesData?.amount_payable_per_share_gross as number)
                        : 0
                      ).toLocaleString("en-IN")
                    }`
                  : "N/A"
              }
              <InfoIcon ml={2} />
            </Text>
          </Tooltip>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2">
          <Text
            fontSize="12px"
            fontWeight="500"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            Ownership Percentage{" "}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="600"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            {propertyData.ownership_percentage > 0
              ? `${propertyData.ownership_percentage.toLocaleString("en-IN")}%`
              : "N/A"}
          </Text>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2">
          <Text
            fontSize="12px"
            fontWeight="500"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            {propertyData.property_id.property_category === "flat"
              ? "Apartment No "
              : "Villa No. "}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="600"
            fontFamily="Poppins"
            fontStyle="normal"
          >
            {propertyData.property_id.flat_number}
          </Text>
        </Box>
      </Box>
      {/* <Box mt="5" ml="1">
        <Text
          mt="2"
          fontWeight="500"
          fontSize="sm"
       fontFamily="Poppins"
          fontStyle="normal"
          color="#000"
        >
          Your 2 days are completed of 6 days
        </Text>
        <Progress
          w="100%"
          h="2"
          m="auto"
          mt="3"
          borderRadius="20px"
          value={33}
          colorScheme="teal"
          bg="#E8E8E8"
        />
      </Box> */}

      {nextBookingArray !== undefined && nextBookingArray.length > 0 ? (
        <>
          <Heading
            mt="5"
            ml="1"
            as="h5"
            fontWeight="600"
            fontSize="18px"
            fontStyle="normal"
            fontFamily="poppins"
          >
            Your trips!
          </Heading>
          {nextBookingArray.map((data: BookingDetails, i: React.Key) => (
            <Box
              key={i}
              border="1px solid rgba(60, 60, 60, 0.50)"
              borderRadius="5px"
              px="2"
              py="2"
              mt="4"
              bg="#F8F8F8"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px="1"
              >
                <Text
                  fontWeight="600"
                  fontFamily="poppins"
                  fontSize="14px"
                  fontStyle="normal"
                >
                  {`${moment(data.checkin_date).format(
                    "DD MMM, YYYY"
                  )}- ${moment(data.checkout_date).format("DD MMM, YYYY")}`}
                </Text>
                <ChevronRightIcon />
              </Box>
              <Divider mt="3" variant="dashed" color="teal.500" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml="1"
                mt="4"
              >
                <Box display="flex" alignItems="center">
                  <Calender />
                  <Text ml="3" fontSize="10px" fontWeight="600">
                    {Math.floor(
                      (moment(data.checkout_date) - moment(data.checkin_date)) /
                        86400000
                    )}{" "}
                    nights Stay
                  </Text>
                </Box>

                <Text
                  fontWeight="bold"
                  color="#245E53"
                  fontSize="12px"
                  onClick={() => {
                    console.log("jkjkjkjk");
                    onOpen();
                  }}
                >
                  Cancel Booking
                </Text>
              </Box>
              <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalContent
                  py="5"
                  boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
                  w="95%"
                  h="40%"
                  position="absolute"
                  top="10"
                >
                  <ModalCloseButton />
                  <Check m="auto" mt="10" />
                  <Heading m="auto" mt="2" fontSize="20px">
                    Are You Sure You want to cancel
                  </Heading>
                  <ModalBody
                    m="auto"
                    fontSize="12px"
                    w="90%"
                    textAlign="center"
                  >
                    <Box
                      display="flex"
                      gap="1"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        bg="#00272c"
                        color="#E2B599"
                        mt="5"
                        mb="4"
                        px="14"
                        fontSize="14px"
                        fontWeight="500"
                        fontFamily="Poppins"
                        colorScheme="none"
                        fontStyle="normal"
                        isLoading={isLoading}
                        onClick={() =>
                          handlecancelBooking(data.booking_id, user?.id)
                        }
                      >
                        Confirm
                      </Button>
                      <Button
                        bg="#FFF"
                        color="#C1C1C1"
                        mt="5"
                        px="14"
                        mb="4"
                        ml="5"
                        fontSize="14px"
                        fontWeight="500"
                        fontFamily="Poppins"
                        shadow="md"
                        fontStyle="normal"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          ))}
        </>
      ) : (
        <>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="5"
            gap="2"
            border={"1px"}
            rounded={"8px"}
            py="4"
          >
            <Image w="40px" src={Plan} />
            <Text
              mt="1"
              fontWeight="600"
              fontFamily="Poppins"
              fontSize="16px"
              fontStyle="normal"
            >
              Book your happy place!
            </Text>
            <Text
              mt="1"
              fontWeight="400"
              fontFamily="Poppins"
              fontSize="14px"
              px="6"
            >
              Laughter, new stories, and the beauty of new experiences await!
            </Text>
            <Box
              textAlign={"center"}
              m={"auto"}
              mt="10px"
              border={"1px"}
              p="2"
              px="6"
              rounded={"8px"}
              shadow="sm"
            >
              <Link href={`/calander/${propertyData.property_id.property_id}`}>
                <Heading
                  cursor={"pointer"}
                  fontWeight="500"
                  fontFamily="Poppins"
                  fontSize="16px"
                  fontStyle="normal"
                  color="#00272c"
                >
                  Explore Dates
                </Heading>
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
