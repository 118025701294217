import moment from "moment"

export const DEFAULT_NOTIFICATION_DISPLAY_TIME = 9000
export const todayMoment = moment()
export const DISPLAY_PROFILE_IMAGE_CHANGE_ON_HEADER = true

//Error Messages
export const USER_NOT_EXIST = 'User does not exist'
export const NO_USER_WITH_EMAIL = 'A user with the provided e-mail does not exist'
export const USER_ERROR_NOT_ALLOWED = 'Action Not allowed'
export const FORGOT_PASSWORD_NOT_ALLOWED_FOR_SOME_TIME = 'You can only send one email every minute, Try in some time'
export const USER_ERROR_INVALID_AUTH_TITLE = 'Invalid email or password'
export const USER_ERROR_INVALID_AUTH_MESSAGE = 'A user with provided email and password does not exist.'
export const DEFAULT_USER_ERROR_TITLE = 'Some error occured'
export const DEFAULT_USER_ERROR_MESSAGE = 'Sit back and relex while we fix this issue'
export const GENERIC_ERROR_TITLE = 'Looks like an error'
export const USER_NOT_LOGGED_IN_TITLE = 'Not logged in'
export const USER_NOT_LOGGED_IN_CHANGE_PASSWORD_MESSAGE = 'Sorry we can\'t change your password as you are not logged in, Please use forgot password.'

export const NOT_IMAGE_TITLE = "Not an image"
export const NOT_IMAGE_MESSAGE = "The file you selected is not an image file. Please use only images"
export const IMAGE_TOO_LARGE_TITLE = "Image too large"
export const IMAGE_TOO_LARGE_MESSAGE = "Sorry, At this moment we do not support images which are larger than 2 MB"

//Success Messages
export const USER_MAGIC_LINK_TITLE = "Confirmation mail sent"
export const USER_MAGIC_LINK_MESSAGE = "Just click on the link, We've send on your email address and Houdini will log you in magically"


export const DEMO_USER_IDS = ['b887b4f2-39b0-4d5d-8bfc-6519a53ffbd9', 'f1f2d82f-9330-49c5-a96d-a3ee65411ef0', '224cba8d-cb3b-4c92-b7da-eea635abaf76', '90f59083-5d5f-425e-b61e-0c2bb0f39499', 'c19c06c3-e646-419d-8aa1-0a4daa0bd640', 'd25b8dab-0d9a-4786-ac2d-300f00ada590',
'd4f3bd3a-42fa-461e-9a18-7aa10d24eb4b', '3b335364-9848-4825-9b5d-ca58866b13cf']