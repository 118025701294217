import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/layout";
import React from "react";
import { BiLeftArrowAlt } from "react-icons/bi";

const Support = () => {
  return (
    <>
      <Box display="flex" gap="5" alignItems="center">
        <BiLeftArrowAlt onClick={() => window.history.back()} fontSize="20px" />
        <Text
          color={"#767676"}
          fontWeight="600"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="16px"
        >
          Chat with our agent
        </Text>
      </Box>

      <Box>
        <Text
          mt="10"
          bg="#DDD"
          borderRadius="6px"
          py="2"
          px="3"
          fontWeight="500"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="12px"
          w="80%"
        >
          Hi Chetan how we can help you?
        </Text>
        <Box display="flex" alignItems="end" justifyContent="end">
          <Text
            marginLeft=""
            mt="7"
            bg="rgba(127, 171, 99, 0.20)"
            borderRadius="6px"
            py="2"
            px="3"
            fontWeight="500"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="12px"
            w="80%"
          >
            Hi wanted to ask few question regarding the changes to dates
          </Text>
        </Box>
      </Box>
      <Box  w="92%" display="flex" alignItems="center" position="fixed" bottom="24">
        <Input
          type="text"
          placeholder="Enter your question"
          fontSize="12px"
          _placeholder={{ opacity: 0.7, color: "#B8B8B8" }}
        />
        <Button
          bg="#00272c"
          color="#E2B599"
          px="5"
          fontWeight="500"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="12px"
        >
          Send
        </Button>
      </Box>
    </>
  );
};

export default Support;
