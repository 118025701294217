import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../contexts/Auth'

export default function PrivateRoute( { component: Component, ...rest }: any ) {
    const { user } = useAuth()
    // const user = true

    const redirectToPath = window.location.href

    return (
        <Route
            {...rest}
            render={props => {
                return user ? <Component {...props} /> : <Redirect to={`/signin?redirectToPath=${redirectToPath}`} />
            }}
        >
        </Route>
    )
}
