import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    BoxProps,
    useBreakpointValue,
    SlideDirection
} from "@chakra-ui/react"
import React, { cloneElement, ReactElement } from 'react'

export interface drawerComponentInputPropsType extends BoxProps {
    triggerComponent: ReactElement
    heading: string
    showFooter?: boolean
    footerButtons?: ReactElement
}

export const DrawerComponent = ( props:drawerComponentInputPropsType ) => {
    const { triggerComponent, children, heading, showFooter, footerButtons } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const slideDirection = useBreakpointValue({ base: "bottom", md: "right" }) as SlideDirection

    const triggerComponentWithInjectedProps = cloneElement( triggerComponent, { onClick: onOpen } )

    return (
        <>
            { triggerComponentWithInjectedProps }
            <Drawer
                isOpen={isOpen}
                placement={slideDirection}
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader> { heading } </DrawerHeader>

                    <DrawerBody>
                        { children }
                    </DrawerBody>

                    {
                        showFooter &&
                        <DrawerFooter>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            { footerButtons }
                        </DrawerFooter>
                    }
                </DrawerContent>
            </Drawer>
        </>
    )
}
