import React, { useState } from "react";
import Calendar from "react-calendar";
import {
  Box,
  Button,
  SimpleGrid,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import "./calender.css";
import { Navbar } from "../components/header/Header";
import Header from "../components/Navbar/Header";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useQuery } from "react-query";
import {
  getAllBookingsData,
  getBookingsData,
  getPropertyLocationData,
} from "../dataFetchers/userProfileDataFetcher";
import { useAuth } from "../contexts/Auth";
import {
  BookingData,
  BookingDetails,
  bookingObjectType,
} from "../types/dataFetcherTypes/DataFetcherTypes";
import {
  convertDDMMYYYYToMMDDYYYY,
  subtractDays,
} from "../helpers/CommonFunctions";
import { useProperty } from "../contexts/Property";
import { getStartDateOfSelectedMonth } from "./MyDashboard/Mydashboard";

const Calender = () => {
  const defaultDate = "2020-01-01";
  const { user } = useAuth();
  const history = useHistory();

  const { propertyId } = useParams<{
    propertyId: string;
  }>();

  const {
    isError: isPropertyLocationError,
    isLoading: isPropertyLocationLoading,
    data: propertyLocationData,
    error: propertyLocationError,
  } = useQuery(["propertyLocationData", propertyId], () =>
    getPropertyLocationData(propertyId)
  );

  let peakSeasonArray: {
    startDate: Date;
    endDate: Date;
  }[] = [];

  propertyLocationData?.queryData?.map(val => {
    const seasons = val.seasons || val.location_id.seasons;
    seasons.peak_seasons.map(dateObj => {
      const currentYear = new Date().getFullYear();
      peakSeasonArray.push({
        startDate: new Date(
          currentYear,
          dateObj.start_date.month - 1,
          dateObj.start_date.day
        ),
        endDate: new Date(
          currentYear,
          dateObj.end_date.month - 1,
          dateObj.end_date.day
        ),
      });
      peakSeasonArray.push({
        startDate: new Date(
          currentYear + 1,
          dateObj.start_date.month - 1,
          dateObj.start_date.day
        ),
        endDate: new Date(
          currentYear + 1,
          dateObj.end_date.month - 1,
          dateObj.end_date.day
        ),
      });
      peakSeasonArray.push({
        startDate: new Date(
          currentYear + 2,
          dateObj.start_date.month - 1,
          dateObj.start_date.day
        ),
        endDate: new Date(
          currentYear + 2,
          dateObj.end_date.month - 1,
          dateObj.end_date.day
        ),
      });
    });
  });

  const userId = user?.id;

  const { isLoading: isPropertyLoading, propertyData } = useProperty();
  let firstDay = getStartDateOfSelectedMonth();
  const [startFirstDay, setFirstDay] = useState<string | Date>(firstDay);

  const {
    data: newBookdata,
    isLoading: isbookload,
    isError,
    error,
  } = useQuery<BookingData>(
    [
      "bookingData",
      propertyData?.queryData.property_id.property_id,
      startFirstDay,
    ],
    () =>
      getAllBookingsData({
        property_id: propertyData?.queryData.property_id.property_id,
        firstday: startFirstDay,
        emailId: user?.email,
        userId: user?.id,
      })
  );

  let convertedNewBookingDetailsData: BookingDetails[] = [];

  convertedNewBookingDetailsData =
    newBookdata?.bookingDetails.map(val => {
      return {
        ...val,
        checkin_date: convertDDMMYYYYToMMDDYYYY(val.checkin_date),
        checkout_date: convertDDMMYYYYToMMDDYYYY(val.checkout_date),
      };
    }) || [];

  let propertyBookingArray: { [key: string]: any } = {};
  let bookingList: BookingDetails[] = [];
  let checkOutOnlyDates: string[] = [];

  let bookingStartDays = [];
  let bookingEndDays = [];

  // bookingsData?.queryData?.map((val) => {
  //   val.booking_id.map((propMapingVal) => {
  //     if (propMapingVal.property_id === propertyId) {
  //       if (propertyBookingArray[propMapingVal.booking_id] === undefined) {
  //         bookingList.push(val);
  //         checkOutOnlyDates.push(val.checkin_date);
  //         bookingEndDays.push(val.checkout_date);
  //         bookingStartDays.push(val.checkin_date);
  //       }
  //       propertyBookingArray[propMapingVal.booking_id] = val;
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // });

  convertedNewBookingDetailsData.map(val => {
    bookingList.push(val);
    checkOutOnlyDates.push(val.checkin_date);
    bookingEndDays.push(val.checkout_date);
    bookingStartDays.push(val.checkin_date);
  });

  // console.log(bookingList)
  const [selectionStartDate, setSelectionStartDate] = useState<string | Date>(
    defaultDate
  );
  const [selectionEndDate, setSelectionEndDate] = useState<string | Date>(
    defaultDate
  );

  let datePastTwoYears = new Date();
  datePastTwoYears.setDate(datePastTwoYears.getFullYear() + 2);

  const [maxDates, setMaxDates] = useState(datePastTwoYears);
  const [key, setkey] = useState(1);
  const toas = useToast();

  const tileDisabled = ({ date }: any) => {
    let isDisabled = false;
    // Block all peak season days and special days if the user is on ALYF Growth plan
    if (
      propertyLocationData?.queryData &&
      propertyLocationData?.queryData[0].property_type === "ALYF Growth"
    ) {
      //block peak season days
      peakSeasonArray.map(val => {
        if (date >= val.startDate && date <= val.endDate) {
          isDisabled = true;
          return true;
        }
      });
      //block special days
      let yearOfDisplayedDate = new Date(date).getFullYear();
      let specialDaysArray =
        propertyLocationData?.queryData[0].location_id.special_days_calender[
          yearOfDisplayedDate
        ];
      specialDaysArray &&
        specialDaysArray.map(val => {
          if (
            date >
              new Date(
                yearOfDisplayedDate,
                val.start_date.month - 1,
                val.start_date.day
              ) &&
            date <=
              new Date(
                yearOfDisplayedDate,
                val.end_date.month - 1,
                val.end_date.day
              )
          ) {
            isDisabled = true;
            return true;
          }
        });
    }
    // block all days those lies in middle of bookings done on the same property
    bookingList.map(val => {
      // console.log(val, date)
      if (
        date > new Date(val.checkin_date) &&
        date < subtractDays(val.checkout_date, 1)
      ) {
        isDisabled = true;
        return true;
      }
    });
    return isDisabled;
  };

  const tileContent = ({ date, view }: { date: any; view: any }) => {
    let tooltipText: string[] = [];
    if (view === "month") {
      //Add tooltip to checkout only dates
      if (
        checkOutOnlyDates.includes(moment(date).format("YYYY-MM-DD").toString())
      ) {
        tooltipText.push("Checkout only date");
      }

      let yearOfDisplayedDate = new Date(date).getFullYear();
      let specialDaysArray =
        (propertyLocationData?.queryData &&
          propertyLocationData?.queryData[0].location_id.special_days_calender[
            yearOfDisplayedDate
          ]) ||
        [];

      specialDaysArray.map(val => {
        if (
          moment(date).isBetween(
            moment({
              year: yearOfDisplayedDate,
              month: val.start_date.month - 1,
              day: val.start_date.day,
            }),
            moment({
              year: yearOfDisplayedDate,
              month: val.end_date.month - 1,
              day: val.end_date.day,
            }),
            undefined,
            "[]"
          )
        ) {
          tooltipText.push(`Special day slot for ${val.name}`);
        }
      });

      bookingList.map(val => {
        if (
          date > new Date(val.checkin_date) &&
          date < subtractDays(val.checkout_date, 1)
        ) {
          tooltipText.push(val.booking_category);
        }
      });

      if (tooltipText.length > 0) {
        return (
          <Tooltip label={tooltipText.join(" | ")}>
            <Box
              w="100%"
              h="100%"
              position="absolute"
              top="0"
              left="0"
              maxH="50px"
              maxW="50px"
            />
          </Tooltip>
        );
      }
    }
  };

  const tileClassName = ({ date, view }: { date: any; view: any }) => {
    let className = "";
    if (view === "month") {
      if (
        checkOutOnlyDates.includes(moment(date).format("YYYY-MM-DD").toString())
      ) {
        className = className.concat("check-out-only", " ");
      }

      let yearOfDisplayedDate = new Date(date).getFullYear();
      let specialDaysArray =
        (propertyLocationData?.queryData &&
          propertyLocationData?.queryData[0].location_id.special_days_calender[
            yearOfDisplayedDate
          ]) ||
        [];

      specialDaysArray.map(val => {
        if (
          moment(date).isBetween(
            moment({
              year: yearOfDisplayedDate,
              month: val.start_date.month - 1,
              day: val.start_date.day,
            }),
            moment({
              year: yearOfDisplayedDate,
              month: val.end_date.month - 1,
              day: val.end_date.day,
            }),
            undefined,
            "[]"
          )
        ) {
          className = className.concat("special-days", " ");
        }
      });

      bookingList.map(val => {
        if (
          date > new Date(val.checkin_date) &&
          date < subtractDays(val.checkout_date, 1)
        ) {
          switch (val.booking_category) {
            case "SELF_BOOKING":
              className = className.concat("self-booking", " ");
              break;

            case "COOWNER_BOOKING":
              className = className.concat("co-owner-booking", " ");
              break;

            case "RENTAL_BOOKING":
              className = className.concat("rental-booking", " ");
              break;

            default:
              break;
          }
        }
      });

      return className;
    }
  };

  const onChange = (dates: any) => {
    if (Array.isArray(dates)) {
      setSelectionStartDate(dates[0]);
      setSelectionEndDate(dates[1]);

      let yearOfDisplayedDate = new Date(dates[0]).getFullYear();
      let specialDaysArray =
        (propertyLocationData?.queryData &&
          propertyLocationData?.queryData[0].location_id.special_days_calender[
            yearOfDisplayedDate
          ]) ||
        [];

      let specialDaysStart = [];
      let specialDaysEnd = [];

      specialDaysArray.map(val => {
        specialDaysStart.push(
          moment({
            year: yearOfDisplayedDate,
            month: val.start_date.month - 1,
            day: val.start_date.day,
          })
        );

        specialDaysEnd.push(
          moment({
            year: yearOfDisplayedDate,
            month: val.end_date.month - 1,
            day: val.end_date.day,
          })
        );
      });
    }
  };

  const resetCalender = () => {
    setkey(key + 1);
  };

  const onClickDay = (clickedDate: Date) => {
    if (
      checkOutOnlyDates.includes(
        moment(clickedDate).format("YYYY-MM-DD").toString()
      )
    ) {
      toas({
        title:
          "This is a checkout only date, You can not start your booking on the same",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setSelectionStartDate(defaultDate);
      setSelectionEndDate(defaultDate);
    }
  };

  const handleActiveStartDateChange = ({ activeStartDate }: any) => {
    console.log(activeStartDate.toLocaleDateString("en-In"));
    const month = activeStartDate.toLocaleString("default", { month: "long" });
    const year = activeStartDate.getFullYear();
    setFirstDay(
      activeStartDate
        .toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .join("-")
    );
  };

  const [isLoading, setLoading] = React.useState(false);
  const toast = useToast();
  const createBooking = async () => {
    const apiUrl =
      "https://asia-south1-sharenest-local.cloudfunctions.net/submitBookingRequestHandler/api/create-booking";

    const postData = {
      user_id: user?.id,
      property_id: propertyId,
      checkin_date: moment(selectionStartDate).format("YYYY-MM-DD").toString(),
      checkout_date: moment(selectionEndDate).format("YYYY-MM-DD").toString(),
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbjEyMyIsInJvbGUiOiJhZG1pbiIsInRpbWVzdGFtcCI6MTY5MjYzMDc1NzgzMCwiY29udGV4dCI6eyJhY3Rpb24iOiJzdWJtaXRfYm9va2luZyIsImVuZHBvaW50IjoiL2FwaS9jcmVhdGUtYm9va2luZyIsInRhYmxlc19hZmZlY3RlZCI6WyJib29raW5nIiwicHJvcGVydHlfYm9va2luZyJdfSwiaWF0IjoxNjkyNjMwNzU3fQ.YEOvbbDpPK6vuOZ4dQJ3u9n04_yq6xNzf9rwYDRlhnk`, // Replace with your actual Bearer token
    };
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(postData),
    };

    try {
      setLoading(true);
      const res = await fetch(apiUrl, requestOptions);
      const data = await res.json();
      if (res.status == 200) {
        toast({
          title: data.message,
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          title: data.message,
          status: "error",
          isClosable: true,
        });
      }
      setLoading(false);
    } catch (e) {
      console.error("Error:", e);
      setLoading(false);
    }
  };

  const bookStay = () => {
    if (
      selectionStartDate === defaultDate &&
      selectionEndDate === defaultDate
    ) {
      toas({
        title: "Please select check in and check out date first",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }

    createBooking();
  };

  return (
    <>
      <Navbar />
      <Header />

      <Box
        display="flex"
        gap="5"
        alignItems="center"
        marginTop="20"
        marginLeft="5"
        pt="5"
      >
        <BiLeftArrowAlt fontSize="22px" onClick={() => window.history.back()} />
        <Text
          color={"#000"}
          fontWeight="500"
          fontFamily="Cormorant Garamond"
          fontStyle="normal"
          fontSize="22px"
        >
          Back
        </Text>
      </Box>

      <Box mt="20px" display="flex" alignItems="center" justifyContent="center">
        <Box color={"black"} mb="5px">
          <Calendar
            key={key}
            className="react"
            value={[selectionStartDate, selectionEndDate]}
            selectRange={true}
            tileDisabled={tileDisabled}
            onChange={onChange}
            minDate={new Date()}
            maxDate={maxDates}
            onClickDay={onClickDay}
            tileContent={tileContent}
            tileClassName={tileClassName}
            onActiveStartDateChange={handleActiveStartDateChange}
          />
        </Box>
      </Box>

      <SimpleGrid
        columns={[2]}
        gap="6"
        w="90%"
        margin="auto"
        mt="10"
        pb="2"
        mb="24"
        h="full"
      >
        <Box display="flex" gap="2">
          <Box width="30px" h="20px" bg="#aab6bc" borderRadius="3px"></Box>
          <Text
            fontWeight="600"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="12px"
          >
            Book by rentals
          </Text>
        </Box>
        <Box display="flex" gap="2">
          <Box width="30px" h="20px" bg="#2f6a86" borderRadius="3px"></Box>
          <Text
            fontWeight="600"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="12px"
          >
            Book by owner
          </Text>
        </Box>
        <Box display="flex" gap="2">
          <Box width="30px" h="20px" bg="#e2b599" borderRadius="3px"></Box>
          <Text
            fontWeight="600"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="12px"
          >
            Book by co-owner
          </Text>
        </Box>
        <Box display="flex" gap="2">
          <Box width="30px" h="20px" bg="#f17a36" borderRadius="3px"></Box>
          <Text
            fontWeight="600"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="12px"
          >
            Holidays
          </Text>
        </Box>
      </SimpleGrid>

      <Box position="fixed" bottom="0" maxW="60vh" w="100%">
        <Box
          w={"100%"}
          display={"flex"}
          padding="15px"
          zIndex={"20"}
          color="#000000"
          alignItems={"center"}
          justifyContent="space-between"
          bg="#FFFFFF"
          borderTop="1px"
        >
          <Text ml="15px">
            {selectionStartDate !== defaultDate &&
            selectionEndDate !== defaultDate
              ? `${moment(selectionStartDate)
                  .format("DD-MMM")
                  .toString()} : ${moment(selectionEndDate)
                  .format("DD-MMM")
                  .toString()}`
              : "__-___ : __-___"}
          </Text>
          <Button
            mr="15px"
            fontWeight="bold"
            background="#00272C"
            color="#E2B599"
            colorScheme="none"
            isDisabled={
              !(
                selectionStartDate !== defaultDate &&
                selectionEndDate !== defaultDate
              )
            }
            opacity={
              selectionStartDate !== defaultDate &&
              selectionEndDate !== defaultDate
                ? "100%"
                : "50%"
            }
            onClick={bookStay}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Calender;
