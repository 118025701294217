import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { MainSection } from "../../layout/mainSection/MainSection";
import { Home } from "../../sections/home/Home";
import { Certificates } from "../../sections/certificates/Certificates";
import { UserProfile } from "../../sections/userProfile/UserProfile";
import { UserAdvProfile } from "../../sections/userProfile/UserAdvProfile";
import Mystay from "../../pages/Mystay/Mystay";
import Payment from "../../pages/Payment/Payment";
import More from "../../pages/More/more";
import Calender from "../../pages/Calender";

import Mydashboard from "../../pages/MyDashboard/Mydashboard";
import Profile from "../Profile/Profile";
import Externallink from "../Extenal/Externallink";
import Referearn from "../../pages/Referearn";
import Chat from "../Chat/chat";
import Ticket from "../Chat/Ticket";
import Support from "../Chat/Support";
import { ProfileForm } from "../profileForm/ProfileForm";
import UserBookingData from "../../pages/UserBookingData/UserBookingData";

const extenalLink = {
  data: [
    {
      id: 1,
      name: "faq",
      link: "/dashboard/faq",
      path: "https://alyf.in/faq",
    },
    {
      id: 2,
      name: "terms&condition",
      link: "/dashboard/terms&condition",
      path: "https://alyf.in/disclaimer",
    },
    {
      id: 3,
      name: "privacy",
      link: "/dashboard/privacy",
      path: "https://alyf.in/privacy",
    },

    {
      id: 4,
      name: "howitsworks",
      link: "/dashboard/howitsworks",
    },
  ],
};
export const Main = () => {
  const match = useRouteMatch();

  return (
    <MainSection>
      <Switch>
        <Route path={`${match.path}/myprofile`} component={UserProfile} />
        <Route path={`${match.path}/certificates`} component={Certificates} />

        {extenalLink.data.map((ele) => (
          <Route
            key={ele.id}
            path={`${match.path}/${ele.name}`}
            component={Externallink}
          />
        ))}

        <Route path={`${match.path}/mystay`} component={Mystay} />
        <Route path={`${match.path}/payment`} component={Payment} />
        <Route path={`${match.path}/stay`} component={Home} />
        <Route path={`${match.path}/chat`} component={Chat} />
        <Route path={`${match.path}/more`} component={More} />
        <Route path={`${match.path}/bookings`} component={UserBookingData} />
        <Route path={`${match.path}/`} component={Mydashboard} />
        <Route path={`/refer`} component={Referearn} />
        <Route path={`/ticket`} component={Ticket} />
        <Route path={`/support`} component={Support} />
      </Switch>
    </MainSection>
  );
};
