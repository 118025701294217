import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Signup } from "./pages/signup/Signup";
import { AuthProvider } from "./contexts/Auth";
import { Signin } from "./pages/signin/Signin";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import MagicLink from "./pages/magicLink/MagicLink";
import AuthActionHandler from "./pages/authActionHandler/AuthActionHandler";
import PrivateRoute from "./routes/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import theme from "./theme/index";
import Calender from "./pages/Calender";
import UserBookingData from "./pages/UserBookingData/UserBookingData";
import { PropertyProvider } from "./contexts/Property";
import OtpLogin from "./pages/signin/signinOtp";

export const App = () => {
  const queryClient = new QueryClient();

  React.useEffect(() => {
    const el = document.querySelector(".container");
    if (el) {
      el.remove();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <PropertyProvider>
          <AuthProvider>
            <Router>
              <Switch>
                <Route path="/signup" component={Signup} />
                <Route path="/signin" component={Signin} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/get-magic-link" component={MagicLink} />
                <Route path="/signinOtp" component={OtpLogin} />
                <PrivateRoute path="/bookings" component={UserBookingData} />
                <Route
                  path="/auth-action-handler"
                  component={AuthActionHandler}
                />
                <PrivateRoute
                  path="/calander/:propertyId"
                  component={Calender}
                />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/" component={Dashboard} />
              </Switch>
            </Router>
          </AuthProvider>
        </PropertyProvider>
      </ChakraProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};
