import React from "react";
import { HeaderNavbar } from "../../layout/headerNavbar/HeaderNavbar";
import {
  HEADER_LOGO_HEIGHT,
  HEADER_LOGO_SMALL_SCREEN_RATIO,
  HEADER_LOGO_SMALL_SCREEN_TEXT_SIZE,
} from "../../configs/LayoutConfigs";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { Logo } from "../logo/Logo";
import {
  Avatar,
  Circle,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Spacer,
} from "@chakra-ui/react";
import { useAuth } from "../../contexts/Auth";
import { useHistory } from "react-router-dom";
import { FaSignOutAlt, FaBriefcase, FaUserAstronaut } from "react-icons/fa";
import { useState } from "@hookstate/core";
import { defaultAvatarsType } from "../../dataObjects/DefaultAvatarsObject";
import { randomUserAvatar } from "../../stores/UserDataStore";
import { getUserProfileData } from "../../dataFetchers/userProfileDataFetcher";
import { useQuery } from "react-query";
import { globalCacheBuster } from "../../stores/CacheBuster";
import { DISPLAY_PROFILE_IMAGE_CHANGE_ON_HEADER } from "../../configs/GlobalConstants";
import { useProperty } from "../../contexts/Property";

export const Navbar = () => {
  const userAvatarObj = useState<defaultAvatarsType>(randomUserAvatar);
  const cacheBuster = useState(globalCacheBuster);
  const { user, signOut } = useAuth();
  const history = useHistory();
  const { data } = useQuery("userProfile", getUserProfileData);

  return (
    <>
      <HeaderNavbar>
        <Logo
          h={{
            base: `${HEADER_LOGO_HEIGHT * HEADER_LOGO_SMALL_SCREEN_RATIO}rem`,
            md: `${HEADER_LOGO_HEIGHT}rem`,
          }}
          fontSize={{
            base: HEADER_LOGO_SMALL_SCREEN_TEXT_SIZE,
            md: "inherit",
          }}
          ml={1.5}
        />
        <Spacer />
        {/* <ColorModeSwitcher mr={3} /> */}
        {user && (
          <>
            <Menu>
              <MenuButton>
                <Avatar
                  name={
                    data && data.queryData && data.queryData.full_name
                      ? data.queryData.full_name
                      : userAvatarObj.get().displayName
                  }
                  src="/images/avatar-min.jpg"
                  // src={
                  //     ( data && data.queryData && data.queryData.avatar_url ) ?
                  //         ( ( DISPLAY_PROFILE_IMAGE_CHANGE_ON_HEADER && ( userAvatarObj.URL.get().substring(0,4) === 'blob' ) ) ?
                  //         userAvatarObj.URL.get() :
                  //         data.queryData.avatar_url+'?cacheBuster='+cacheBuster.profilePic.get() ) :
                  //     userAvatarObj.URL.get()
                  // }
                  // src={ ( data && data.queryData ) ? ( ( data.queryData.avatar_url ) ? data.queryData.avatar_url+'?cacheBuster='+cacheBuster.profilePic.get() : userAvatarObj.URL.get() ) : userAvatarObj.URL.get() }
                  size="md"
                  justifySelf="flex-end"
                  p="1"
                  mr={4}
                />
              </MenuButton>
              <MenuList>
                {/* <MenuItem onClick={() => history.push('/dashboard/profile')}> <Circle mr={3} mt={1}><FaUserAstronaut /></Circle> <span>Profile</span></MenuItem>
                            <MenuItem> <Circle mr={3} mt={1}><FaBriefcase /></Circle> <span>Account</span></MenuItem>
                            <MenuDivider /> */}
                <MenuItem onClick={signOut}>
                  <Circle mr={3} mt={1}>
                    <FaSignOutAlt />
                  </Circle>
                  <span>Sign Out</span>
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </HeaderNavbar>
    </>
  );
};
