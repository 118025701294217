import { Flex, HTMLChakraProps, Image, Text, useColorMode, useToken } from '@chakra-ui/react'
import * as React from 'react'
import Alyf from '../../assets/images/Alyf.png'
import AlyfWhite from '../../assets/images/AlyfWhite.png'

export const Logo = (props: HTMLChakraProps<'div'> & { iconColor?: string }) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  const iscolor=localStorage.getItem("chakra-ui-color-mode")
  const { colorMode, toggleColorMode } = useColorMode()
  const handleAuth=()=>{
      if (window.location.origin.indexOf('/dashboard') === -1) {
        window.location.href = '/dashboard';
      }
  }
  return (
    <Flex alignItems="center" {...rest} >
      <Image
        src={Alyf}
        h="inherit"
        mr={2}

        bg="transparent"
        w="auto"
        onClick={()=>handleAuth()}
      />
     
    </Flex>
  )
}
