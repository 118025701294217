import {
  Flex,
  BoxProps,
  Select,
  Box,
  Heading,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { HEADER_NAVBAR_HEIGHT } from "../../configs/LayoutConfigs";
import { useProperty } from "../../contexts/Property";
import supabase from "../../configs/Supabase";
import { useQuery } from "react-query";
import {
  getPropertyData,
  getUserProfileData,
} from "../../dataFetchers/userProfileDataFetcher";
import { useLocation } from "react-router-dom";

export const HeaderNavbar = (props: BoxProps) => {
  let { pathname } = useLocation();

  const {
    isError: isPropertyError,
    isLoading: isPropertyLoading,
    data: propertyData,
    error: propertyError,
  } = useQuery("propertyData", getPropertyData);
  const { setPropertyData } = useProperty();
  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedPropertyId = event.target.value;
    console.log(selectedPropertyId);

    const { data, error } = await supabase
      .from("ownership")
      .select(
        `
                shares_owned,
                ownership_percentage,
                investment_amount,
                q1_mg_amount,
                is_resale,
                property_id (
                    property_id,
                    project_name,
                    description,
                    property_images,
                    property_category,
                    property_type,
                    flat_number,
                    location_id (
                        state,
                        country
                    )
                )
            `
      )
      .eq("property_id", selectedPropertyId)
      .limit(1)
      .single();

    if (error) {
      console.error("Error fetching property data:", error);
      setPropertyData({
        isQueryError: true,
        queryData: null,
        queryError: error,
      });
    } else {
      setPropertyData({
        isQueryError: false,
        queryData: data,
        queryError: null,
      });
    }
  };
  const { isError, isLoading, data, error } = useQuery(
    "userProfile",
    getUserProfileData
  );
  return (
    <>
      <Flex
        h={`${HEADER_NAVBAR_HEIGHT}rem`}
        w="100vw"
        maxW="60vh"
        alignItems="center"
        bg={"#fefefe"}
        borderBottomWidth={1}
        shadow="md"
        position="fixed"
        top="0"
        zIndex={100}
        {...props}
      />
      {pathname === "/dashboard/more" && <Box pt="5rem"></Box>}
      {!(pathname === "/dashboard/more" || pathname.includes("/calander")) && (
        <Flex
          pt="5rem"
          justifyContent="space-between"
          alignItems="center"
          px="4"
        >
          <Heading as="h6" fontSize="18px">
            {(pathname === "/dashboard" || pathname === "/") && (
              <Flex color="#3c3c3c" fontFamily="poppins" fontWeight="600">
                Hello&nbsp;
                <Skeleton isLoaded={!isLoading}>
                  {(data?.queryData?.name?.split(" ").at(0) || "User") + " !"}
                </Skeleton>
              </Flex>
            )}

            {pathname === "/dashboard/mystay" && (
              <Text color="#3c3c3c" fontFamily="poppins" fontWeight="600">
                My Home
              </Text>
            )}

            {pathname === "/dashboard/payment" && (
              <Text color="#3c3c3c" fontFamily="poppins" fontWeight="600">
                My Earnings
              </Text>
            )}

            {pathname === "/dashboard/bookings" && (
              <Text color="#3c3c3c" fontFamily="poppins" fontWeight="600">
                My Bookings
              </Text>
            )}
          </Heading>

          {propertyData && propertyData?.queryData?.length > 1 && (
            <Box>
              <Select
                defaultValue="0"
                maxW="10.5rem"
                fontSize="14px"
                fontWeight={"medium"}
                onChange={handleSelectChange}
              >
                {propertyData &&
                  propertyData?.queryData?.map((val, index) => {
                    return (
                      <option
                        value={val.property_id.property_id}
                        key={index}
                      >{`${val.property_id.project_name} ${val.property_id.flat_number}`}</option>
                    );
                  })}
              </Select>
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};
