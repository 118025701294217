import {
    Badge,
    Box,
    HStack,
    Stack,
    StackDivider,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { certificateType } from "../../types/certificateTypes/certificateTypes"


// const colors: Record<BlogMetaProps['type'], string> = {
//     participant: 'blue',
//     winner: 'red',
//     employee: 'purple',
// }

export const BlogMeta = (props: certificateType) => {
    const { type, event_type, event_start_date, verified } = props
    return (
        <Stack
            spacing={{ base: '4', md: '6' }}
            direction={{ base: 'column', md: 'row' }}
            textTransform="uppercase"
            fontSize="xs"
            letterSpacing="wider"
            fontWeight="semibold"
        >
            <Stack>
                <Badge alignSelf="flex-start" colorScheme='blue' variant="solid">
                    {type}
                </Badge>
                {verified &&
                    <Badge alignSelf="flex-start" colorScheme='green' variant="solid">
                        Verified
                    </Badge>
                }
            </Stack>
            <HStack
                divider={<StackDivider h="3" alignSelf="center" />}
                spacing="3"
                color={mode('gray.600', 'gray.400')}
            >

                <Box>{event_type}</Box>
                <Box>{event_start_date}</Box>

            </HStack>
        </Stack>
    )
}
