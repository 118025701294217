import { Box } from "@chakra-ui/layout";
import React from "react";
import { useRouteMatch } from "react-router";

const extenalLink = {
  data: [
    {
      id: 1,
      name: "faq",
      link: "/dashboard/faq",
      path: "https://alyf.in/faq",
    },
    {
      id: 2,
      name: "terms&condition",
      link: "/dashboard/terms&condition",
      path: "https://alyf.in/disclaimer",
    },
    {
      id: 3,
      name: "privacy",
      link: "/dashboard/privacy",
      path: "https://alyf.in/privacy",
    },

    {
      id: 4,
      name: "howitsworks",
      link: "/dashboard/howitsworks",
      path: "https://alyf.in/how-it-works/buy",
    },
  ],
};

const Externallink = () => {
  const match = useRouteMatch();
  return (
    <>
      {extenalLink.data.map((ele) => {
          if (match.path === ele.link) {
            return (
              <Box key={ele.id} w="100%" h="800px" marginTop="-90px">
                <iframe title={ele.name} src={ele.path} width="100%" height="100%"></iframe>
              </Box>
            );
          }
        return true
      })}
    </>
  );
};

export default Externallink;
