import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Finance,
  Tenteativesharevalue,
  TotalEarning,
  Totalexpense,
  Totalrevenue,
  Vector,
  Yourvilla,
} from "../../components/Icon/fin";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import "./payment.css";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  getBookingsData,
  getExpensesData,
  getPropertyData,
} from "../../dataFetchers/userProfileDataFetcher";
import { useQuery } from "react-query";
import { useAuth } from "../../contexts/Auth";
import {
  expensesFetcherObjectReturnType,
  expensesObjectType,
  propertyObjectType,
} from "../../types/dataFetcherTypes/DataFetcherTypes";
import { number } from "yup";
import { useProperty } from "../../contexts/Property";
import { MdPayment } from "react-icons/md";

export const DEFAULT_EXPENSES_OBJECT = {
  property_id: 0,
  available_days: 0,
  occupancy_achieved_in_days: 0,
  occupancy_achieved_in_percentage: 0,
  arr_achieved: 0,
  revenue_generated: 0,
  alyf_share: 0,
  operating_expenses: 0,
  owner_share: 0,
  minimum_return_as_per_pma: 0,
  amount_payable: 0,
  amount_payable_per_share_gross: 0,
  amount_payable_per_share_net_taxation: 0,
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius - 10}
        outerRadius={innerRadius - 6}
        fill={fill}
      />
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

function Payment() {
  const propRef = useRef<HTMLSelectElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const { isLoading: isPropertyLoading, propertyData } = useProperty();

  const {
    isError: isExpensesError,
    isLoading: isExpensesLoading,
    data: expensesData,
    error: expensesError,
  } = useQuery("expensesData", getExpensesData);

  // setTimeout(() => {
  //   console.log(expensesData?.queryData);
  // }, 500);

  const { user } = useAuth();
  const userId = user?.id;

  const pieChartColors = {
    "OTA Fees": "#245E53",
    "ALYF Fees": "#7FAB63",
    Expenses: "#E99D08",
    Earnings: "#F17A36",
  };

  const getExpenseData = () => {
    return [
      {
        name: "OTA Fees",
        value: Math.round(earning * 0.15),
      },
      {
        name: "ALYF Fees",
        value: Math.round((earning - earning * 0.15) * 0.25),
      },
      {
        name: "Expenses",
        value: Math.round((earning - earning * 0.15) * 0.75 * 0.4),
      },
      {
        name: "Earnings",
        value: Math.round((earning - earning * 0.15) * 0.75 * 0.6),
      },
    ];
  };

  const DEFAULT_OWNERSHIP_OBJECT: propertyObjectType = {
    shares_owned: 1,
    ownership_percentage: 12.5,
    investment_amount: 0,
    q1_mg_amount: 0,
    property_id: {
      property_id: "",
      project_name: "",
      description: "",
      property_images: [""],
      property_category: "flat",
      property_type: "ALYF Growth",
      flat_number: "",
      location_id: {
        state: "",
        country: "",
      },
    },
  };

  const [propData, setPropData] = useState<expensesObjectType>(
    DEFAULT_EXPENSES_OBJECT
  );

  const [ownershipData, setOwnershipData] = useState<propertyObjectType>(
    DEFAULT_OWNERSHIP_OBJECT
  );
  const [selectedQuarterIndex, setSelectedQuarterIndex] = useState<
    number | string
  >(0);
  // console.log(propData);

  const [activeIndex, setActiveIndex] = useState(0);
  const earning = 21500;

  useEffect(() => {
    expensesData?.queryData?.map((data, i) => {
      if (
        propertyData?.queryData.property_id.property_id === data.property_id
      ) {
        setPropData(data);
      }
    });
    // setPropData((expensesData?.queryData && expensesData.queryData) ? expensesData?.queryData : DEFAULT_EXPENSES_OBJECT);
    setOwnershipData(
      propertyData?.queryData && propertyData?.queryData
        ? propertyData.queryData
        : DEFAULT_OWNERSHIP_OBJECT
    );
  }, [expensesData, propertyData]);

  console.log(ownershipData, "owner");
  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };

  const onQuarterChange = (e: { target: { value: any } }) => {
    const selectedIndex = e.target.value;

    if (selectedIndex === "Full") {
      setPropData(calculateSum(expensesData));
      setSelectedQuarterIndex("Full");
      return;
    }
    setSelectedQuarterIndex(selectedIndex);
    setPropData(expensesData.queryData[selectedIndex]);
  };
  function calculateSum(
    expensesData: expensesFetcherObjectReturnType | undefined
  ) {
    const newData = {};

    expensesData?.queryData.forEach(obj => {
      Object.keys(obj).forEach(key => {
        if (typeof obj[key] === "number") {
          newData[key] = (newData[key] || 0) + obj[key];
        } else {
          newData[key] = obj[key];
        }
      });
    });

    return newData;
  }

  console.log(
    selectedQuarterIndex,
    expensesData?.queryData[selectedQuarterIndex]
  );

  const is_investement_not_available =
    new Date(propertyData?.queryData.start_date) >
    new Date(propData.payout_date);

  return (
    <>
      <Box pt="1" mt="3" px="3">
        <Select
          mt="2"
          fontSize="14px"
          onChange={onQuarterChange}
          value={selectedQuarterIndex || "Full"}
        >
          <option value="Full">Full Year</option>
          {expensesData?.queryData?.map((val, index) => {
            {
              return (
                val.property_id ===
                  propertyData?.queryData.property_id.property_id && (
                  <>
                    <option value={index} key={index}>
                      {val.quarter} ({Number(val.year) - 1} - {val.year})
                    </option>
                  </>
                )
              );
            }
          })}
        </Select>
      </Box>

      {is_investement_not_available ? (
        <Box>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="5"
            gap="2"
          >
            <svg width="40" height="40" viewBox="0 0 25 25" fill="none">
              <path
                d="M9.375 14.583C9.375 16.309 12.1729 17.708 15.625 17.708C19.0771 17.708 21.875 16.309 21.875 14.583C21.875 12.857 19.0771 11.458 15.625 11.458C12.1729 11.458 9.375 12.857 9.375 14.583Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.375 14.583V18.7497C9.375 20.4747 12.1729 21.8747 15.625 21.8747C19.0771 21.8747 21.875 20.4747 21.875 18.7497V14.583"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.125 6.25C3.125 7.36667 4.31667 8.39792 6.25 8.95625C8.18333 9.51458 10.5667 9.51458 12.5 8.95625C14.4333 8.39792 15.625 7.36667 15.625 6.25C15.625 5.13333 14.4333 4.10208 12.5 3.54375C10.5667 2.98542 8.18333 2.98542 6.25 3.54375C4.31667 4.10208 3.125 5.13333 3.125 6.25Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.125 6.25V16.6667C3.125 17.5917 3.92917 18.1771 5.20833 18.75"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.125 11.458C3.125 12.383 3.92917 12.9684 5.20833 13.5413"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Text
              mt="1"
              fontWeight="600"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
            >
              No Earnings Yet
            </Text>
            <Text
              mt="1"
              fontWeight="500"
              fontFamily="Montserrat"
              fontSize="14px"
              fontStyle="normal"
              px="6"
            >
              We will notify you if any data is available.
            </Text>
          </Box>
        </Box>
      ) : (
        <>
          <Text
            fontWeight="600"
            fontSize="16px"
            fontStyle="normal"
            fontFamily="Montserrat"
            mt="5"
            px="4"
          >
            {`Payment Details ( ${
              selectedQuarterIndex == "Full"
                ? "Full Year"
                : propData.quarter +
                  " " +
                  (Number(propData.year) - 1) +
                  " - " +
                  propData.year
            })`}
          </Text>
          <Box
            py="5"
            px="3"
            mt="3"
            bgColor="white"
            borderRadius="md"
            boxShadow={
              "rgba(0, 0, 0, 0.0) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"
            }
          >
            <Box mt="2" px="3">
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Day available
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.available_days}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Occupancy achieved (in days)
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.occupancy_achieved_in_days}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Occupancy achieved (in %)
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.occupancy_achieved_in_percentage} %
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    ARR achieved
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.arr_achieved}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Gross revenue generated
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.revenue_generated}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    ALYF share @ 25%
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.alyf_share}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Operational costs
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.operating_expenses}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Owner’s share ( e - f - g )
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.owner_share}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Minimum guarantee as per PMA
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.minimum_return_as_per_pma}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Amount payable for Q2
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {propData.amount_payable}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Gross amount Payable for{" "}
                    {ownershipData.ownership_percentage === 100
                      ? "full owners"
                      : `${ownershipData.shares_owned}/8th Share`}{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {Number(ownershipData.shares_owned) *
                      propData.amount_payable_per_share_gross}
                  </Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text fontWeight="500" fontSize="14px">
                    Net amount payable for{" "}
                    {ownershipData.ownership_percentage === 100
                      ? "full owners"
                      : `${ownershipData.shares_owned}/8th Share`}{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight="500" fontSize="14px" textAlign="right">
                    {ownershipData.shares_owned *
                      propData.amount_payable_per_share_net_taxation}
                  </Text>
                </GridItem>
              </Grid>

              {/* <Box w="100%" h="280px" mt="5">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={getExpenseData()}
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={95}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                  label
                >
                  <Cell
                    style={{ outline: "none" }}
                    key={`cell-1`}
                    fill="#245E53"
                  />
                  <Cell
                    style={{ outline: "none" }}
                    key={`cell-2`}
                    fill="#7FAB63"
                  />
                  <Cell
                    style={{ outline: "none" }}
                    key={`cell-3`}
                    fill="#E99D08"
                  />
                  <Cell
                    style={{ outline: "none" }}
                    key={`cell-4`}
                    fill="#185d53"
                  />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Flex justifyContent="space-between">
            <Box>
              <Flex justifyContent="flex-start">
                <Box
                  w="2"
                  h="2"
                  mr="1"
                  borderRadius="100%"
                  bgColor="#245E53"
                  alignSelf="center"
                />
                <Text fontWeight="500" fontSize="12px" fontStyle="normal">
                  OTA Fees
                </Text>
              </Flex>
              <Flex justifyContent="flex-start">
                <Box
                  w="2"
                  h="2"
                  mr="1"
                  borderRadius="100%"
                  bgColor="#7FAB63"
                  alignSelf="center"
                />
                <Text fontWeight="500" fontSize="12px" fontStyle="normal">
                  ALYF Fees
                </Text>
              </Flex>
            </Box>

            <Box>
              <Flex justifyContent="flex-start">
                <Box
                  w="2"
                  h="2"
                  mr="1"
                  borderRadius="100%"
                  bgColor="#E99D08"
                  alignSelf="center"
                />
                <Text fontWeight="500" fontSize="12px" fontStyle="normal">
                  Expenses
                </Text>
              </Flex>
              <Flex justifyContent="flex-start">
                <Box
                  w="2"
                  h="2"
                  mr="1"
                  borderRadius="100%"
                  bgColor="#185d53"
                  alignSelf="center"
                />
                <Text fontWeight="500" fontSize="12px" fontStyle="normal">
                  Earnings
                </Text>
              </Flex>
            </Box>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Water (F)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                propData.Expense.Water.toLocaleString('en-IN')+".00"
              }
            </Text>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Other (G)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                propData.Expense.Other.toLocaleString('en-IN')+".00"
              }
            </Text>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Expenses (H) (B+C+D+E+F+G)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                (propData.Expense.CAM + propData.Expense.Consumables + propData.Expense.Maintenance + propData.Expense.Manpower + propData.Expense.Other + propData.Expense.Water).toLocaleString('en-IN')+".00"
              }
            </Text>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Earnings (I) (A-H)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                (propData.Revenue - (propData.Expense.CAM + propData.Expense.Consumables + propData.Expense.Maintenance + propData.Expense.Manpower + propData.Expense.Other + propData.Expense.Water)).toLocaleString('en-IN')+".00"
              }
            </Text>
          </Flex> */}
              {/* 
          <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              ALYF Share (25%) (J)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                parseFloat(((propData.Revenue - (propData.Expense.CAM + propData.Expense.Consumables + propData.Expense.Maintenance + propData.Expense.Manpower + propData.Expense.Other + propData.Expense.Water))*0.25).toFixed(2)).toLocaleString('en-IN')
              }
            </Text>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Home Owner's Share (75%) (K)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                parseFloat(((propData.Revenue - (propData.Expense.CAM + propData.Expense.Consumables + propData.Expense.Maintenance + propData.Expense.Manpower + propData.Expense.Other + propData.Expense.Water))*0.75).toFixed(2)).toLocaleString('en-IN')
              }
            </Text>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Minimum Guarantee (L)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                parseFloat(((propData.propertyPrice)*(propData.minimumGuarantee/400)).toFixed(2)).toLocaleString('en-IN')+".00"
              }
            </Text>
          </Flex> */}

              {/* <Flex mt="1">
            <Text fontWeight="400" fontSize="14px">
              Payout (M) (Max of K,L)
            </Text>
            <Spacer />
            <Text fontWeight="400" fontSize="14px">
              {
                parseFloat((Math.max((propData.propertyPrice)*(propData.minimumGuarantee/400), ((propData.Revenue - (propData.Expense.CAM + propData.Expense.Consumables + propData.Expense.Maintenance + propData.Expense.Manpower + propData.Expense.Other + propData.Expense.Water))*0.75))).toFixed(2)).toLocaleString('en-IN')
              }
            </Text>
          </Flex> */}
            </Box>
          </Box>

          <Text mt={4} fontSize="sm" px="4">
            *Note: The expenses and revenue are subject to audit and
            discrepancy, it shall be adjusted in the yearly calculations
          </Text>
        </>
      )}
      {/* <Text
        fontWeight="600"
        fontSize="16px"
        fontStyle="normal"
        fontFamily="Montserrat"
        mt="10"
      >
        Booking Details
      </Text>

      <Box borderRadius="6px" p="2" mt="5" background="#FFF">
        <Box display="flex" justifyContent="space-between" mt="2">
          <Heading as="h5" fontWeight="medium" fontSize="14px">
            Name
          </Heading>
          <Box display="flex" w="60%" justifyContent="space-between">
            <Heading as="h5" fontWeight="medium" fontSize="14px">
              Check In
            </Heading>
            <Heading as="h5" fontWeight="medium" fontSize="14px">
              Check Out
            </Heading>
          </Box>
        </Box>
        <Divider mt="2" color="gray" />

        {bookingsData?.queryData?.map((val, index) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              mt="3"
              key={index}
            >
              <Heading as="h5" fontWeight="medium" fontSize="14px">
                {val.guest_name}
              </Heading>
              <Box display="flex" w="60%" justifyContent="space-around">
                <Heading as="h5" fontWeight="medium" fontSize="14px">
                  {val.checkin_date}
                </Heading>
                <Heading as="h5" fontWeight="medium" fontSize="14px">
                  {val.checkout_date}
                </Heading>
              </Box>
            </Box>
          );
        })}

        <Divider mt="2" color="gray" variant="dashed" />
      </Box>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalContent py="5" border="1px" w="95%" position="absolute" top="5">
          <ModalCloseButton />
          <Yourvilla fontSize="40px" m="auto" />
          <Heading m="auto" mt="2" fontSize="20px">
            Total Earnings
          </Heading>
          <ModalBody m="auto" fontSize="12px" w="90%" textAlign="center">
            It refers to a contractual assurance or commitment made by one party
            to another, establishing a minimum level of performance, payment, or
            outcome.{" "}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal onClose={onCloseModal1} isOpen={isOpenModal1} isCentered>
        <ModalContent py="5" border="1px" w="95%" position="absolute" top="5">
          <ModalCloseButton />
          <Totalrevenue fontSize="40px" m="auto" />
          <Heading m="auto" mt="2" fontSize="20px">
            Minimum Garuntee
          </Heading>
          <ModalBody m="auto" fontSize="12px" w="90%" textAlign="center">
            It refers to a contractual assurance or commitment made by one party
            to another, establishing a minimum level of performance, payment, or
            outcome.
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
}
export default Payment;
