import React from 'react'
import { Box, Center, FlexProps, Icon, Stack, Text } from '@chakra-ui/react'
import { SIDEBAR_CONFIG, SIDEBAR_PORTRAIT_TO_LANDSCAPE_RATIO } from '../../configs/LayoutConfigs'
import './SidebarItem.css'
import { useHistory, useLocation } from 'react-router-dom'
import { SidebarItemObjectType } from '../../dataObjects/SideBarObject'

export interface SidebarItemInputType extends FlexProps {
    menuObj: SidebarItemObjectType
}

export const SidebarItem = ( props:SidebarItemInputType ) => {
    const { menuObj, ...propsToPass } = props
    const location = useLocation()
    const history = useHistory()
    const isActive = ( location.pathname === menuObj.link )?true:false

    return (
        <Center     
            w="50px"
            h="50px"
            m="auto"
            mx='5'
            {...propsToPass}
            onClick={() => {
                //@ts-ignore
                (menuObj.noLink === true)? menuObj.onClickFunction() : history.push(menuObj.link)
            }}
            cursor="pointer"
            borderRadius="100%"
            position="relative"
            className="stopHighlightOnSelect"
        >
            <Stack alignItems="center" spacing={1} border={"5px"} zIndex="100" >
                <Icon as={ menuObj.icon } boxSize="6" color="white" />
            </Stack>
            <Box 
                position="absolute"
                borderRadius="100%"
                className={isActive?'myNevBarActiveClassBox':'myNevBarClassBox'}
                zIndex="90"
            />
        </Center>
    )
}
