import {
    Box,
    Button,
    Flex,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { FaFacebook, FaGoogle } from 'react-icons/fa'
import { DividerWithText } from '../../components/divider/DividerWithText'
import { Logo } from '../../components/logo/Logo'
import { SigupForm } from '../../components/signupForm/SigupForm'
import { Testimonial } from '../../components/testimonial/Testimonial'
import * as Logos from '../../components/brands/Brands'
import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import { useAuth } from '../../contexts/Auth'
import { Link } from 'react-router-dom'
  
export const Signup: React.FC = () => {

    const { signInByGmail, signInByFacebook } = useAuth()

    const gmailLogin = async () => {
        await signInByGmail()
    }

    const facebookLogin = async () => {
        await signInByFacebook()
    }

    return (
      <Box minH="100vh" bg={{ md: mode('gray.100', 'inherit') }}>
        <Flex justifyContent="flex-end" mb={-7}>
          <ColorModeSwitcher m={2} />
        </Flex>
        <Box maxW="6xl" mx="auto" py={{ base: '10', md: '20' }} px={{ base: '4', md: '10' }}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="14">
            <Box w="full" maxW="xl" mx="auto">
              <Box
                bg={{ md: mode('white', 'gray.700') }}
                rounded={{ md: '2xl' }}
                p={{ base: '4', md: '12' }}
                borderWidth={{ md: '1px' }}
                borderColor={mode('gray.200', 'transparent')}
                shadow={{ md: 'lg' }}
              >
                <Logo
                  h="7"
                  fontSize="1.1rem"
                  justifyContent="center"
                  mb={{ base: '16', md: '10' }}
                  mx={{ base: 'auto', md: 'unset' }}
                />
                <Box mb="8" textAlign={{ base: 'center', md: 'start' }}>
                  <Heading size="lg" mb="2" fontWeight="extrabold">
                    Welcome to GiveMyCertificate
                  </Heading>
                  <Text fontSize="lg" color={mode('gray.600', 'gray.400')} fontWeight="medium">
                    Let's get you started
                  </Text>
                </Box>
                <Stack spacing="4">
                  <Button 
                    variant="outline" 
                    leftIcon={<Box as={FaGoogle} color="red.500" />} 
                    onClick={gmailLogin}
                  >
                    Sign up with Google
                  </Button>
                  <Button
                    variant="outline"
                    leftIcon={<Box as={FaFacebook} color={mode('facebook.500', 'facebook.300')} />}
                    onClick={facebookLogin}
                  >
                    Sign up with Facebook
                  </Button>
                </Stack>
  
                <DividerWithText>or</DividerWithText>
                <SigupForm />
              </Box>
  
              <Text mt="8" align="center" fontWeight="medium">
                Already have an account?{' '}
                <Box
                  as={Link}
                  to="/signin"
                  color={mode('blue.600', 'blue.200')}
                  display={{ base: 'block', md: 'inline-block' }}
                >
                  Log in to Chakra
                </Box>
              </Text>
            </Box>
  
            <Flex direction="column" py="24" display={{ base: 'none', lg: 'flex' }}>
              <Testimonial />
              <SimpleGrid
                columns={2}
                spacing="10"
                paddingStart="12"
                alignItems="center"
                color="gray.400"
              >
                <Logos.IEEEMAIT />
                <Logos.Regex />
              </SimpleGrid>
            </Flex>
          </SimpleGrid>
        </Box>
      </Box>
    )
  }