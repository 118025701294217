// theme/index.js
import { ThemeConfig, extendTheme } from "@chakra-ui/react";

// Global style overrides

import Popover from "./components/popover";

function getSize(value: string) {
  if (value === "full") {
    return {
      dialog: { maxW: "100vw", minH: "100vh", my: 0 },
    };
  } else if (value === "wide") {
    return {
      dialog: { maxW: "90vw" },
    };
  }
  return {
    dialog: { maxW: value },
  };
}

export const overrides = {
  // Other foundational style overrides go here
  components: {
    Popover,
    Modal: {
      // 2. We can add a new button size or extend existing
      sizes: {
        "6xl": getSize("8xl"),
        wide: getSize("wide"),
      },
    },
  },
  colors: {
    yuck: "#00272c",
    brand: "#00272c",
  },
  // Other components go here
};
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
const theme = extendTheme({
  colors: {
    brand: {
      100: "#00272c",
    },
  },
  config,
});

export default extendTheme(theme);
