import { createStandaloneToast } from "@chakra-ui/react";
import moment, { isDate } from "moment";
import { DEFAULT_NOTIFICATION_DISPLAY_TIME } from "../configs/GlobalConstants";
import { CustomError } from "../contexts/Auth";
import { globalErrorObjectMaker } from "./ErrorMessageHelper";
import { globalSuccessObjectMaker } from "./SuccessMessageHelper";

const { customAlphabet } = require("nanoid");
const alphabetSimple = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
const alphabetComplex =
  "123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz";

const nanoIdSimple = customAlphabet(alphabetSimple, 10);
const nanoIdAverage = customAlphabet(alphabetSimple, 16);
const nanoIdComplex = customAlphabet(alphabetComplex, 20);

export interface errorObjectInputType {
  code?: number;
  subType?: string;
  type?: string;
  error?: CustomError | null;
}

export interface successObjectInputType {
  code?: number;
  subType?: string;
  type?: string;
  successObj?: any;
}

const toast = createStandaloneToast();

export const displayError = (
  title?: string,
  message?: string,
  createError: boolean = false,
  errorObject: errorObjectInputType = {}
) => {
  if (createError && Object.keys(errorObject).length !== 0) {
    const homeMadeErrorObject = globalErrorObjectMaker(errorObject);
    title = homeMadeErrorObject.title;
    message = homeMadeErrorObject.message;
  }
  toast({
    title: title,
    description: message,
    status: "error",
    duration: DEFAULT_NOTIFICATION_DISPLAY_TIME,
    isClosable: true,
    position: "top-right",
  });
};

export const displaySuccess = (
  title?: string,
  message?: string,
  createMessage: boolean = false,
  successObject: successObjectInputType = {}
) => {
  if (createMessage && Object.keys(successObject).length !== 0) {
    const homeMadeSuccessObject = globalSuccessObjectMaker(successObject);
    title = homeMadeSuccessObject.title;
    message = homeMadeSuccessObject.message;
  }
  toast({
    title: title,
    description: message,
    status: "success",
    duration: DEFAULT_NOTIFICATION_DISPLAY_TIME,
    isClosable: true,
    position: "top-right",
  });
};

export interface StringMap {
  [key: string]: any;
}

export const getRandomProperty = (obj: StringMap): any => {
  let keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
};

export const getRandomKey = (obj: StringMap): any => {
  let keys = Object.keys(obj);
  return keys[(keys.length * Math.random()) << 0];
};

export const getRandomKeyValuePair = (obj: StringMap): any => {
  let keys = Object.keys(obj);
  let key = keys[(keys.length * Math.random()) << 0];
  return {
    key,
    value: obj[key],
  };
};

export const isFileImage = (file: File) => {
  return file && file["type"].split("/")[0] === "image";
};

export const parseDateString = (value: any, originalValue: any) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : moment(originalValue).format("YYYY-MM-DD");

  return parsedDate;
};

export const createDateWithDays = (days: number) => {
  let newDate = new Date();
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export function addSeperater(
  str: string,
  seperator: string,
  positionArray: number[]
) {
  positionArray.map((pos, index) => {
    str =
      str.slice(0, pos + index) + (seperator || "") + str.slice(pos + index);
    return true;
  });
  return str;
}

export function getUniqueId(
  latency: "low" | "medium" | "high" = "low",
  isSeperater: boolean = true,
  seperator: string = "-",
  positionArray: number[] = [],
  alphabet: string | undefined = undefined,
  size: number = 10
) {
  let certId = "";

  if (alphabet) {
    const nenoIdCustom = customAlphabet(alphabet, size);
    if (isSeperater) {
      certId = addSeperater(nenoIdCustom(), seperator, positionArray);
    } else {
      certId = nenoIdCustom();
    }
  } else {
    switch (latency) {
      case "low":
        if (isSeperater) {
          certId = addSeperater(nanoIdSimple(), seperator, [3, 7]);
        } else {
          certId = nanoIdSimple();
        }
        break;

      case "medium":
        if (isSeperater) {
          certId = addSeperater(nanoIdAverage(), seperator, [4, 8, 12]);
        } else {
          certId = nanoIdAverage();
        }
        break;

      case "high":
        if (isSeperater) {
          certId = addSeperater(nanoIdComplex(), seperator, [5, 10, 15]);
        } else {
          certId = nanoIdComplex();
        }
        break;
    }
  }
  return certId;
}

export const subtractDays = (date: Date | string, days: number) => {
  let givenDate = new Date(date);
  givenDate.setDate(givenDate.getDate() - days);
  return givenDate;
};

export const removeByAttr = function (
  arr: Array<any>,
  attr: number | string,
  value: number | string
) {
  return arr.filter((val) => {
    if (!(val && val.hasOwnProperty(attr) && val[attr] === value)) {
      return true;
    }
  });
};

export const removeByAttrInPlace = function (
  arr: Array<any>,
  attr: number | string,
  value: number | string
) {
  var i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export const convertDDMMYYYYToMMDDYYYY = function (date: string) {
  let parts_of_date = date.split("-");
  let convertedDate = [
    parts_of_date[2],
    parts_of_date[1],
    parts_of_date[0],
  ].join("-");
  console.log(convertedDate);

  return convertedDate;
};
