import { Box, BoxProps, Heading, Text, Button, useColorMode, useColorModeValue as mode, useDisclosure } from '@chakra-ui/react'
import * as React from 'react'
import { BlogAuthor } from './BlogAuthor'
import { BlogMedia } from './BlogMedia'
import { BlogMeta } from './BlogMeta'
import { certificateType } from "../../types/certificateTypes/certificateTypes"
import { CertificateModal } from "../modal/CertificateModal"




export const BlogCard = (props: certificateType) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const bgColor = { light: 'gray.200', dark: 'gray.900' };
    const bgColorHover = { light: 'gray.200', dark: 'gray.700' };
    const textColor = { light: 'gray.500', dark: 'gray.100' };
    const bgColorButton = { light: 'green.200', dark: 'green.500' };

    const { isOpen, onOpen, onClose } = useDisclosure();


    return (
        <Box onClick={onOpen}>
            <CertificateModal
                onClose={onClose}
                isOpen={isOpen}
                onOpen={onOpen}
                image={props.thumbnail_url}
                OrgName={props.org_name}
                Start_Date={props.event_start_date}
                End_Date={props.event_end_date}
                Event_type={props.event_type}
                Certificate_type={props.type}
                Event_name={props.event_name}
                Org_thumb={props.organization_logo_url}
            ></CertificateModal>
            <BlogMedia src={props.thumbnail_url} alt={props.event_name} />
            <Box mt="6">
                <BlogMeta {...props} />
                <Box mb="6">
                    <Box as="a" href='/'>
                        <Heading size="md" mt="6" mb="4">
                            {props.event_name}
                        </Heading>
                    </Box>
                    <Text color={mode('gray.600', 'gray.400')} lineHeight="tall">
                        {props.org_name}<br />

                        {props.recipient_name} <br />
                    </Text>
                </Box>
                <BlogAuthor name={props.org_name} image={props.organization_logo_url} role={props.org_name} />
            </Box>
            <Box transform='translateY(-15%);' textAlign='center' mt='6'>
                <Button
                    variant='solid'
                    variantColor='teal'
                    size='lg'
                    boxShadow='md'
                    mr='2'
                    mt='2'
                    _active={{ boxShadow: 'lg', bgColor: 'teal.500' }}>
                    Download
                </Button>
                <Button
                    variant='solid'
                    variantColor='teal'
                    size='lg'
                    boxShadow='md'
                    mt='2'
                    _active={{ boxShadow: 'lg', bgColor: 'teal.500' }}
                    _hover={{
                        bgColor: bgColorButton[colorMode]
                    }}>
                    Verify
                </Button>
            </Box>
        </Box>
    )
}
