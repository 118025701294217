import { Box, Heading, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/modal";
import React, { useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Check } from "../Icon/fin";
import { useDisclosure } from "@chakra-ui/hooks";
import { Button, Select, Textarea, useToast } from "@chakra-ui/react";
import { getUserProfileData } from "../../dataFetchers/userProfileDataFetcher";
import { useQuery } from "react-query";
import { useAuth } from "../../contexts/Auth";
import supabase from "../../configs/Supabase";

const issues = [
  "Want to know about your transaction",
  "Want to postpone your dates ",
  "Want to prepone your dates ",
  "Having an issue on booking the future dates",
];

const Ticket = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const toast = useToast();
  const { data } = useQuery("userProfile", getUserProfileData);
  let [value, setValue] = React.useState("");
  const [newissue, setIssue] = useState("");

  let handleInputChange = (e: { target: { value: any } }) => {
    let inputValue = e.target.value;

    setValue(inputValue);
  };

  const handlesubmit = async () => {
    try {
      if (!newissue) {
        toast({
          title: `Please select a issue`,
          status: "error",
          isClosable: true,
        });
        return;
      }
      const { error } = await supabase.from("query_ticket").insert({
        name: data.queryData.name,
        email: user?.email,
        query: newissue,
        query_message: value,
      });
      if (error) {
        console.log(error);
      } else {
        onOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box display="flex" gap="5" alignItems="center" mt="4" px="4">
        <BiLeftArrowAlt onClick={() => window.history.back()} fontSize="20px" />
        <Text
          color={"#767676"}
          fontWeight="600"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="16px"
        >
          Raise a Ticket
        </Text>
      </Box>
      <Box mt="5" bg="#FFF" py="3" px="2" borderRadius="5px">
        <>
          <Select
            w="fit-content"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="16px"
            placeholder="Select an issue"
            isRequired
            onChange={e => {
              setIssue(e.target.value);
            }}
          >
            {issues.map((issue, index) => (
              <option key={index} value={issue}>
                {issue}
              </option>
            ))}
          </Select>
          <Textarea
            mt="5"
            value={value}
            onChange={handleInputChange}
            placeholder="Describe your issue..."
            size="sm"
          />
          <Button
            onClick={() => {
              handlesubmit();
            }}
            display="flex"
            m="auto"
            mt="3"
            bg={"#7FAB63"}
            color="white"
            colorScheme="none"
          >
            Submit
          </Button>

          <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalContent
              py="5"
              boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
              w="95%"
              h="40%"
              position="absolute"
              top="15"
            >
              <ModalCloseButton />
              <Check m="auto" mt="20" />
              <Heading m="auto" mt="2" fontSize="20px">
                SIT BACK AND RELAX!
              </Heading>
              <ModalBody m="auto" fontSize="12px" w="90%" textAlign="center">
                Your ticket has been raised. Your problem will be solved in a
                while.
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      </Box>
    </>
  );
};

export default Ticket;
