import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import { Box, Link, Text } from "@chakra-ui/layout";
import React, { useState } from "react";
import { AiOutlineWechat } from "react-icons/ai";
import { GoQuestion } from "react-icons/go";
import Leaf from "../../assets/images/leaf.png";
import { HiArrowRight } from "react-icons/hi";
import { Image } from "@chakra-ui/image";
import { Button, Divider } from "@chakra-ui/react";
import { useAuth } from "../../contexts/Auth";
import { useQuery } from "react-query";
import { getUserProfileData } from "../../dataFetchers/userProfileDataFetcher";

const FaqData = [
  {
    question: "How often will I receive my financial reports?",
    answer: `Reports are provided monthly or quarterly, based on your management agreement. View them on the app or request from your account manager`,
  },
  {
    question: "What should I do if I have a concern or complaint?",
    answer: `Contact your account manager directly. They will assist you and address any issues promptly.`,
  },
  {
    question:
      "What should I do if I want to make improvements or renovations to my property?",
    answer: `Discuss your plans with your account manager. They will help coordinate and ensure compliance with property management policies.`,
  },
  {
    question: "What services does Stay ALYF provide for homeowners?",
    answer: `We offer property management, guest booking and check-in/out, maintenance and housekeeping, marketing, and 24/7 guest support.`,
  },
  {
    question: "How is my property marketed to potential guests?",
    answer: `We use various channels including our website, social media, and online travel agencies (OTAs) to market your property. `,
  },
  {
    question: "What happens if my property needs maintenance or repairs?",
    answer: `Our team will handle all maintenance and repair requests with a snag report. We perform regular inspections and promptly address any issues to ensure your property remains in excellent condition.
    `,
  },
  {
    question: "How can I track bookings and revenue for my property?",
    answer:
      "You can track bookings, revenue, and other metrics through our app. Regular reports will also be provided to keep you informed of your property's performance.",
  },
  {
    question: "How do you ensure the safety and security of my property?",
    answer:
      "We implement strict security measures, including secure check-in processes, surveillance systems, and regular property inspections. Our team ensures all safety standards are met. Additionally, we assist you in obtaining an insurance policy for your property.",
  },
  {
    question: "Can I stay at my property when it is listed?",
    answer:
      "Yes, you can stay at your property. Just block the dates at least a month or 15 days in advance to prevent the reservation team from accepting bookings for those dates.",
  },
];
const Chat = () => {
  const { user } = useAuth();
  const { isError, isLoading, data, error } = useQuery(
    "userProfile",
    getUserProfileData
  );
  const Name = data?.queryData?.name?.split(" ").at(0);

  // const handleReferdByChange = () => {
  //   setIsReferdBySelected(true);
  //   setIsReferdToRentSelected(false);
  // };

  // const handleReferdToRentChange = () => {
  //   setIsReferdBySelected(false);
  //   setIsReferdToRentSelected(true);
  // };
  return (
    <>
      <Text
        fontWeight="600"
        fontFamily="Cormorant Garamond"
        fontStyle="normal"
        fontSize="22px"
        ml="2"
        px="4"
        mt="3"
      >
        Got Questions?
      </Text>
      <Box px="4">
        <Link href="/ticket">
          <Box
            position="relative"
            border="1px"
            bg="#00272C"
            mt="6"
            borderRadius="8px"
            px="2"
            py="3"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px="2"
            >
              <Box display="flex" alignItems="center" gap="20px">
                <GoQuestion color="#E2B599" fontSize="large" />
                <Text
                  fontSize="14px"
                  color="#E2B599"
                  fontFamily="Cormorant Garamond"
                >
                  Raise a Ticket
                </Text>
              </Box>
              <HiArrowRight color="white" />
            </Box>
            <Text w="80%" pl="2" mt="4" fontSize="10px" color="white">
              Have a query, Raise your ticket here and our team will get back to
              you in a while
            </Text>

            <Image position="absolute" bottom="1" right="1" src={`${Leaf}`} />
          </Box>
        </Link>
        <Link
          href={`https://wa.me/+918828304175?text=Hi%20Team%20ALYF%2C%0A%0AI%20have%20a%20query%20regarding%20user%20app.%0A%0ADetails%0AName%3A-%20${Name}%0AEmail%3A-%20${user.email}`}
          target="_blank"
        >
          <Box position="relative">
            <Box
              position="relative"
              border="1px"
              bg="#00272C"
              mt="6"
              borderRadius="8px"
              px="2"
              py="3"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px="2"
              >
                <Box display="flex" alignItems="center" gap="20px">
                  <AiOutlineWechat color="#E2B599" fontSize="20px" />
                  <Text fontSize="14px" color="#E2B599" fontFamily="poppins">
                    Chat with us
                  </Text>
                </Box>

                <HiArrowRight color="white" />
              </Box>

              <Text w="80%" pl="2" mt="4" fontSize="10px" color="white">
                If having any query chat with our agent and solve your problem
              </Text>

              <Image position="absolute" bottom="1" right="1" src={`${Leaf}`} />
            </Box>
          </Box>
        </Link>
      </Box>

      <Box mt="8" px="4">
        <Text
          fontWeight="600"
          fontFamily="Cormorant Garamond"
          fontStyle="normal"
          fontSize="22px"
          ml="2"
        >
          FAQ's
        </Text>
        <Box
          boxShadow="8px 8px 16px 0px rgba(0, 0, 0, 0.10)"
          mt="5"
          borderRadius="5px"
        >
          <Accordion allowToggle>
            {FaqData.map((ele, index) => (
              <AccordionItem
                key={index}
                borderTop="none"
                borderBottom="1px solid #D9D9D94D"
                isFocusable={false}
                appearance="none"
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight="500"
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          py="2"
                        >
                          {ele.question}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      pb={4}
                      fontWeight="500"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="12px"
                      lineHeight="20px"
                    >
                      {ele.answer}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default Chat;
