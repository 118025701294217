import { Box, useColorModeValue, BoxProps } from "@chakra-ui/react";
import React from "react";
import {
  HEADER_NAVBAR_HEIGHT,
  SIDEBAR_CONFIG,
} from "../../configs/LayoutConfigs";

export const MainSection = (props: BoxProps) => {
  return (
    <Box
      mt={`1 rem`}
      // ml={{
      //     base: undefined,
      //     md: `${SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH}rem`
      // }}
      mb={{
        base: `${SIDEBAR_CONFIG.MOBILE_VIEW.BOTTOM_NAVBAR_HEIGHT}rem`,
        // md: 0
      }}
      bg="#FAFAFA"
      {...props}
    />
  );
};
